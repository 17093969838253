import Point from "./Point";

class Vector {
    constructor(p1, p2) {
        this.point = p1;
        this.dir = p1.diff(p2);
    }

    intersection = (v) => {
        const x1 = this.point.x;
        const y1 = this.point.y;
        const ax1 = this.dir.x;
        const by1 = this.dir.y;
        const x2 = v.point.x;
        const y2 = v.point.y;
        const ax2 = v.dir.x;
        const by2 = v.dir.y;

        const r2 = (- (y1 * ax1 / by1) + (y2 * ax1 / by1) + x1 - x2) / (ax2 - ((by2 * ax1) / by1));
        if (isNaN(r2)) {
            return false;
        }
        if (!isFinite(r2)) {
            return new Point(v.point.x, v.point.y);
        }
        const xS = x2 + r2 * ax2;
        const yS = y2 + r2 * by2;
        return new Point(xS, yS);
    }
}

export default Vector;