import React, { useState } from 'react';
import { Text, StyleSheet, FlatList, View, TouchableOpacity, Dimensions } from 'react-native';
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';

import ColorConstants from '../screens/ColorConstants';
import PlayerItem from './PlayerItem';
import scaleFontSize from '../helper/scaleFunctions';

const Rank = props => {
    const [sortBy, setSortBy] = useState(0);

    const labelWidth = props.isPartyMode ? Dimensions.get('window').width * 0.1666 : Dimensions.get('window').width * 0.2;

    const getSortedPlayerList = () => {
        switch (sortBy) {
            case 0: return props.playerlist.sort((a, b) => a.points[0] < b.points[0] ? 1 : a.points[0] > b.points[0] ? -1 : a.points[1] < b.points[1] ? 1 : a.name < b.name ? 1 : -1);
            case 1: return props.playerlist.sort((a, b) => a.points[1] < b.points[1] ? 1 : a.points[1] > b.points[1] ? -1 : a.points[0] < b.points[0] ? 1 : a.name < b.name ? 1 : -1);
            case 2: return props.playerlist.sort((a, b) => a.shots < b.shots ? 1 : a.shots === b.shots ? a.name < b.name ? 1 : -1 : -1);
            default: return props.playerlist;
        }
    }

    const getRank = (index) => {
        switch (sortBy) {
            case 0:
                let rankRound = 1;
                for (let i = 0; i < props.playerlist.length; i++) {
                    if (i !== index && props.playerlist[index].points[0] < props.playerlist[i].points[0]) {
                        rankRound++;
                    }
                }
                return rankRound;
            case 1:
                let rankTotal = 1;
                for (let i = 0; i < props.playerlist.length; i++) {
                    if (i !== index && props.playerlist[index].points[1] < props.playerlist[i].points[1]) {
                        rankTotal++;
                    }
                }
                return rankTotal;
            case 2:
                let rankShots = 1;
                for (let i = 0; i < props.playerlist.length; i++) {
                    if (i !== index && props.playerlist[index].shots < props.playerlist[i].shots) {
                        rankShots++;
                    }
                }
                return rankShots;
        }
    }

    return (
        <View style={{ flex: 1 }}>
            <View style={styles.iconPodium}>
                <MaterialCommunityIcons name="podium-gold" size={scaleFontSize(128)} color="white" />
            </View>
            <View style={styles.rank}>
                <View style={{
                    flexDirection: 'row', alignItems: 'center', marginBottom: 10, width: '100%', justifyContent: 'flex-end',

                }}>
                    <TouchableOpacity style={{ width: labelWidth }} onPress={() => setSortBy(1)}>
                        <Text numberOfLines={1} adjustsFontSizeToFit style={{ textAlign: 'center', fontWeight: sortBy === 1 ? 'bold' : 'normal', color: sortBy === 1 ? 'white' : '#808080', fontSize: scaleFontSize(22), }}>Total</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ width: labelWidth }} onPress={() => setSortBy(0)}>
                        <Text numberOfLines={1} adjustsFontSizeToFit style={{ textAlign: 'center', fontWeight: sortBy === 0 ? 'bold' : 'normal', color: sortBy === 0 ? 'white' : '#808080', fontSize: scaleFontSize(22), }}>Round {props.round}</Text>
                    </TouchableOpacity>
                    {props.isPartyMode && <TouchableOpacity style={{ width: labelWidth }} onPress={() => setSortBy(2)}>
                        <Text style={{ textAlign: 'center', fontWeight: sortBy === 2 ? 'bold' : 'normal', color: sortBy === 2 ? 'white' : '#808080', fontSize: scaleFontSize(22) }}>Shots</Text>
                    </TouchableOpacity>}
                </View>
                <FlatList
                    style={{ width: '100%' }}
                    data={getSortedPlayerList()}
                    keyExtractor={item => item.name}
                    renderItem={itemData => (
                        <View style={{
                            flexDirection: 'row', alignItems: 'center', borderColor: '#FFFFFF',
                            borderBottomWidth: 1, marginBottom: 10, paddingBottom: 10, width: '100%',
                        }}>
                            <Text style={{ color: itemData.item.name === props.username ? ColorConstants.RANK_USER_COLOR : 'white', fontWeight: 'bold', fontSize: scaleFontSize(38) }}>#{getRank(itemData.index)} </Text>
                            <PlayerItem style={{ flex: 1 }} icon={itemData.item.icon}>
                                <View style={{ flex: 1, flexDirection: 'row' }}>
                                    <Text numberOfLines={2} adjustsFontSizeToFit style={{ color: itemData.item.name === props.username ? ColorConstants.RANK_USER_COLOR : 'white', fontSize: scaleFontSize(22) }}>{itemData.item.name} </Text>
                                    {itemData.item.earnedPoints > 0 && <Text style={{ color: '#57D40E', fontSize: scaleFontSize(12) }}>+{itemData.item.earnedPoints}p</Text>}
                                </View>
                                <Text numberOfLines={1} adjustsFontSizeToFit style={{ width: labelWidth, textAlign: 'center', color: '#FFFFFF', fontSize: scaleFontSize(24) }}>{itemData.item.points[1]}p</Text>
                                <Text numberOfLines={1} adjustsFontSizeToFit style={{ width: labelWidth, textAlign: 'center', color: '#FFFFFF', fontSize: scaleFontSize(24) }}>{itemData.item.points[0]}p</Text>
                                {props.isPartyMode && <Text numberOfLines={1} adjustsFontSizeToFit style={{ width: labelWidth, textAlign: 'center', color: '#FFFFFF', fontSize: scaleFontSize(24) }}>{itemData.item.shots}</Text>}
                            </PlayerItem>

                        </View>
                    )}
                />
            </View>

            <View style={{ width: '100%', alignItems: 'flex-end', marginTop: 10 }}>
                <TouchableOpacity
                    onPress={() => { props.continue() }}>
                    <View style={{ alignItems: 'center' }}>
                        <Ionicons name="ios-arrow-round-forward" size={32} color="white" />
                        <Text style={{ color: 'white', fontWeight: 'bold' }}>CONTINUE</Text>
                    </View>
                </TouchableOpacity>
            </View>
        </View>

    );
};

const styles = StyleSheet.create({
    iconPodium: {
        width: '100%',
        alignItems: 'center',
    },
    rank: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20%'
    }
});

export default Rank;
