import React, { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { View, Text, StyleSheet, FlatList, Dimensions } from 'react-native';
import { AdMobInterstitial, setTestDeviceIDAsync } from 'expo-ads-admob';
import { Entypo } from '@expo/vector-icons';
import { Platform } from 'react-native';

import PlayerItem from '../Components/PlayerItem'
import scaleFontSize from '../helper/scaleFunctions';


const JoinScreen = props => {
    const [playerlist, setPlayerlist] = useState([]);

    useEffect(() => {
        props.firebase.database().ref(`Rooms/${props.roomKey}/Players`).on('value', function (snapshot) {
            const list = [];
            let canJoin = true;
            let someoneStarted = false;
            snapshot.forEach(function (childSnapshot) {
                const playerStep = childSnapshot.child('step').val();
                if (playerStep > 1) {
                    canJoin = false;
                }
                if (playerStep >= 1) {
                    someoneStarted = true;
                }
                list.push({ name: childSnapshot.key, icon: childSnapshot.val().icon });
            });
            if (canJoin && someoneStarted) {
                props.join();
                props.firebase.database().ref(`Rooms/${props.roomKey}/Players`).off();
            }
            setPlayerlist(list);
        });
        const showAd = async () => {
            //await AdMobInterstitial.setAdUnitID('ca-app-pub-3940256099942544/1033173712'); //test ad
            //await setTestDeviceIDAsync('EMULATOR');
            if (Platform.OS === 'android') {
                await AdMobInterstitial.setAdUnitID('ca-app-pub-5489489250931709/9350938961');
            } else {
                await AdMobInterstitial.setAdUnitID('ca-app-pub-5489489250931709/2766005859');
            }
            await AdMobInterstitial.requestAdAsync({ servePersonalizedAds: true });
            await AdMobInterstitial.showAdAsync();
        }
        if (Platform.OS === 'android' || Platform.OS === 'ios') {
            showAd();
        }
        return () => {
            props.firebase.database().ref(`Rooms/${props.roomKey}/Players`).off();
        }
    }, []);

    return (
        <View style={styles.container}>
            <View style={{ alignItems: 'flex-end', justifyContent: 'center' }}>
                <TouchableOpacity onPress={() => props.leave()}>
                    <Entypo name="log-out" size={scaleFontSize(28)} color="white" />
                </TouchableOpacity>
            </View>
            <View style={styles.names}>
                <Text style={[styles.name, { fontWeight: 'bold', fontSize: styles.name.fontSize * 1.5, }]}>Player</Text>
                <FlatList
                    data={playerlist}
                    keyExtractor={item => item.name}
                    renderItem={itemData => (
                        <PlayerItem style={{ marginVertical: 10 }} icon={itemData.item.icon}>
                            <View >
                                <Text numberOfLines={2} style={{ color: 'white', fontSize: scaleFontSize(26) }}>{itemData.item.name}</Text>
                            </View>
                        </PlayerItem>
                    )}
                />
            </View>
            {!props.canStart && <Text style={styles.joinText}>...You will join when the next round begins...</Text>}
            {props.canStart &&
                <TouchableOpacity
                    onPress={props.join}
                    style={styles.startButton}
                >
                    <Text style={{ color: 'white', fontSize: 48, }}>START</Text>
                </TouchableOpacity>
            }
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'black',
        flex: 1,
        padding: 10
    },
    name: {
        color: 'white',
        fontSize: 52,
        textAlign: 'center'
    },
    joinText: {
        color: 'white',
        fontSize: 22,
        textAlign: 'center'
    },
    names: {
        alignSelf: 'center',
        flex: 1
    },
    startButton: {
        width: '100%',
        backgroundColor: '#00cc00',
        borderColor: 'white',
        padding: 10,
        borderRadius: 50,
        alignItems: 'center',
    }
});

export default JoinScreen;
