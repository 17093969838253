import React from "react";
import { Image, View, TouchableOpacity } from "react-native";

const Player = props => {
    const getImage = () => {
        switch (props.body.color) {
            case 0: return require('./assets/balloon_green.png');
            case 1: return require('./assets/balloon_blue.png');
            case 2: return require('./assets/balloon_red.png');
            case 3: return require('./assets/balloon_yellow.png');
            default: return require('./assets/balloon_yellow.png');
        }
    }

    return (

        <TouchableOpacity
            style={{
                position: "absolute",
                left: props.body.x,
                top: props.body.y,
            }} onPress={() => { props.onClick(); props.body.status = 1 }}
        >


            <Image
                source={getImage()}
                fadeDuration={0}
                resizeMode='stretch'
                style={{
                    width: props.body.width,
                    height: props.body.height,
                }} />
        </TouchableOpacity>
    );
};

export default Player;