import React, { useState, useEffect, useRef } from "react";
import { Dimensions, View, PanResponder } from 'react-native';
import { Audio } from 'expo-av'

import Wall from '../objects/Wall';
import World from '../physics/World';
import WorldObject from '../physics/WorldObject';
import MinigameEngine from "../MinigameEngine";
import GameIdConstants from '../../GameIdConstants';

const Constants = {
    MAX_WIDTH: Dimensions.get("window").width,
    MAX_HEIGHT: Dimensions.get("window").height - 50,
    BALL_SIZE: Math.ceil((25 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    BAR_HEIGHT: Math.ceil((10 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    BAR_WIDTH: Dimensions.get("window").width * 0.3,
    WALL_WIDTH: Math.ceil((5 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
};

const Pong = props => {
    const [score, setScore] = useState(0);
    const [gameEngine, setGameEngine] = useState(null);
    const [world, _setWorld] = useState(new World([]));
    const worldRef = useRef(world);
    const showHelpRef = useRef(props.showHelp);
    const soundObject = new Audio.Sound();

    useEffect(() => {
        showHelpRef.current = props.showHelp
    }, [props.showHelp])

    useEffect((() => {
        const loadMusic = async () => {
            try {
                await soundObject.loadAsync(require('../../../assets/music/music_pong.mp3'));
                await soundObject.setIsLoopingAsync(true);
                await soundObject.playAsync();
                // Your sound is playing!
            } catch (error) {
                // An error occurred!
            }
        }; loadMusic();
        return () => {
            const unloadMusic = async () => {
                await soundObject.unloadAsync();
            }; unloadMusic();
        }
    }), []);

    const setWorld = objects => {
        worldRef.current = objects
        _setWorld(objects)
    }

    const onEvent = (e) => {
        if (e.type === "score") {
            setScore(score + 1);
            props.updateBestTry(score + 1);
        } else if (e.type === "moveLeft") {
            world.objects.player.moveLeft(world.objects.player.distance, 10);
        } else if (e.type === "moveRight") {
            world.objects.player.moveRight(world.objects.player.distance, 10);
        } else if (e.type === "jump") {
            if (world.objects.player.color === '#FF0000') {
                world.objects.player.color = "#88FF88";
                world.objects.player.distance = Constants.MAX_WIDTH * 0.1;
            } else {
                world.objects.player.color = "#FF0000";
                world.objects.player.distance = Constants.MAX_WIDTH * 0.25;
            }
        }
    }

    const setupWorld = () => {
        const objects = [];
        objects.leftWall = new WorldObject(0, 0, Constants.WALL_WIDTH, Constants.MAX_HEIGHT, 'wallLeft', 0, true);
        objects.rightWall = new WorldObject(Constants.MAX_WIDTH - Constants.WALL_WIDTH, 0, Constants.WALL_WIDTH, Constants.MAX_HEIGHT, 'wallRight', 0, true);
        objects.topWall = new WorldObject(0, 0, Constants.MAX_WIDTH, Constants.WALL_WIDTH, 'wallTop', 0, true);
        objects.player = new WorldObject(Constants.MAX_WIDTH / 2 - Constants.BAR_WIDTH / 2, Constants.MAX_HEIGHT - Constants.BAR_HEIGHT - 10, Constants.BAR_WIDTH, Constants.BAR_HEIGHT, 'player', 0, true);
        objects.player.distance = Constants.MAX_WIDTH * 0.10;
        objects.ball = new WorldObject(Constants.MAX_WIDTH / 2, Constants.MAX_HEIGHT / 2, Constants.BALL_SIZE, Constants.BALL_SIZE, 'ball', 0.25, true);
        objects.ball.minTime = 200;
        objects.ball.maxTime = 400;

        const getMoveDownTime = () => {
            return Math.floor(Math.random() * (objects.ball.maxTime - objects.ball.minTime + 1) + objects.ball.minTime) * 4
        }

        objects.ball.setCollisionHandler(true, (isActive, obj, sP, pos) => {
            switch (obj.name) {
                case 'player':
                    if (objects.player.width > 50) {
                        objects.player.width -= 2;
                        if (objects.ball.minTime > 20) {
                            objects.ball.minTime -= 10;
                            objects.ball.maxTime -= 10;
                        }
                    }
                    objects.ball.moveUp(Constants.MAX_HEIGHT, Math.floor(Math.random() * (200 - objects.ball.minTime + 1) + objects.ball.minTime));
                    if (Math.random() > 0.5) {
                        objects.ball.moveLeft(Constants.MAX_WIDTH, Math.floor(Math.random() * (200 - objects.ball.minTime + 1) + objects.ball.minTime));
                    } else {
                        objects.ball.moveRight(Constants.MAX_WIDTH, Math.floor(Math.random() * (200 - objects.ball.minTime + 1) + objects.ball.minTime));
                    }
                    gameEngine.dispatch({ type: "score" });

                    if (objects.ball.minTime > 5) {
                        objects.ball.minTime--;
                    }
                    objects.ball.mass += 0.1;

                    return 2;
                case 'wallLeft':
                    objects.ball.moveRight(Constants.MAX_WIDTH, Math.floor(Math.random() * (200 - objects.ball.minTime + 1) + objects.ball.minTime));
                    if (Math.random() > 0.5) {
                        objects.ball.moveUp(Constants.MAX_HEIGHT, Math.floor(Math.random() * (200 - objects.ball.minTime + 1) + objects.ball.minTime));
                    } else {
                        objects.ball.moveDown(Constants.MAX_HEIGHT, getMoveDownTime());
                    }
                    return 2;
                case 'wallRight':
                    objects.ball.moveLeft(Constants.MAX_WIDTH, Math.floor(Math.random() * (200 - objects.ball.minTime + 1) + objects.ball.minTime));
                    if (Math.random() > 0.5) {
                        objects.ball.moveUp(Constants.MAX_HEIGHT, Math.floor(Math.random() * (200 - objects.ball.minTime + 1) + objects.ball.minTime));
                    } else {
                        objects.ball.moveDown(Constants.MAX_HEIGHT, getMoveDownTime());
                    }
                    return 2;
                case 'wallTop':
                    objects.ball.moveDown(Constants.MAX_HEIGHT, getMoveDownTime());
                    if (Math.random() > 0.5) {
                        objects.ball.moveLeft(Constants.MAX_WIDTH, Math.floor(Math.random() * (200 - objects.ball.minTime + 1) + objects.ball.minTime));
                    } else {
                        objects.ball.moveRight(Constants.MAX_WIDTH, Math.floor(Math.random() * (200 - objects.ball.minTime + 1) + objects.ball.minTime));
                    }
                    return 2;
                default: return 0;
            }
        });

        const world = new World(objects);
        setWorld(world);
        return {
            physics: { gameEngine: gameEngine },
            world: world,
            player: { body: world.objects.player, color: "#88FF88", renderer: Wall },
            leftWall: { body: world.objects.leftWall, color: "#FFAA40", renderer: Wall },
            rightWall: { body: world.objects.rightWall, color: "#FFAA40", renderer: Wall },
            topWall: { body: world.objects.topWall, color: "#FFAA40", renderer: Wall },
            ball: { body: world.objects.ball, color: "#FFFFFF", renderer: Wall },
        }
    };

    const panResponder = React.useRef(
        PanResponder.create({
            // Ask to be the responder:
            onStartShouldSetPanResponder: () => !showHelpRef.current,
            onStartShouldSetPanResponderCapture: (evt, gestureState) =>
                false,
            onMoveShouldSetPanResponder: (evt, gestureState) => false,
            onMoveShouldSetPanResponderCapture: (evt, gestureState) =>
                false,

            onPanResponderGrant: (evt, gestureState) => {
                // The gesture has started. Show visual feedback so the user knows
                // what is happening!
                // gestureState.d{x,y} will be set to zero now
            },
            onPanResponderMove: (evt, gestureState) => {
                // The most recent move distance is gestureState.move{X,Y}
                // The accumulated gesture distance since becoming responder is
                let newXPos = gestureState.moveX - worldRef.current.objects.player.width / 2;
                if (newXPos + worldRef.current.objects.player.width / 2 < Constants.MAX_WIDTH && newXPos > 0) {
                    let direction = newXPos - worldRef.current.objects.player.x;
                    if (direction > 0) {
                        worldRef.current.objects.player.moveRight(direction, 4);
                    } else {
                        worldRef.current.objects.player.moveLeft(Math.abs(direction), 4);
                    }
                }
            },
            onPanResponderTerminationRequest: (evt, gestureState) =>
                true,
            onPanResponderRelease: (evt, gestureState) => {
                // The user has released all touches while this view is the
                // responder. This typically means a gesture has succeeded
                let newXPos = gestureState.moveX - worldRef.current.objects.player.width / 2;
                if (newXPos + worldRef.current.objects.player.width / 2 < Constants.MAX_WIDTH && newXPos > 0) {
                    let direction = newXPos - worldRef.current.objects.player.x;
                    if (direction > 0) {
                        worldRef.current.objects.player.moveRight(direction);
                    } else {
                        worldRef.current.objects.player.moveLeft(Math.abs(direction));
                    }
                }
            },
            onPanResponderTerminate: (evt, gestureState) => {
                // Another component has become the responder, so this gesture
                // should be cancelled
            },
            onShouldBlockNativeResponder: (evt, gestureState) => {
                // Returns whether this component should block native components from becoming the JS
                // responder. Returns true by default. Is currently only supported on android.
                return true;
            }
        })
    ).current;

    const Physics = (entities, { touches, time, dispatch, motionData }) => {
        if (entities.ball.body.y > Constants.MAX_HEIGHT) {
            dispatch({ type: "game-over" });
        }
        entities.world.update();
        return entities;
    };

    return (
        <View {...panResponder.panHandlers} style={{ flex: 1, overflow: 'hidden' }}>
            <MinigameEngine
                setupWorld={setupWorld}
                systems={[Physics]}
                onEvent={onEvent}
                setScore={setScore}
                score={score}
                setGameEngine={setGameEngine}
                backgroundColor={'#222222'}
                showHelp={props.showHelp}
                setShowHelp={props.setShowHelp}
                gameId={GameIdConstants.PONG}
            />
        </View>

    );
};

export default Pong;