import React, { useState, useEffect, useRef } from 'react'
import { StyleSheet, Text, View, FlatList, TouchableOpacity, TextInput } from 'react-native'
import scaleFontSize from '../../../helper/scaleFunctions';
import GuessPlayerAnswer from './GuessPlayerAnswer';
import PlayerItem from '../../../Components/PlayerItem';
import { Ionicons, Entypo } from '@expo/vector-icons';
import Questions from './Questions';
import GameIdConstants from '../../GameIdConstants';
import HelpScreen from '../../help/HelpScreen';

const KnowFriends = props => {
    const [step, setStep] = useState(0); // 0 := answer question; 1 := guess what others answered; 2 := wait ; 3 := show result 
    const [answer, setAnswer] = useState('');
    const [player, setPlayer] = useState([]);
    const [answers, setanswers] = useState([]);
    const [question, setQuestion] = useState(0);
    const showHelpRef = useRef(props.showHelp);

    useEffect(() => {
        showHelpRef.current = props.showHelp
    }, [props.showHelp])

    useEffect(() => {
        let clock;
        let secondsLeft;
        switch (step) {
            case 0:
                secondsLeft = 60;
                break;
            case 1:
                commitAnwser();
                break;
            case 2:
                secondsLeft = Math.max(30, player.length * 6);
                break;
            default:
                return;
        }
        props.setTimer(secondsLeft);
        clock = setInterval(() => {
            if (!showHelpRef.current) {
                secondsLeft--;
            }
            if (secondsLeft <= 0) {
                setStep(step + 1);
                props.setTimer(secondsLeft);
            } else {
                props.setTimer(secondsLeft);
            }
        }, 1000);
        return () => {
            clearInterval(clock);
        };
    }, [step]);


    useEffect(() => {
        props.firebase.database().ref(`Rooms/${props.roomKey}/KnowFriends/question`).on('value', function (snapshot) {
            if (snapshot.val()) {
                props.firebase.database().ref(`Rooms/${props.roomKey}/KnowFriends/question`).off();
                setQuestion(snapshot.val());
            }
        });
        return () => {
            props.firebase.database().ref(`Rooms/${props.roomKey}/Players/${props.username}`).update({
                'knowFriendsAnswer': null,
            }).then((data) => {
                setStep(step + 1);
            }).catch((error) => {
                //error callback
            });
            if (props.isHost) {
                props.firebase.database().ref(`Rooms/${props.roomKey}/KnowFriends`).update({
                    'question': null,
                });
            }
        }
    }, [])

    const commitAnwser = async () => {
        props.firebase.database().ref(`Rooms/${props.roomKey}/Players/${props.username}`).update({
            'knowFriendsAnswer': answer,
        }).then((data) => {

        }).catch((error) => {
            //error callback
        });
    }

    if (step === 0) {
        //Give Answer
        return (
            <View style={styles.containter}>
                <View style={{ flex: 1, width: '100%', alignItems: 'center' }}>
                    <Text style={{ color: 'white', fontSize: scaleFontSize(28), marginBottom: 25, paddingHorizontal: 10, textAlign: 'center', width: '100%' }}>{Questions[question]}</Text>
                    <TextInput
                        placeholder='Your answer'
                        placeholderTextColor='#BBBBBB'
                        style={styles.input}
                        blurOnSubmit
                        autoCapitalize="none"
                        autoCorrect={false}
                        value={answer}
                        multiline
                        onChangeText={(input) => { setAnswer(input) }}
                    />
                </View>
                <TouchableOpacity
                    onPress={() => setStep(step + 1)}
                    style={{ borderWidth: 0.5, borderColor: 'white', padding: 15, marginBottom: 10, borderRadius: 25 }}>
                    <Text style={{ color: 'white', fontSize: scaleFontSize(28) }}>Submit</Text>
                </TouchableOpacity>
                {props.showHelp && <HelpScreen close={() => { props.setShowHelp(false); }} gameId={GameIdConstants.KNOW_FRIENDS} />}
            </View>
        )
    } else if (step === 1) {
        //Wait
        props.firebase.database().ref(`Rooms/${props.roomKey}/Players`).on('value', function (snapshot) {
            let allDone = true;
            const playerList = [];
            snapshot.forEach(function (childSnapshot) {
                if (childSnapshot.child('knowFriendsAnswer').val() === null) {
                    allDone = false;
                    return;
                } else {
                    if (childSnapshot.key !== props.playerName) {
                        playerList.push({ name: childSnapshot.key, icon: childSnapshot.child('icon').val(), answer: childSnapshot.child('knowFriendsAnswer').val() });
                    }
                }
            });
            if (allDone) {
                props.firebase.database().ref(`Rooms/${props.roomKey}/Players`).off();
                const answers = [];
                playerList.forEach(player => {
                    answers.push({ value: player.answer, selected: false, playerName: player.name });
                });
                setanswers(answers);
                setPlayer(playerList);
                setStep(step + 1);
            }
        });
        return (
            <View style={styles.containter}>
                <Text style={{ color: 'white', fontSize: scaleFontSize(28) }}>...WAITING...</Text>
                {props.showHelp && <HelpScreen close={() => { props.setShowHelp(false); }} gameId={GameIdConstants.KNOW_FRIENDS} />}
            </View>
        )
    } else if (step === 2) {
        //Guess what your friends anwered
        return (
            <View style={styles.containter}>
                <GuessPlayerAnswer player={player} answers={answers} />
                {props.showHelp && <HelpScreen close={() => { props.setShowHelp(false); }} gameId={GameIdConstants.KNOW_FRIENDS} />}
            </View>
        )
    } else if (step === 3) {
        //Result

        let points = 0;
        player.forEach(player => {
            if (player.answer === player.selectedAnswer) {
                points++;
            }
        });
        props.updateBestTry(points);

        return (
            <View style={styles.containter}>
                <Text style={{ color: 'white', fontSize: scaleFontSize(28) }}>RESULT: {points}</Text>
                <FlatList
                    style={{ width: '100%' }}
                    data={player}
                    keyExtractor={item => item.name}
                    renderItem={itemData => (
                        <View style={{ width: '100%', flexDirection: 'row' }}>
                            <View style={{ flex: 1, paddingHorizontal: 25 }}>
                                <PlayerItem icon={itemData.item.icon}>
                                    <Text numberOfLines={2} style={{ color: 'white', fontSize: scaleFontSize(26) }}>{itemData.item.name}</Text>
                                </PlayerItem>
                                {(itemData.item.answer !== itemData.item.selectedAnswer && itemData.item.selectedAnswer !== '' && itemData.item.selectedAnswer) && <Text numberOfLines={2} style={{ color: 'red', fontSize: scaleFontSize(16), marginLeft: 50, textDecorationLine: 'line-through' }}>{itemData.item.selectedAnswer}</Text>}
                                <Text numberOfLines={2} style={{ color: 'white', fontSize: scaleFontSize(18), marginLeft: 50 }}>{itemData.item.answer}</Text>
                            </View>
                            <View style={{ marginRight: 15 }}>
                                {itemData.item.answer !== itemData.item.selectedAnswer && <Entypo name="cross" size={22} color="red" />}
                                {itemData.item.answer === itemData.item.selectedAnswer && <Ionicons name="ios-thumbs-up" size={22} color="green" />}
                            </View>
                        </View>
                    )}
                />
                <TouchableOpacity
                    onPress={() => { props.onTaskDone(); }}
                    style={{ borderWidth: 0.5, borderColor: 'white', padding: 15, marginBottom: 10, borderRadius: 25 }}>
                    <Text style={{ color: 'white', fontSize: scaleFontSize(28) }}>Continue</Text>
                </TouchableOpacity>
                {props.showHelp && <HelpScreen close={() => { props.setShowHelp(false); }} gameId={GameIdConstants.KNOW_FRIENDS} />}
            </View>
        )
    }
}

export default KnowFriends

const styles = StyleSheet.create({
    containter: {
        flex: 1,
        backgroundColor: 'black',
        alignItems: 'center',
    }, input: {
        width: '75%',
        marginVertical: 5,
        padding: 3,
        borderBottomWidth: 1,
        borderColor: 'white',
        color: 'white'
    },
})
