import React, { useState } from "react";
import { StyleSheet, Text, View, TouchableOpacity, TextInput, Platform } from "react-native";
import { GameEngine } from "react-native-game-engine";
import { GameEngine as GameEngineWeb } from "react-game-engine";

import HelpScreen from "../help/HelpScreen";
import getPlatform from '../../helper/getPlatform';
import scaleFontSize from "../../helper/scaleFunctions";

const MinigameEngine = props => {
    const [running, setRunning] = useState(false);

    let gameEngine;
    let keyInput;

    if (getPlatform() === "web") {
        setInterval(() => {
            if (keyInput) {
                keyInput.focus();
            }
        }, 100);
    }

    const onEvent = (e) => {
        if (e.type === "game-over") {
            setRunning(false);
        }
        props.onEvent(e);
    }

    const reset = () => {
        props.setScore(0);
        gameEngine.swap(props.setupWorld());
        setRunning(true);
    }

    return (
        <View style={styles.container}>
            {getPlatform() === 'web' && <TextInput
                style={{ width: 0, height: 0 }}
                ref={(ref) => { if (ref) { keyInput = ref; } }}
                autoFocus
                onKeyPress={(key) => {
                    if (running) {
                        switch (key.key.toUpperCase()) {
                            case 'W':
                                gameEngine.dispatch({ type: "moveUp" });
                                break;
                            case 'A':
                                gameEngine.dispatch({ type: "moveLeft" });
                                break;
                            case 'S':
                                gameEngine.dispatch({ type: "moveDown" });
                                break;
                            case 'D':
                                gameEngine.dispatch({ type: "moveRight" });
                                break;
                            case 'ARROWUP':
                                gameEngine.dispatch({ type: "moveUp" });
                                break;
                            case 'ARROWLEFT':
                                gameEngine.dispatch({ type: "moveLeft" });
                                break;
                            case 'ARROWDOWN':
                                gameEngine.dispatch({ type: "moveDown" });
                                break;
                            case 'ARROWRIGHT':
                                gameEngine.dispatch({ type: "moveRight" });
                                break;
                            case ' ':
                                gameEngine.dispatch({ type: "jump" });
                                break;

                        }
                    } else {
                        reset();
                    }
                }}
            />}

            {Platform.OS === 'web' && <GameEngineWeb
                ref={(ref) => { if (ref) { gameEngine = ref; props.setGameEngine(ref); } }}
                style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: props.backgroundColor ? props.backgroundColor : '#00000000'
                }}
                systems={props.systems}
                running={running && !props.showHelp}
                onEvent={onEvent}
            >
            </GameEngineWeb>}
            {Platform.OS !== 'web' && <GameEngine
                ref={(ref) => { if (ref) { gameEngine = ref; props.setGameEngine(ref); } }}
                style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: props.backgroundColor ? props.backgroundColor : '#00000000'
                }}
                systems={props.systems}
                running={running && !props.showHelp}
                onEvent={onEvent}
            >
            </GameEngine>}
            {(!running && !props.showHelp) && <TouchableOpacity style={styles.fullScreenButton} onPress={reset}>
                <View style={styles.fullScreen}>
                    <Text style={styles.gameOverText}>Game Over</Text>
                    {getPlatform() !== 'web' && <Text style={styles.gameOverRetryText}>Tap to retry</Text>}
                    {getPlatform() === 'web' && <Text style={styles.gameOverRetryText}>Press any key to retry</Text>}
                </View>
            </TouchableOpacity>}

            {props.showHelp && <HelpScreen close={() => { if (!running) { reset(); } props.setShowHelp(false); }} gameId={props.gameId} />}

            {!props.showHelp && <Text style={styles.score}>{props.score}</Text>}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    score: {
        position: 'absolute',
        top: '2%',
        left: '48%',
        textAlign: 'center',
        fontSize: 28,
        color: '#AAAAAA',
        fontFamily: 'PressStart2P_400Regular'
    },
    gameOverText: {
        color: 'red',
        fontSize: scaleFontSize(69),
        fontFamily: 'PressStart2P_400Regular',
        textAlign: 'center',
        marginBottom: 50
    },
    fullScreen: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'black',
        opacity: 0.8,
        justifyContent: 'center',
        alignItems: 'center'
    },
    fullScreenButton: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        flex: 1
    },
    gameOverRetryText: {
        color: 'green',
        fontSize: scaleFontSize(32),
        fontFamily: 'PressStart2P_400Regular',
        textAlign: 'center',
    }
});

export default MinigameEngine;