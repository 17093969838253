import React, { useState, useEffect } from 'react';
import { View, Text, Dimensions, StyleSheet, TouchableOpacity } from 'react-native';
import Answer from './Answer'
import { FontAwesome } from '@expo/vector-icons';

const QuizContainer = props => {
    const [answers, setAnswers] = useState([{}, {}, {}, {}]);
    const [answered, setAnswered] = useState(-1);

    useEffect(() => {
        const correctIndex = Math.floor(Math.random() * (4));
        const sortedAnswers = [];
        let i_false = 0;
        for (let i = 0; i <= 3; i++) {
            if (i === correctIndex) {
                sortedAnswers[i] = { text: props.quiz.correct, correct: true };
            } else {
                sortedAnswers[i] = { text: props.quiz['false' + i_false], correct: false };
                i_false++;
            }
        }
        setAnswers(sortedAnswers);
        setAnswered(-1);
    }, [props.quiz]);

    const onAnswer = (correct) => {
        setAnswered(correct ? 1 : 0);
        props.onAnswer(correct);
    }

    const scaleFontSize = (fontSize) => {
        return Math.ceil((fontSize * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861)));
    }

    return (
        <View style={{ flex: 1, backgroundColor: 'black' }}>
            <View style={{ justifyContent: 'center' }}>
                <Text style={{ textAlign: 'center', color: 'white', fontWeight: 'bold', fontSize: scaleFontSize(26) }}>{props.quiz.question}</Text>
            </View>
            <View style={{ position: 'absolute', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
                {answered === 0 && <FontAwesome name="thumbs-down" size={128} color='#FF0000DD' />}
                {answered === 1 && <FontAwesome name="thumbs-up" size={128} color='#00FF00DD' />}
            </View>

            <View style={{ flex: 1, marginVertical: 25, justifyContent: 'space-evenly', alignItems: 'center' }}>
                <View style={styles.answerRow}>
                    <Answer answer={answers[0]} onAnswer={() => onAnswer(answers[0].correct)} answered={answered !== -1} />
                    <Answer answer={answers[1]} onAnswer={() => onAnswer(answers[1].correct)} answered={answered !== -1} />
                </View>

                <View style={styles.answerRow}>
                    <Answer answer={answers[2]} onAnswer={() => onAnswer(answers[2].correct)} answered={answered !== -1} />
                    <Answer answer={answers[3]} onAnswer={() => onAnswer(answers[3].correct)} answered={answered !== -1} />
                </View>
            </View>

            <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                <Text style={styles.score}>{props.score}</Text>
                <TouchableOpacity style={{ alignItems: 'center', borderWidth: 0.5, borderColor: 'white', borderRadius: 25, marginHorizontal: 25, marginBottom: 10, flex: 1 }} onPress={() => props.nextQuestion()}>
                    <Text style={styles.score}>Next</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    answerRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    score: {
        fontSize: 22,
        color: '#AAAAAA',
        fontFamily: 'PressStart2P_400Regular',
        marginHorizontal: 25, 
        marginVertical: 5,
        paddingTop: 5
    },
});

export default QuizContainer;
