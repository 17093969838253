import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import HomeScreen from '../screens/HomeScreen';
import RoomsScreen from '../screens/RoomsScreen';
import RoomsScreenWeb from '../screens/web/RoomsScreen';
import GameScreen from '../screens/ClassicGame/GameScreen';
import CreditsScreen from '../screens/CreditsScreen';
import ArcadeScreen from '../screens/ArcadeScreen';
import PlayOnMobileScreen from '../screens/web/PlayOnMobileScreen';

const StackNavigator = createStackNavigator();
export const AppNavigator = () => {
    return (
        <StackNavigator.Navigator
            screenOptions={{
                headerShown: false
            }}>
            <StackNavigator.Screen name="Home" component={HomeScreen} />
            <StackNavigator.Screen name="RoomsWeb" component={RoomsScreenWeb} />
            <StackNavigator.Screen name="Rooms" component={RoomsScreen} />
            <StackNavigator.Screen name="Game" component={GameScreen} />
            <StackNavigator.Screen name="Credits" component={CreditsScreen} />
            <StackNavigator.Screen name="Arcade" component={ArcadeScreen} />
            <StackNavigator.Screen name="Mobile" component={PlayOnMobileScreen} />
        </StackNavigator.Navigator>
    );
}

