import React, { useState, useRef, useEffect } from 'react';
import { Dimensions, Platform, View, PanResponder } from 'react-native';
import { Accelerometer } from 'expo-sensors';
import { Audio } from 'expo-av'

import World from '../physics/World';
import WorldObject from '../physics/WorldObject';
import ManyPointsWorldObject from '../physics/ManyPointsWorldObject';
import Player from '../objects/Player';
import WorldVectorObject from "../physics/WorldVectorObject";
import Point from "../physics/Point";
import MinigameEngine from "../MinigameEngine";
import GameIdConstants from '../../GameIdConstants';

const Constants = {
    MAX_WIDTH: Dimensions.get("window").width,
    MAX_HEIGHT: Dimensions.get("window").height - 50,
    SHUTTLE_HEIGHT: Math.ceil((100 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    SHUTTLE_WIDTH: Math.ceil((100 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    STAR_HEIGHT: Math.ceil((150 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    STAR_WIDTH: Math.ceil((150 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    SHOOT_SIZE: Math.ceil((25 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    STAR_MIN_SIZE: Math.ceil((50 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    SHOOT_WEIGHT: Math.ceil((50 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
};

const StarReaction = props => {
    const [score, setScore] = useState(3);
    const [gameEngine, _setGameEngine] = useState(null);
    const [world, _setWorld] = useState(new World([]));
    const worldRef = useRef(world);
    const gameengineRef = useRef(gameEngine);
    const showHelpRef = useRef(props.showHelp);
    const soundObject = new Audio.Sound();

    const setGameEngine = objects => {
        gameengineRef.current = objects
        _setGameEngine(objects)
    }

    const setWorld = objects => {
        worldRef.current = objects
        _setWorld(objects)
    }

    useEffect(() => {
        showHelpRef.current = props.showHelp
    }, [props.showHelp])

    useEffect((() => {
        const loadMusic = async () => {
            try {
                await soundObject.loadAsync(require('../../../assets/music/music_spacebattle.mp3'));
                await soundObject.setIsLoopingAsync(true);
                await soundObject.playAsync();
                // Your sound is playing!
            } catch (error) {
                // An error occurred!
            }
        }; loadMusic();
        return () => {
            const unloadMusic = async () => {
                await soundObject.unloadAsync();
            }; unloadMusic();
            Accelerometer.removeAllListeners();
        }
    }), []);

    const onEvent = (e) => {
        if (e.type === "game-over") {
            Accelerometer.removeAllListeners();
            props.updateBestTry(score);
        } else if (e.type === "scoreStar") {
            setScore(score + 5);
            props.updateBestTry(score + 5);
        } else if (e.type === "shoot") {
            setScore(score - 1);
        } else if (e.type === "moveLeft") {
            world.objects.shuttle.moveLeft(Constants.MAX_WIDTH * 0.025);
        } else if (e.type === "moveRight") {
            world.objects.shuttle.moveRight(Constants.MAX_WIDTH * 0.025);
        } else if (e.type === "jump") {
            shoot();
        }
    }

    const shoot = () => {
        if (score > 0) {
            for (let i = 0; i < 10; i++) {
                if (worldRef.current.objects['shoot' + i].usable) {
                    worldRef.current.objects['shoot' + i].setPosition(worldRef.current.objects.shuttle.x + worldRef.current.objects.shuttle.width / 2 - Constants.SHOOT_SIZE / 2, worldRef.current.objects.shuttle.y)
                    worldRef.current.objects['shoot' + i].moveUp(Constants.MAX_HEIGHT, 100);
                    worldRef.current.objects['shoot' + i].usable = false;
                    gameengineRef.current.dispatch({ type: "shoot" });
                    break;
                }
            }
        }
    }

    const getXPos = () => {
        return Math.floor(Math.random() * ((Constants.MAX_WIDTH - Constants.STAR_WIDTH) - Constants.STAR_WIDTH + 1) + Constants.STAR_WIDTH);
    }

    const setupWorld = () => {
        const shuttleRatio = { x: (Constants.SHUTTLE_WIDTH) / (512), y: Constants.SHUTTLE_HEIGHT / 512 }
        const objects = [];
        objects['shuttle'] = new WorldVectorObject(Constants.MAX_WIDTH / 2, 3 * Constants.MAX_HEIGHT / 4, Constants.SHUTTLE_WIDTH, Constants.SHUTTLE_HEIGHT, 'shuttle',
            [Point.scale2d(new Point(111, 325), shuttleRatio), Point.scale2d(new Point(195, 88), shuttleRatio), Point.scale2d(new Point(235, 45), shuttleRatio), Point.scale2d(new Point(275, 82), shuttleRatio), Point.scale2d(new Point(360, 325), shuttleRatio)],
            [Point.scale2d(new Point(275, 82), shuttleRatio), Point.scale2d(new Point(360, 325), shuttleRatio), Point.scale2d(new Point(280, 200), shuttleRatio), Point.scale2d(new Point(325, 280), shuttleRatio), Point.scale2d(new Point(360, 405), shuttleRatio)],
            [Point.scale2d(new Point(110, 405), shuttleRatio), Point.scale2d(new Point(360, 405), shuttleRatio)],
            [Point.scale2d(new Point(195, 88), shuttleRatio), Point.scale2d(new Point(112, 325), shuttleRatio), Point.scale2d(new Point(110, 405), shuttleRatio)],
            0, false);
        objects['star0'] = new ManyPointsWorldObject(getXPos(), Constants.MAX_HEIGHT / 2, Constants.STAR_WIDTH, Constants.STAR_HEIGHT, 'star', 0.05);
        objects['star1'] = new ManyPointsWorldObject(getXPos(), Constants.MAX_HEIGHT / 6 - Constants.STAR_HEIGHT, Constants.STAR_WIDTH, Constants.STAR_HEIGHT, 'star', 0.05);
        objects['star2'] = new ManyPointsWorldObject(getXPos(), -Constants.MAX_HEIGHT / 6 - Constants.STAR_HEIGHT, Constants.STAR_WIDTH, Constants.STAR_HEIGHT, 'star', 0.05);


        for (let i = 0; i < 10; i++) {
            objects['shoot' + i] = new WorldObject(0, Constants.MAX_HEIGHT * 2, Constants.SHOOT_SIZE, Constants.SHOOT_SIZE, 'shoot', 0, false);
            objects['shoot' + i].usable = true;

            objects['shoot' + i].setCollisionHandler(true, (isActive, obj, sP, position) => {
                if (obj.name === 'star') {
                    if (obj.height > Constants.STAR_MIN_SIZE) {
                        obj.width -= Constants.SHOOT_WEIGHT;
                        obj.height -= Constants.SHOOT_WEIGHT;
                    } else {
                        gameEngine.dispatch({ type: "scoreStar" });
                        obj.setPosition(getXPos(), Math.random() * (-Constants.STAR_HEIGHT + Constants.MAX_HEIGHT / 2 + 1) - Constants.MAX_HEIGHT / 2);
                        obj.width = Constants.STAR_WIDTH;
                        obj.height = Constants.STAR_HEIGHT;
                        obj.speedVertical = 0;
                        obj.mass += 0.01;
                    }
                    objects['shoot' + i].setPosition(0, Constants.MAX_HEIGHT * 2)
                    world.objects['shoot' + i].usable = true;
                }
            });
        }

        objects['shuttle'].setCollisionHandler(true, (isActive, obj, sP, position) => {
            if (obj.name !== 'background') {
                gameEngine.dispatch({ type: "game-over" });
            }
        });

        const world = new World(objects);
        setWorld(world);

        if (Platform.OS === "android") {
            Accelerometer.addListener(motionData => {
                if (motionData.x > 0) {
                    world.objects.shuttle.moveLeft(motionData.x * 50);
                } else {
                    world.objects.shuttle.moveRight(Math.abs(motionData.x * 50));
                }
            });
        } else {
            Accelerometer.addListener(motionData => {
                if (motionData.x > 0) {
                    world.objects.shuttle.moveRight(Math.abs(motionData.x * 50));
                } else {
                    world.objects.shuttle.moveLeft(motionData.x * 50);
                }
            });
        }
        Accelerometer.setUpdateInterval(1);

        const specialStarRessource = Math.random() > 0.5 ? require('./assets/ast_2.png') : require('./assets/ast_3.png');

        return {
            physics: { gameEngine: gameEngine },
            world: world,
            background1: { body: { x: 0, y: 0, width: Constants.MAX_WIDTH, height: Constants.MAX_HEIGHT }, imageSource: require('../starReaction/assets/space1.png'), renderer: Player },
            background2: { body: { x: 0, y: -Constants.MAX_HEIGHT + 3, width: Constants.MAX_WIDTH, height: Constants.MAX_HEIGHT }, imageSource: require('../starReaction/assets/space2.png'), renderer: Player },
            shuttle: { body: world.objects.shuttle, imageSource: require('../starReaction/assets/shuttle.png'), renderer: Player },
            star0: { body: world.objects.star0, imageSource: require('./assets/ast_0.png'), renderer: Player },
            star1: { body: world.objects.star1, imageSource: require('./assets/ast_1.png'), renderer: Player },
            star2: { body: world.objects.star2, imageSource: specialStarRessource, renderer: Player },
            shoot0: { body: world.objects['shoot0'], imageSource: require('./assets/fire.png'), renderer: Player },
            shoot1: { body: world.objects['shoot1'], imageSource: require('./assets/fire.png'), renderer: Player },
            shoot2: { body: world.objects['shoot2'], imageSource: require('./assets/fire.png'), renderer: Player },
            shoot3: { body: world.objects['shoot3'], imageSource: require('./assets/fire.png'), renderer: Player },
            shoot4: { body: world.objects['shoot4'], imageSource: require('./assets/fire.png'), renderer: Player },
            shoot5: { body: world.objects['shoot5'], imageSource: require('./assets/fire.png'), renderer: Player },
            shoot6: { body: world.objects['shoot6'], imageSource: require('./assets/fire.png'), renderer: Player },
            shoot7: { body: world.objects['shoot7'], imageSource: require('./assets/fire.png'), renderer: Player },
            shoot8: { body: world.objects['shoot8'], imageSource: require('./assets/fire.png'), renderer: Player },
            shoot9: { body: world.objects['shoot9'], imageSource: require('./assets/fire.png'), renderer: Player }
        };
    };

    const panResponder = React.useRef(
        PanResponder.create({
            onStartShouldSetPanResponder: () => !showHelpRef.current,
            onStartShouldSetPanResponderCapture: (evt, gestureState) =>
                false,
            onMoveShouldSetPanResponder: (evt, gestureState) => false,
            onMoveShouldSetPanResponderCapture: (evt, gestureState) =>
                false,
            onPanResponderGrant: (evt, gestureState) => {
                shoot();
            },
            onPanResponderMove: (evt, gestureState) => {
            },
            onPanResponderTerminationRequest: (evt, gestureState) =>
                true,
            onPanResponderRelease: (evt, gestureState) => {
            },
            onPanResponderTerminate: (evt, gestureState) => {
            },
            onShouldBlockNativeResponder: (evt, gestureState) => {
                return true;
            }
        })
    ).current;

    const Physics = (entities, { touches, time, dispatch, motionData }) => {
        let shuttle = entities.shuttle.body;

        if (shuttle.x + shuttle.width / 2 < 0) {
            shuttle.setPosition(Constants.MAX_WIDTH - shuttle.width / 2, shuttle.y);
        }

        if (shuttle.x + shuttle.width / 2 > Constants.MAX_WIDTH) {
            shuttle.setPosition(0 - shuttle.width / 2, shuttle.y);
        }

        for (let i = 0; i <= 2; i++) {
            if (entities["star" + i].body.y >= Constants.MAX_HEIGHT + Constants.STAR_HEIGHT) {
                entities["star" + i].body.setPosition(getXPos(), entities["star" + (i + 1) % 3].y < 0 ? entities["star" + (i + 1) % 3].y - Constants.MAX_HEIGHT / 2 : -Constants.STAR_HEIGHT * 2);
                entities["star" + i].body.width = Constants.STAR_WIDTH;
                entities["star" + i].body.height = Constants.STAR_HEIGHT;
            }
        }

        for (let i = 0; i < 10; i++) {
            if (entities["shoot" + i].body.y < 0) {
                world.objects['shoot' + i].usable = true;
            }
        }

        if (entities.background1.body.y > Constants.MAX_HEIGHT) {
            entities.background1.body.y = entities.background2.body.y - Constants.MAX_HEIGHT + 3;
        } else {
            entities.background1.body.y += Constants.MAX_HEIGHT * 0.005;
        }

        if (entities.background2.body.y > Constants.MAX_HEIGHT) {
            entities.background2.body.y = entities.background1.body.y - Constants.MAX_HEIGHT + 3;
        } else {
            entities.background2.body.y += Constants.MAX_HEIGHT * 0.005;
        }

        entities.world.update();
        return entities;
    };

    return (
        <View {...panResponder.panHandlers} style={{ flex: 1, overflow: 'hidden' }}>
            <MinigameEngine
                setupWorld={setupWorld}
                systems={[Physics]}
                onEvent={onEvent}
                setScore={() => setScore(10)}
                score={score}
                setGameEngine={setGameEngine}
                showHelp={props.showHelp}
                setShowHelp={props.setShowHelp}
                gameId={GameIdConstants.SPACE_BATTLE}
            />
        </View>
    );
};

export default StarReaction;