import React from 'react'
import { StyleSheet, Text, TouchableOpacity, Image, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons';

import HelpText from '../games/help/HelpText';
import GameIdConstants from '../games/GameIdConstants';

const ArcadeCard = props => {

    const getColor = () => {
        switch (props.gameId) {
            case GameIdConstants["99_RED_BALLOONS"]: return '#FF6800'
            case GameIdConstants.COLOR_CONFUSION: return '#B4B4B4'
            case GameIdConstants.DUCK_FISHING: return '#00FFBE'
            case GameIdConstants.FLAPPY_UNICORN: return '#00C8FF'
            case GameIdConstants.KIWI_JUMP: return '#D30000'
            case GameIdConstants.PONG: return '#444444'
            case GameIdConstants.QUIZ: return '#D8F000'
            case GameIdConstants.RABBIT_RUN: return '#2A8500'
            case GameIdConstants.MEMORY_MASTER: return '#FF0075'
            case GameIdConstants.SNAKE: return '#968500'
            case GameIdConstants.SPACE_BATTLE: return '#370038'
            case GameIdConstants.STAR_REACTION: return '#000075'
            case GameIdConstants.TURTLE_MATCH: return '#B07CCC'
        }
    }
    const getPreview = () => {
        switch (props.gameId) {
            case GameIdConstants["99_RED_BALLOONS"]: return (<View style={{ width: props.size, height: props.size, alignItems: 'center', justifyContent: 'center' }}>
                <Image
                    source={require('../games/miniGames/balloons/assets/balloon_green.png')}
                    fadeDuration={0}
                    style={{ width: props.size * 0.35, height: props.size * 0.75 }}
                    resizeMode='stretch'
                />
            </View>)
            case GameIdConstants.COLOR_CONFUSION:
                const colors =
                    [
                        { color: '#5a3419', name: 'brown' },
                        { color: '#FF0000', name: 'red' },
                        { color: '#00FF00', name: 'green' },
                        { color: '#0000FF', name: 'blue' },
                        { color: '#00FFFF', name: 'aqua' },
                        { color: '#800080', name: 'purple' },
                        { color: '#FFFF00', name: 'yellow' },
                        { color: '#FF8C00', name: 'orange' },
                    ];
                const wordsRows = [];

                for (let i = 0; i < 10; i++) {
                    const words = [];
                    for (let j = 0; j < 4; j++) {
                        const word = colors[Math.floor(Math.random() * 8)].name;
                        const wordColor = colors[Math.floor(Math.random() * 8)].color;
                        words.push(<Text numberOfLines={1} adjustsFontSizeToFit style={{ color: wordColor, overflow: 'hidden', width: '25%' }}> {word} </Text>)
                    }
                    wordsRows.push(<View style={{ width: '100%', height: '10%', flexDirection: 'row', }}>{words}</View>)
                }
                return <View style={{ flex: 1, alignContent: 'center', justifyContent: 'center' }}>{wordsRows}</View>;
            case GameIdConstants.DUCK_FISHING:
                return (<View style={{ width: props.size, height: props.size, alignItems: 'center', justifyContent: 'center' }}>
                    <Image
                        source={require('../games/miniGames/duckFishing/assets/flyDuck.png')}
                        fadeDuration={0}
                        style={{ width: props.size * 0.8, height: props.size * 0.8 }}
                        resizeMode='stretch'
                    />
                </View>)
            case GameIdConstants.FLAPPY_UNICORN: return (<View style={{ width: props.size, height: props.size, alignItems: 'center', justifyContent: 'center' }}>
                <Image
                    source={require('../games/miniGames/flappybird/images/unicorn.png')}
                    fadeDuration={0}
                    style={{ width: props.size * 0.7, height: props.size * 0.7 }}
                    resizeMode='stretch'
                />
            </View>)
            case GameIdConstants.KIWI_JUMP: return (<View style={{ width: props.size, height: props.size, alignItems: 'center', justifyContent: 'center' }}>
                <Image
                    source={require('../games/miniGames/kiwiJump/assets/kiwi.png')}
                    fadeDuration={0}
                    style={{ width: props.size * 0.8, height: props.size * 0.8 }}
                    resizeMode='stretch'
                />
            </View>)
            case GameIdConstants.PONG: return (<View style={{ width: props.size, height: props.size, alignItems: 'center', justifyContent: 'flex-end', paddingBottom: '25%' }}>
                <View style={{ width: '10%', height: '10%', backgroundColor: 'white', marginBottom: '20%' }}></View>
                <View style={{ width: '70%', height: '10%', backgroundColor: '#88FF88' }}></View>
            </View>)
            case GameIdConstants.QUIZ: return <MaterialCommunityIcons name="account-question" size={props.size * 0.75} color="black" />
            case GameIdConstants.RABBIT_RUN: return (<View style={{ width: props.size, height: props.size, alignItems: 'center', justifyContent: 'center' }}>
                <Image
                    source={require('../games/miniGames/rabbitRun/assets/rabbit.png')}
                    fadeDuration={0}
                    style={{ width: props.size * 0.7, height: props.size * 0.7 }}
                    resizeMode='stretch'
                />
            </View>)
            case GameIdConstants.MEMORY_MASTER: return <MaterialCommunityIcons name="thought-bubble" size={props.size * 0.75} color="white" />
            case GameIdConstants.SNAKE: return (<View style={{ width: props.size, height: props.size, alignItems: 'center', justifyContent: 'center' }}>
                <Image
                    source={require('../games/miniGames/rabbitRun/assets/snake.png')}
                    fadeDuration={0}
                    style={{ width: props.size * 0.7, height: props.size * 0.7 }}
                    resizeMode='stretch'
                />
            </View>)
            case GameIdConstants.SPACE_BATTLE: return (<View style={{ width: props.size, height: props.size, alignItems: 'center', justifyContent: 'center' }}>
                <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <Image
                        source={require('../games/miniGames/spaceBattle/assets/ast_0.png')}
                        fadeDuration={0}
                        style={{ width: props.size * 0.35, height: props.size * 0.35, marginRight: '5%' }}
                        resizeMode='stretch'
                    />
                    <Image
                        source={require('../games/miniGames/spaceBattle/assets/ast_2.png')}
                        fadeDuration={0}
                        style={{ width: props.size * 0.45, height: props.size * 0.45 }}
                        resizeMode='stretch'
                    />
                </View>
                <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <Image
                        source={require('../games/miniGames/spaceBattle/assets/ast_1.png')}
                        fadeDuration={0}
                        style={{ width: props.size * 0.4, height: props.size * 0.4 }}
                        resizeMode='stretch'
                    />
                    <Image
                        source={require('../games/miniGames/spaceBattle/assets/ast_3.png')}
                        fadeDuration={0}
                        style={{ width: props.size * 0.3, height: props.size * 0.3, marginTop: '15%' }}
                        resizeMode='stretch'
                    />
                </View>
            </View>)
            case GameIdConstants.STAR_REACTION: return (<View style={{ width: props.size, height: props.size, alignItems: 'center', justifyContent: 'center' }}>
                <Image
                    source={require('../games/miniGames/starReaction/assets/shuttle.png')}
                    fadeDuration={0}
                    style={{ width: props.size * 0.7, height: props.size * 0.7 }}
                    resizeMode='stretch'
                />
            </View>)
            case GameIdConstants.TURTLE_MATCH: return (<View style={{ width: props.size, height: props.size, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                <Image
                    source={require('../games/miniGamesB/candycrush/assets/turtle_green.png')}
                    fadeDuration={0}
                    style={{ width: props.size * 0.2525, height: props.size * 0.3 }}
                    resizeMode='stretch'
                />
                <Image
                    source={require('../games/miniGamesB/candycrush/assets/turtle_orange.png')}
                    fadeDuration={0}
                    style={{ width: props.size * 0.2525, height: props.size * 0.3 }}
                    resizeMode='stretch'
                />
                <Image
                    source={require('../games/miniGamesB/candycrush/assets/turtle_orange.png')}
                    fadeDuration={0}
                    style={{ width: props.size * 0.2525, height: props.size * 0.3 }}
                    resizeMode='stretch'
                />
            </View>)
        }
    }

    return (
        <View style={{ width: props.size, marginHorizontal: 10, marginVertical: 5 }}>
            <TouchableOpacity style={{ width: props.size, height: props.size, backgroundColor: getColor(), borderRadius: 25, alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }} onPress={() => props.setactiveMinigame(props.gameId)}>
                {getPreview()}
            </TouchableOpacity>
            <Text adjustsFontSizeToFit style={{ fontFamily: 'PressStart2P_400Regular', color: 'white', width: '100%', textAlign: 'center', marginTop: 5 }}>{HelpText[props.gameId].title}</Text>
        </View>

    )
}

export default ArcadeCard

const styles = StyleSheet.create({})
