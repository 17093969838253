import React from "react";
import { View } from "react-native";

const Wall = props => {
    return (
        <View
            style={{
                position: "absolute",
                left: props.body.x,
                top: props.body.y,
                width: props.body.width,
                height: props.body.height,
                backgroundColor: props.body.color ? props.body.color : props.color,
                borderColor: props.body.borderColor ? props.body.borderColor : "black",
                borderWidth: props.body.borderWidth ? props.body.borderWidth : 0,
                borderRadius: props.body.borderRadius ? props.body.borderRadius : 0
            }} >
        </View >
    );
};

export default Wall;
