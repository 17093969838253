import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import Constants from './Constants';

const Tail = props => {
    let tailList = props.elements.map((el, idx) => {
        return <View key={idx} style={{ width: props.size, height: props.size, position: 'absolute', left: el[0] * props.size, top: el[1] * props.size, backgroundColor: '#008800' }} />
    });

    return (
        <View style={{ width: Constants.GRID_WIDTH * props.size, height: Constants.GRID_HEIGHT * props.size }}>
            {tailList}
        </View>
    );
};

export default Tail;
