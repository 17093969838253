import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Button, FlatList, Dimensions, TouchableOpacity } from 'react-native';
import { Ionicons, Entypo } from '@expo/vector-icons';
import PlayerItem from '../Components/PlayerItem';

const DrinkScreen = props => {
    const [shots, setShots] = useState(-1);
    const [drinkList, setDrinklist] = useState([]);

    useEffect(() => {
        props.firebase.database().ref(`Rooms/${props.roomKey}/Players/${props.username}/shotsToDrink`).once('value', function (snapshot) {
            let updatedDrinklist = [];
            let shotsTotal = 0;
            
            if(snapshot.numChildren() <= 0){
                setShots(0);
            }

            snapshot.forEach(function (childSnapshot) {
                props.firebase.database().ref(`Rooms/${props.roomKey}/Players/${childSnapshot.key}/icon`).once('value', function (innerSnapshot) {
                    if (childSnapshot && innerSnapshot) {
                        updatedDrinklist.push({ name: childSnapshot.key, shots: childSnapshot.val(), icon: innerSnapshot.val() });
                        shotsTotal += childSnapshot.val();
                    }
                }).then(() => {
                    setDrinklist(updatedDrinklist);
                    setShots(shotsTotal);
                });
            });
        })
    }, [])

    const clearShotsToDrink = () => {
        props.firebase.database().ref(`Rooms/${props.roomKey}/Players/${props.username}/shotsToDrink`).set(null);
    }

    const scaleFontSize = (fontSize) => {
        return Math.ceil((fontSize * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861)));
    }

    let screen = <View></View>;

    if (shots === 0) {
        screen = (
            <View style={styles.screen}>
                <Text style={{ color: 'white', textAlign: 'center', fontSize: scaleFontSize(36), fontWeight: 'bold', marginHorizontal: 25, marginTop: 40 }}>No shots to drink this round. Better luck next time!</Text>

                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <Entypo name="emoji-sad" size={scaleFontSize(128)} color="white" />
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginHorizontal: 10, marginBottom: 25 }}>
                    <TouchableOpacity
                        style={{ alignItems: 'center' }}
                        onPress={() => {
                            clearShotsToDrink();
                            props.onTaskDone(shots);
                        }}>
                        <Ionicons name="ios-arrow-round-forward" size={42} color="white" />
                        <Text style={{ color: 'white', fontWeight: 'bold' }}>CONTINUE</Text>
                    </TouchableOpacity>
                </View>

            </View>
        );
    } else if (shots > 0) {
        screen = (
            <View style={styles.screen}>

                <View style={{ width: '100%', alignItems: 'center', justifyContent: 'center', marginVertical: 25 }}>
                    <Ionicons name="ios-beer" size={scaleFontSize(72)} color="white" />
                </View>

                <Text style={{ color: 'white', textAlign: 'center', fontSize: scaleFontSize(32), marginHorizontal: 50 }}>Those players wants you to drink:</Text>

                <View style={styles.playerlist}>
                    <FlatList
                        data={drinkList}
                        keyExtractor={item => item.name}
                        renderItem={itemData => (
                            <PlayerItem style={styles.room} icon={itemData.item.icon}>
                                <Text style={{ color: 'white', fontWeight: 'bold', fontSize: scaleFontSize(28) }}>{itemData.item.name} - {itemData.item.shots} {itemData.item.shots > 1 ? 'shots' : 'shot'}</Text>
                            </PlayerItem>
                        )}
                    />
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginHorizontal: 10, marginBottom: 25 }}>
                    <Text style={{ color: 'white', textAlign: 'center', fontWeight: 'bold', fontSize: scaleFontSize(32) }}>Please drink {shots} {shots !== 1 ? 'shots' : 'shot'}</Text>
                    <TouchableOpacity
                        style={{ alignItems: 'center' }}
                        onPress={() => {
                            clearShotsToDrink();
                            props.onTaskDone(shots);
                        }}>
                        <Ionicons name="ios-arrow-round-forward" size={36} color="white" />
                        <Text style={{ color: 'white', fontWeight: 'bold' }}>DONE</Text>
                    </TouchableOpacity>
                </View>

            </View>
        );
    }

    return (
        <View style={styles.screen}>
            {screen}
        </View>
    );
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: 'black'
    },
    playerlist: {
        borderColor: 'grey',
        borderBottomWidth: 1,
        flex: 1,
        margin: 20,
        alignItems: 'center'
    },
    drinkInfo: {
        borderColor: 'red',
        borderWidth: 1,
    },
    room: {
        flexDirection: 'row',
        alignItems: "center",
        margin: 5,
    },
});

export default DrinkScreen;
