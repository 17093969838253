import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Dimensions, TouchableOpacity } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { Audio } from 'expo-av'

import GameIdConstants from '../../GameIdConstants';
import HelpScreen from '../../help/HelpScreen';

const scaleFontSize = (fontSize) => {
    return Math.ceil((fontSize * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861)));
}

const colors =
    [
        { color: '#FFFFFF', name: 'white' },
        { color: '#FF0000', name: 'red' },
        { color: '#00FF00', name: 'green' },
        { color: '#0000FF', name: 'blue' },
        { color: '#00FFFF', name: 'aqua' },
        { color: '#800080', name: 'purple' },
        { color: '#FFFF00', name: 'yellow' },
        { color: '#FF8C00', name: 'orange' },
    ];


const ColorWords = props => {
    const [score, setScore] = useState(0);
    const [running, setIsRunning] = useState(false);
    const [lastColor, setLastColor] = useState({ colorIndex: 0, nameIndex: 0 });
    const [color, setColor] = useState({ colorIndex: 0, nameIndex: 0 });
    const soundObject = new Audio.Sound();

    useEffect(() => { getNextColor(); }, []);

    useEffect((() => {
        const loadMusic = async () => {
            try {
                await soundObject.loadAsync(require('../../../assets/music/music_colorconfusion.mp3'));
                await soundObject.setIsLoopingAsync(true);
                await soundObject.playAsync();
                // Your sound is playing!
            } catch (error) {
                // An error occurred!
            }
        }; loadMusic();
        return () => {
            const unloadMusic = async () => {
                await soundObject.unloadAsync();
            }; unloadMusic();
        }
    }), []);

    const check = (clickedYes) => {
        if (clickedYes === (colors[color.nameIndex].name === colors[lastColor.colorIndex].name)) {
            setScore(score + 1);
            props.updateBestTry(score + 1);
        } else {
            setScore(0);
        }
        getNextColor();
    }

    const randomBetween = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const getNextColor = () => {
        let newColorIndex, newNameIndex;

        if (Math.random() >= 0.5) {
            newNameIndex = color.colorIndex;
            newColorIndex = randomBetween(0, colors.length - 1);
        } else {
            newNameIndex = randomBetween(0, colors.length - 1);
            if (colors[newNameIndex] === colors[color.colorIndex]) {
                newNameIndex = (newNameIndex + 1) % (colors.length - 1);
            }
            if (Math.random() >= 0.5) {
                newColorIndex = color.nameIndex;
            } else {
                newColorIndex = randomBetween(0, colors.length - 1);
            }
        }
        setLastColor(color);
        setColor({ colorIndex: newColorIndex, nameIndex: newNameIndex });
    }

    if (!running) {
        return (
            <View style={styles.container}>
                <Text style={{ color: 'white', fontSize: scaleFontSize(36), textAlign: 'center' }}>Score: {score}</Text>
                <Text style={{ color: colors[color.colorIndex].color, fontSize: scaleFontSize(72), fontWeight: 'bold' }}>{colors[color.nameIndex].name}</Text>

                <TouchableOpacity
                    style={{
                        alignItems: 'center', borderWidth: 0.5, borderColor: 'white', borderRadius: 25,
                        paddingVertical: 10, paddingHorizontal: '15%'
                    }}
                    onPress={() => { getNextColor(); setIsRunning(true); }}
                >
                    <Text style={{ color: 'white', marginHorizontal: 50 }}>Start</Text>
                </TouchableOpacity>
                {props.showHelp && <HelpScreen close={() => {props.setShowHelp(false);}} gameId={GameIdConstants.COLOR_CONFUSION} />}
            </View>
        );
    }

    return (
        <View style={styles.container}>
            <Text style={{ color: 'white', fontSize: scaleFontSize(36), textAlign: 'center' }}>Score: {score}</Text>
            <Text style={{ color: 'white', fontSize: scaleFontSize(36), textAlign: 'center' }}>Is this the name of the color the last word had?</Text>
            <Text style={{ color: colors[color.colorIndex].color, fontSize: scaleFontSize(72), fontWeight: 'bold' }}>{colors[color.nameIndex].name}</Text>
            <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
                <TouchableOpacity style={styles.btn} onPress={() => check(false)}>
                    <Feather name="thumbs-down" size={scaleFontSize(128)} color='red' />
                </TouchableOpacity>
                <TouchableOpacity style={styles.btn} onPress={() => check(true)}>
                    <Feather name="thumbs-up" size={scaleFontSize(128)} color='green' />
                </TouchableOpacity>
            </View>
            {props.showHelp && <HelpScreen close={() => {props.setShowHelp(false);}} gameId={GameIdConstants.COLOR_CONFUSION} />}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'black',
        justifyContent: 'space-around'
    }
});

export default ColorWords;
