import World from './World';
import Point from './Point';
import WorldObject from './WorldObject';

class ManyPointsWorldObject extends WorldObject {

    getTopPoints = () => {
        return [new Point(this.x, this.y), new Point(this.x + this.width * 0.25, this.y), new Point(this.x + this.width / 2, this.y), new Point(this.x + this.width * 0.75, this.y), new Point(this.x + this.width, this.y)];
    }

    getBottomPoints = () => {
        return [new Point(this.x, this.y + this.height), new Point(this.x + this.width * 0.25, this.y + this.height), new Point(this.x + this.width / 2, this.y + this.height), new Point(this.x + this.width * 0.75, this.y + this.height), new Point(this.x + this.width, this.y + this.height)];
    }

    getLeftPoints = () => {
        return [new Point(this.x, this.y), new Point(this.x, this.y + this.height * 0.25), new Point(this.x, this.y + this.height / 2), new Point(this.x, this.y + this.height * 0.75), new Point(this.x, this.y + this.height)];
    }

    getRightPoints = () => {
        return [new Point(this.x + this.width, this.y), new Point(this.x + this.width, this.y + this.height * 0.25), new Point(this.x + this.width, this.y + this.height / 2), new Point(this.x + this.width, this.y + this.height * 0.75), new Point(this.x + this.width, this.y + this.height)];
    }
}

export default ManyPointsWorldObject;