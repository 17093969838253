import Vector from './Vector';
import Point from './Point';

class World {
    static gravity = 0.5;
    speed = 0.00;

    constructor(objects) {
        this.objects = objects;
    }

    update = () => {
        const checkFreeHorizontal = (objIndex) => {
            if (this.objects[objIndex].speedHorizontal > 0) {
                //Right
                for (let index in this.objects) {
                    if (index === objIndex) {
                        continue;
                    }
                    const points = this.objects[objIndex].getRightPoints();
                    loopPoints: for (let i = 0; i < points.length; i++) {
                        let point = points[i];
                        const destPoint = new Point(point.x + this.objects[objIndex].speedHorizontal, point.y);
                        const vectorA = new Vector(point, destPoint);
                        const vectorB = new Vector(new Point(this.objects[index].getX(point.y), this.objects[index].getY(point.x)), new Point(this.objects[index].getX(point.y), this.objects[index].getY(point.x) + this.objects[index].getHeight(point.x)))
                        const sP = vectorB.intersection(vectorA);
                        if (!sP) {
                            continue;
                        }
                        if (sP.x >= point.x && sP.x <= destPoint.x && sP.y >= this.objects[index].getY(point.x) && sP.y <= this.objects[index].getY(point.x) + this.objects[index].getHeight(point.x)) {
                            let result;
                            if ((result = this.objects[objIndex].collissionHandler(true, this.objects[index], sP, 1)) === 0) {
                                this.objects[objIndex].speedHorizontal = sP.x - point.x;
                                this.objects[objIndex].veloHorizontal = 0;
                                return false;
                            } else if (result === 2) {
                                break loopPoints;
                            }
                        }
                    }
                }
            } else {
                //Left
                for (let index in this.objects) {
                    if (index === objIndex) {
                        continue;
                    }
                    const points = this.objects[objIndex].getLeftPoints();
                    loopPoints: for (let i = 0; i < points.length; i++) {
                        let point = points[i];
                        const destPoint = new Point(point.x + this.objects[objIndex].speedHorizontal, point.y);
                        const vectorA = new Vector(point, destPoint);
                        const vectorB = new Vector(new Point(this.objects[index].getX(point.y) + this.objects[index].getWidth(point.y), this.objects[index].getY(point.x)), new Point(this.objects[index].getX(point.y) + this.objects[index].getWidth(point.y), this.objects[index].getY(point.x) + this.objects[index].getHeight(point.x)))
                        const sP = vectorB.intersection(vectorA);
                        if (!sP) {
                            continue;
                        }
                        if (sP.x <= point.x && sP.x > destPoint.x && sP.y >= this.objects[index].getY(point.x) && sP.y < this.objects[index].getY(point.x) + this.objects[index].getHeight(point.x)) {
                            let result;
                            if ((result = this.objects[objIndex].collissionHandler(true, this.objects[index], sP, 3)) === 0) {
                                this.objects[objIndex].speedHorizontal = sP.x - point.x;
                                this.objects[objIndex].veloHorizontal = 0;
                                return false;
                            } else if (result === 2) {
                                break loopPoints;
                            }
                        }
                    }
                }
            }
            return true;
        }

        const checkFree = (objIndex) => {
            if (this.objects[objIndex].speedVertical > 0) {
                //DOWN
                for (let index in this.objects) {
                    if (index === objIndex) {
                        continue;
                    }
                    const points = this.objects[objIndex].getBottomPoints();
                    loopPoints: for (let i = 0; i < points.length; i++) {
                        let point = points[i];
                        const destPoint = new Point(point.x, point.y + this.objects[objIndex].speedVertical);
                        const vectorA = new Vector(point, destPoint);
                        const vectorB = new Vector(new Point(this.objects[index].getX(point.y), this.objects[index].getY(point.x)), new Point(this.objects[index].getX(point.y) + this.objects[index].getWidth(point.y), this.objects[index].getY(point.x)))
                        const sP = vectorA.intersection(vectorB);
                        if (!sP) {
                            continue;
                        }
                        if (sP.y >= point.y && sP.y <= destPoint.y && sP.x >= this.objects[index].getX(point.y) && sP.x <= this.objects[index].getX(point.y) + this.objects[index].getWidth(point.y)) {
                            let result;
                            if ((result = this.objects[objIndex].collissionHandler(true, this.objects[index], sP, 2)) === 0) {
                                this.objects[objIndex].speedVertical = sP.y - point.y;
                                this.objects[objIndex].veloVertical = 0;
                                return false;
                            } else if (result === 2) {
                                break loopPoints;
                            }
                        }
                    }
                }
            } else {
                //UP
                for (let index in this.objects) {
                    if (index === objIndex) {
                        continue;
                    }
                    const points = this.objects[objIndex].getTopPoints();
                    loopPoints: for (let i = 0; i < points.length; i++) {
                        let point = points[i];
                        const destPoint = new Point(point.x, point.y + this.objects[objIndex].speedVertical);
                        const vectorA = new Vector(point, destPoint);
                        const vectorB = new Vector(new Point(this.objects[index].getX(point.y), this.objects[index].getY(point.x) + this.objects[index].getHeight(point.x)), new Point(this.objects[index].getX(point.y) + this.objects[index].getWidth(point.y), this.objects[index].getY(point.x) + this.objects[index].getHeight(point.x)))
                        const sP = vectorA.intersection(vectorB);
                        if (!sP) {
                            continue;
                        }
                        if (sP.y <= point.y && sP.y > destPoint.y && sP.x >= this.objects[index].getX(point.y) && sP.x < this.objects[index].getX(point.y) + this.objects[index].getWidth(point.y)) {
                            let result;
                            if ((result = this.objects[objIndex].collissionHandler(true, this.objects[index], sP, 0)) === 0) {
                                this.objects[objIndex].speedVertical = sP.y - point.y;
                                this.objects[objIndex].veloVertical = 0;
                                return false;
                            } else if (result === 2) {
                                break loopPoints;
                            }
                        }
                    }
                }
            }
            return true;
        }

        const objToMoveVertical = [];
        const objToMoveHorizontal = [];
        for (let index in this.objects) {
            if (this.objects[index].mass > 0 || this.objects[index].speedVertical.toFixed(2) !== parseInt(0).toFixed(2)) {
                objToMoveVertical.push(index);
            }
            if (this.objects[index].speedHorizontal.toFixed(2) !== parseInt(0).toFixed(2)) {
                objToMoveHorizontal.push(index);
            }
        }

        objToMoveVertical.sort((a, b) => {
            if ((a.speedVertical > 0 && b.speedVertical < 0) || (a.speedVertical < 0 && b.speedVertical > 0)) {
                return 0;
            }
            if (a.speedVertical === 0) {
                if (b.speedVertical === 0) {
                    return 0;
                } else {
                    return 1;
                }
            } else if (b.speedVertical === 0) {
                return -1;
            }
            return a.speedVertical < 0 ? (a.y > b.y ? -1 : 1) : (a.y < b.y ? -1 : 1);
        });

        objToMoveVertical.forEach((index) => {
            if (this.objects[index].speedVertical !== 0) {
                checkFree(index);
            }
            this.objects[index].y = this.objects[index].y + this.objects[index].speedVertical;
            this.objects[index].speedVertical += World.gravity * this.objects[index].mass;
            this.objects[index].speedVertical += this.objects[index].veloVertical;
            if (Math.abs(this.objects[index].speedVertical).toFixed(2) === parseInt(0).toFixed(2)) {
                this.objects[index].veloVertical = 0;
                this.objects[index].speedVertical = 0;
            }

        });

        objToMoveHorizontal.sort((a, b) => {
            if ((a.speedHorizontal > 0 && b.speedHorizontal < 0) || (a.speedHorizontal < 0 && b.speedHorizontal > 0)) {
                return 0;
            }
            if (a.speedHorizontal === 0) {
                if (b.speedHorizontal === 0) {
                    return 0;
                } else {
                    return 1;
                }
            } else if (b.speedHorizontal === 0) {
                return -1;
            }
            return a.speedHorizontal < 0 ? (a.y > b.y ? -1 : 1) : (a.y < b.y ? -1 : 1);
        });

        objToMoveHorizontal.forEach((index) => {
            if(Number.isNaN(this.objects[index].speedHorizontal)){
                this.objects[index].veloHorizontal = 0;
                this.objects[index].speedHorizontal = 0;
            }else{
                checkFreeHorizontal(index);
            }
            this.objects[index].x = this.objects[index].x + this.objects[index].speedHorizontal;
            this.objects[index].speedHorizontal += this.objects[index].veloHorizontal;
            if (Math.abs(this.objects[index].speedHorizontal).toFixed(2) === parseInt(0).toFixed(2)) {
                this.objects[index].veloHorizontal = 0;
                this.objects[index].speedHorizontal = 0;
            }
        });
    }
}

export default World;