import React, { useState, useRef, useEffect } from 'react';
import { Dimensions, View, PanResponder } from 'react-native';
import { Audio } from 'expo-av'

import World from '../physics/World';
import WorldObject from '../physics/WorldObject';
import WorldVectorObject from '../physics/WorldVectorObject';
import Wall from '../objects/Wall';
import Player from '../objects/Player';
import MinigameEngine from "../MinigameEngine";
import GameIdConstants from '../../GameIdConstants';
import Point from "../physics/Point";

const Constants = {
  MAX_WIDTH: Dimensions.get("window").width,
  MAX_HEIGHT: Dimensions.get("window").height - 50,
  GAP_SIZE: Math.ceil((200 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))), // gap between the two parts of the pipe
  CLOUD_WIDTH: Math.ceil((200 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
  CLOUD_HEIGHT: Math.ceil((100 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
  UNICORN_HEIGHT: Math.ceil((65 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
  UNICORN_WIDTH: Math.ceil((80 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
};

const FlappyUnicorn = props => {
  const [score, setScore] = useState(0);
  const [gameEngine, setGameEngine] = useState(null);
  const [world, _setWorld] = useState(new World([]));
  const worldRef = useRef(world);
  const showHelpRef = useRef(props.showHelp);
  const soundObject = new Audio.Sound();


  const setWorld = objects => {
    worldRef.current = objects
    _setWorld(objects)
  }

  useEffect(() => {
    showHelpRef.current = props.showHelp
  }, [props.showHelp])

  useEffect((() => {
    const loadMusic = async () => {
      try {
        await soundObject.loadAsync(require('../../../assets/music/music_flappyunicorn.mp3'));
        await soundObject.setIsLoopingAsync(true);
        await soundObject.playAsync();
        // Your sound is playing!
      } catch (error) {
        // An error occurred!
      }
    }; loadMusic();
    return () => {
      const unloadMusic = async () => {
        await soundObject.unloadAsync();
      }; unloadMusic();
    }
  }), []);

  const getYPosTop = () => {
    return Math.floor(Math.random() * ((Constants.MAX_HEIGHT / 2 - Constants.CLOUD_HEIGHT) + 1));
  }

  const getYPosBot = () => {
    return Math.floor(Math.random() * ((Constants.MAX_HEIGHT / 2 - Constants.CLOUD_HEIGHT) + 1)) + Constants.MAX_HEIGHT / 2;
  }

  const setupWorld = () => {
    const objects = [];
    const unicornRatio = { x: (Constants.UNICORN_WIDTH) / (392), y: Constants.UNICORN_HEIGHT / 322 }

    const topPoints = [Point.scale2d(new Point(20, 100), unicornRatio), Point.scale2d(new Point(200, 80), unicornRatio), Point.scale2d(new Point(260, 20), unicornRatio), Point.scale2d(new Point(392, 0), unicornRatio)];
    const bottomPoints = [Point.scale2d(new Point(50, 320), unicornRatio), Point.scale2d(new Point(290, 322), unicornRatio)];
    const rightPoints = [Point.scale2d(new Point(392, 0), unicornRatio), Point.scale2d(new Point(380, 115), unicornRatio), Point.scale2d(new Point(310, 190), unicornRatio), Point.scale2d(new Point(290, 322), unicornRatio)];
    const leftPoints = [Point.scale2d(new Point(20, 100), unicornRatio),  Point.scale2d(new Point(0, 300), unicornRatio)];

    objects.unicorn = new WorldVectorObject(Constants.MAX_WIDTH / 4, Constants.MAX_HEIGHT / 2, Constants.UNICORN_WIDTH, Constants.UNICORN_HEIGHT, 'unicorn',
      topPoints,
      rightPoints,
      bottomPoints,
      leftPoints,
      0.5, true);

    objects['floor'] = new WorldObject(0, Constants.MAX_HEIGHT, Constants.MAX_WIDTH, 1, 'floor', 0, true);
    objects['ceiling'] = new WorldObject(0, -25, Constants.MAX_WIDTH, 25, 'ceiling', 0, true);
    objects['cloud1'] = new WorldObject(Constants.MAX_WIDTH, getYPosTop(), Constants.CLOUD_WIDTH, Constants.CLOUD_HEIGHT, 'cloud', 0, false);
    objects['cloud2'] = new WorldObject(Constants.MAX_WIDTH, getYPosBot(), Constants.CLOUD_WIDTH, Constants.CLOUD_HEIGHT, 'cloud', 0, false);
    objects['cloud3'] = new WorldObject(Constants.MAX_WIDTH * 2, getYPosTop(), Constants.CLOUD_WIDTH, Constants.CLOUD_HEIGHT, 'cloud', 0, false);
    objects['cloud4'] = new WorldObject(Constants.MAX_WIDTH * 2, getYPosBot(), Constants.CLOUD_WIDTH, Constants.CLOUD_HEIGHT, 'cloud', 0, false);

    objects["cloud1"].speedHorizontal = -3;
    objects["cloud2"].speedHorizontal = -3;
    objects["cloud3"].speedHorizontal = -3;
    objects["cloud4"].speedHorizontal = -3;

    objects['unicorn'].setCollisionHandler(true, (isActive, obj, sP, position) => {
      gameEngine.dispatch({ type: "game-over" });
    });

    const world = new World(objects);
    setWorld(world);
    return {
      physics: { gameEngine: gameEngine },
      world: world,
      background1: { body: { x: 0, y: 0, width: Constants.MAX_WIDTH, height: Constants.MAX_HEIGHT }, imageSource: require('./images/background1.png'), renderer: Player },
      background2: { body: { x: Constants.MAX_WIDTH - 2, y: 0, width: Constants.MAX_WIDTH, height: Constants.MAX_HEIGHT }, imageSource: require('./images/background2.png'), renderer: Player },
      floor: { body: world.objects.floor, color: "#000000", renderer: Wall },
      ceiling: { body: world.objects.ceiling, color: "#00000000", renderer: Wall },
      unicorn: { body: world.objects.unicorn, imageSource: require('./images/unicorn.png'), renderer: Player },
      cloud1: { body: world.objects.cloud1, imageSource: require('./images/cloud.png'), renderer: Player },
      cloud2: { body: world.objects.cloud2, imageSource: require('./images/cloudB.png'), renderer: Player },
      cloud3: { body: world.objects.cloud3, imageSource: require('./images/cloudB.png'), renderer: Player },
      cloud4: { body: world.objects.cloud4, imageSource: require('./images/cloud.png'), renderer: Player }
    }
  };

  const onEvent = (e) => {
    if (e.type === "score") {
      setScore(score + 1);
      props.updateBestTry(score + 1);
    } else if (e.type === "jump") {
      world.objects.unicorn.moveUp(Constants.MAX_HEIGHT * 0.1, 10);
    }
  }

  const Physics = (entities, { dispatch }) => {

    for (let i = 1; i <= 3; i += 2) {
      if (!entities["cloud" + i].score && entities["cloud" + i].body.x + Constants.CLOUD_WIDTH <= entities.unicorn.body.x) {
        entities["cloud" + i].score = true;
        dispatch({ type: "score" });
      }
      if (entities["cloud" + i].body.x <= -Constants.CLOUD_WIDTH) {
        entities["cloud" + i].body.setPosition(Constants.MAX_WIDTH * 2, getYPosTop());
        entities["cloud" + i].speedHorizontal -= -0.25;
        entities["cloud" + i].score = false;
      }
    }

    for (let i = 2; i <= 4; i += 2) {
      if (entities["cloud" + i].body.x <= -Constants.CLOUD_WIDTH) {
        entities["cloud" + i].body.setPosition(Constants.MAX_WIDTH * 2, getYPosBot());
        entities["cloud" + i].speedHorizontal -= -0.25;
      }
    }

    if (entities.background1.body.x < -Constants.MAX_WIDTH) {
      entities.background1.body.x = entities.background2.body.x + Constants.MAX_WIDTH - 2;
    } else {
      entities.background1.body.x -= Constants.MAX_WIDTH * 0.005;
    }

    if (entities.background2.body.x < -Constants.MAX_WIDTH) {
      entities.background2.body.x = entities.background1.body.x + Constants.MAX_WIDTH - 2;
    } else {
      entities.background2.body.x -= Constants.MAX_WIDTH * 0.005;
    }

    entities.world.update();
    return entities;
  };

  const panResponder = React.useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => !showHelpRef.current,
      onStartShouldSetPanResponderCapture: (evt, gestureState) =>
        false,
      onMoveShouldSetPanResponder: (evt, gestureState) => false,
      onMoveShouldSetPanResponderCapture: (evt, gestureState) =>
        false,
      onPanResponderGrant: (evt, gestureState) => {
        worldRef.current.objects.unicorn.moveUp(Constants.MAX_HEIGHT * 0.1, 20);
      },
      onPanResponderMove: (evt, gestureState) => {
      },
      onPanResponderTerminationRequest: (evt, gestureState) =>
        true,
      onPanResponderRelease: (evt, gestureState) => {
      },
      onPanResponderTerminate: (evt, gestureState) => {
      },
      onShouldBlockNativeResponder: (evt, gestureState) => {
        return true;
      }
    })
  ).current;

  return (
    <View {...panResponder.panHandlers} style={{ flex: 1, overflow: 'hidden' }}>
      <MinigameEngine
        setupWorld={setupWorld}
        systems={[Physics]}
        onEvent={onEvent}
        setScore={setScore}
        score={score}
        setGameEngine={setGameEngine}
        showHelp={props.showHelp}
        backgroundColor={"#66AAFF"}
        setShowHelp={props.setShowHelp}
        gameId={GameIdConstants.FLAPPY_UNICORN}
      />
    </View>
  );
};

export default FlappyUnicorn;