class Room {
  constructor(id, name, pwd, host, isPartyMode, round) {
    this.id = id;
    this.name = name;
    this.pwd = pwd;
    this.host = host;
    this.isPartyMode = isPartyMode;
    this.round = round;
  }
}

export default Room;
