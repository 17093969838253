import React, { useState, useEffect } from 'react'
import { StyleSheet, Text, View, TouchableOpacity, ScrollView, FlatList, TextInput } from 'react-native'
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';

import ColorConstants from './ColorConstants';
import PlayerItem from '../Components/PlayerItem';
import scaleFontSize from '../helper/scaleFunctions';
import IconPicker from '../screens/web/IconPicker';

const ArcadeRankScreen = props => {
    const [username, setUsername] = useState('');
    const [icon, setIcon] = useState(1);
    const [topTen, setTopTen] = useState([]);
    const [showJoinTopTenModal, setShowJoinTopTenModal] = useState(false);

    useEffect(() => {
        props.firebase.database().ref(`ArcadeRanks/${props.gameId}/topTen`).once('value', function (snapshot) {
            const topTenList = [];
            snapshot.forEach(function (childSnapshot) {
                topTenList.push({ rank: childSnapshot.val().rank, icon: childSnapshot.val().icon, name: childSnapshot.val().name, score: childSnapshot.val().score });
            });
            setTopTen(topTenList);
            if (props.newHighscore && topTenList[topTenList.length - 1].score < props.score) {
                setShowJoinTopTenModal(true);
            }
        }
        );
    }, []);

    const writeNewTopTenToFirebase = (topTen) => {
        props.firebase.database().ref(`ArcadeRanks/${props.gameId}`).update({
            topTen,
            "entryScore": topTen[topTen.length - 1].score
        }).then((data) => {
            //success callback
            setTopTen(topTen);
            setShowJoinTopTenModal(false);
            props.setNewHighscore(false);
        }).catch((error) => {
            //error callback
            setShowJoinTopTenModal(false);
        })
    }

    const joinTopTen = () => {
        props.firebase.database().ref(`ArcadeRanks/${props.gameId}/topTen`).once('value', function (snapshot) {
            const topTenList = [];
            snapshot.forEach(function (childSnapshot) {
                topTenList.push({ rank: childSnapshot.key, icon: childSnapshot.val().icon, name: childSnapshot.val().name, score: childSnapshot.val().score });
            });
            topTenList.push({ rank: 0, icon: icon, name: username, score: props.score });
            topTenList.sort((a, b) => b.score - a.score);
            const newTopTen = [];
            for (let i = 1; i <= 10 && i <= topTenList.length; i++) {
                topTenList[i - 1].rank = i;
                newTopTen.push(topTenList[i - 1]);
            }
            writeNewTopTenToFirebase(newTopTen);
        }
        );
    }

    const joinTopTenModal = (<View style={styles.fullScreen}>
        <View style={styles.modal}>
            <Text style={{ color: 'white', textAlign: 'center', fontFamily: 'PressStart2P_400Regular', marginTop: 5 }}>NEW GLOBAL HIGHSCORE!</Text>
            <Text numberOfLines={2} adjustsFontSizeToFit style={{ color: '#EEEEEE', textAlign: 'center', fontFamily: 'PressStart2P_400Regular', marginTop: 5, width: '100%', paddingHorizontal: 10 }}>How do you want to appear in the global top ten?</Text>
            <ScrollView>
                <View style={styles.roomInfoInput}>
                    <IconPicker icon={icon} setIcon={(icon) => setIcon(icon)} />
                    <TextInput
                        placeholder='Nickname'
                        style={styles.input}
                        blurOnSubmit
                        autoCapitalize="none"
                        autoCorrect={false}
                        value={username}
                        onChangeText={(input) => setUsername(input)}
                    />
                </View>
            </ScrollView>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 15 }}>
                <TouchableOpacity style={{}} onPress={() => setShowJoinTopTenModal(false)}>
                    <Text style={{ color: '#FFBBBB', fontFamily: 'PressStart2P_400Regular' }}>Cancel</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{}} onPress={() => joinTopTen()}>
                    <Text style={{ color: '#BBFFBB', fontFamily: 'PressStart2P_400Regular' }}>OK</Text>
                </TouchableOpacity>
            </View>
        </View>
    </View>);

    return (
        <View style={styles.containerRank}>
            <View style={{ paddingTop: 10, alignItems: 'center', width: '100%', height: 50, backgroundColor: 'black', flexDirection: 'row' }}>
                <TouchableOpacity style={{ marginLeft: 15 }} onPress={props.backAction}>
                    <AntDesign name="back" size={scaleFontSize(28)} color="white" />
                </TouchableOpacity>
                <Text style={{ color: 'white', flex: 1, textAlign: 'center', fontFamily: 'PressStart2P_400Regular', marginTop: 5 }}>Your Highscore: {props.score}</Text>
                <TouchableOpacity style={{ marginRight: 25 }} onPress={props.backAction}>
                    <MaterialCommunityIcons name="podium" size={scaleFontSize(28)} color="white" />
                </TouchableOpacity>
            </View>
            <Text numberOfLines={1} adjustsFontSizeToFit style={styles.heading}>GLOBAL TOP TEN</Text>
            <View style={{ flex: 1, paddingLeft: 10, marginTop: 5 }}>
                <FlatList
                    data={topTen}
                    keyExtractor={item => item.name}
                    renderItem={itemData => (
                        <View style={{
                            flexDirection: 'row', alignItems: 'center', borderColor: '#FFFFFF',
                            borderBottomWidth: 1, marginBottom: 10, paddingBottom: 10,
                        }}>
                            <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                                <Text numberOfLines={1} adjustsFontSizeToFit style={{ color: 'white', fontSize: scaleFontSize(22), marginTop: 5, fontFamily: 'PressStart2P_400Regular', maxWidth: '10%', marginRight: 5 }}>#{itemData.item.rank}</Text>
                                <PlayerItem icon={itemData.item.icon} style={{ flex: 1 }}>
                                    <Text numberOfLines={2} adjustsFontSizeToFit style={{ color: 'white', fontSize: scaleFontSize(20), paddingVertical: 10, fontFamily: 'PressStart2P_400Regular', marginTop: 5 }}>{itemData.item.name}</Text>
                                </PlayerItem>
                                <Text numberOfLines={1} adjustsFontSizeToFit style={{ maxWidth: '15%', marginLeft: '15%', marginTop: 5, textAlign: 'right', color: '#FFFFFF', fontSize: scaleFontSize(20), fontFamily: 'PressStart2P_400Regular' }}>{itemData.item.score}p</Text>
                            </View>
                        </View>
                    )}
                />
            </View>
            {showJoinTopTenModal && joinTopTenModal}
        </View>
    )
}

export default ArcadeRankScreen

const styles = StyleSheet.create({
    containerRank: {
        flex: 1,
        backgroundColor: ColorConstants.ARCARDE_RANK_BACKGROUND,
    },
    fullScreen: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#000000AA'
    },
    modal: {
        width: '90%',
        height: '40%',
        backgroundColor: ColorConstants.JOIN_TOP_TEN_MODAL,
        borderRadius: 25,
        overflow: 'hidden',
        borderWidth: .5,
        borderColor: '#000000',
        paddingVertical: 10
    },
    roomInfoInput: {
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 10
    },
    input: {
        borderColor: 'white',
        color: 'white',
        borderWidth: 1,
        width: '75%',
        marginVertical: 5,
        padding: 3
    },
    heading: {
        textAlign: 'center',
        fontFamily: 'PressStart2P_400Regular',
        color: 'white',
        fontSize: scaleFontSize(32),
        marginTop: 25,
        marginHorizontal: 25
    }
})
