import getPlatform from '../../helper/getPlatform';

const Instructions = {
    tilt: getPlatform() === 'web'? { text: "Use left and right arrow keys to move", img: require('./assets/controls/arrowKeys.png') }: { text: "Tilt your phone in the direction you want to move", img: require('./assets/controls/tilt.gif') },
    tap: getPlatform() === 'web'? { text: "Press Space", img: require('./assets/controls/spaceKey.png') }: { text: "Tap your screen", img: require('./assets/controls/tap.png') },
    touch: { text: "Click it", img: require('./assets/controls/tap.png') },
    swipeToDirection: getPlatform() === 'web'? { text: "Use the arrow keys or W,A,S,D to move", img: require('./assets/controls/arrowKeys.png') }: { text: "Swipe to your desired direction", img: require('./assets/controls/swipe.png') },
    other: { text: "", img: null },
};

export default Instructions;