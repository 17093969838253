import React, { useState, useEffect } from 'react'
import { Dimensions, StyleSheet, Text, View, BackHandler, TouchableOpacity, ScrollView, Platform } from 'react-native'
import { AntDesign, Entypo, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { AdMobInterstitial } from 'expo-ads-admob';

import * as SecureStore from 'expo-secure-store';
import * as WebBrowser from 'expo-web-browser';

import GameIdConstants from '../games/GameIdConstants';
import ColorConstants from './ColorConstants';
import ArcadeCard from '../Components/ArcadeCard';
import ArcadeRankScreen from './ArcadeRankScreen';
import scaleFontSize from '../helper/scaleFunctions';

/* GAME IMPORTS */
import FlappyUnicorn from '../games/miniGames/flappybird/FlappyUnicorn';
import Snake from '../games/miniGamesB/snake/Snake';
import StarReaction from '../games/miniGames/starReaction/StarReaction';
import ColorWords from '../games/brainGames/colorWords/ColorWords';
import RabbitRun from '../games/miniGames/rabbitRun/RabbitRun';
import KiwiJump from '../games/miniGames/kiwiJump/KiwiJump';
import ShootTheBalloons from '../games/miniGames/balloons/ShootTheBalloons';
import DuckFishing from '../games/miniGames/duckFishing/DuckFishing';
import Pong from '../games/miniGames/pong/Pong';
import SpaceBattle from '../games/miniGames/spaceBattle/SpaceBattle';
import HelpText from '../games/help/HelpText';
import RememberWords from '../games/brainGames/words/RememberWords';
import QuizScreen from '../games/brainGames/quizgame/QuizScreen';
import CandyCrush from '../games/miniGamesB/candycrush/CandyCrush';

const arcadeGames = [
    GameIdConstants.RABBIT_RUN,
    GameIdConstants.FLAPPY_UNICORN,
    GameIdConstants.DUCK_FISHING,
    GameIdConstants.SNAKE,
    GameIdConstants.SPACE_BATTLE,
    GameIdConstants.KIWI_JUMP,
    GameIdConstants['99_RED_BALLOONS'],
    GameIdConstants.TURTLE_MATCH,
    GameIdConstants.PONG,
    GameIdConstants.STAR_REACTION,
    GameIdConstants.MEMORY_MASTER,
    GameIdConstants.COLOR_CONFUSION,
    GameIdConstants.QUIZ,
]

const ArcadeScreen = props => {
    const [activeMinigame, setactiveMinigame] = useState(-1);
    const [showHelp, setShowHelp] = useState(true);
    const [showRank, setShowRank] = useState(false);
    const [personalHighscore, setPersonalHighscore] = useState(0);
    const [lowestGlobalHighscore, setLowestGlobalHighscore] = useState(999999999);
    const [firebase, setFirebase] = useState(require("firebase"));
    const [newHighscore, setNewHighscore] = useState(false);
    const [timer, setTimer] = useState(60);
    const [resetGame, setReset] = useState(false);

    useEffect(() => {
        const backHandler = BackHandler.addEventListener(
            "hardwareBackPress",
            backAction
        );
        return () => {
            backHandler.remove();
        };
    }, [activeMinigame, showRank, personalHighscore]);

    useEffect(() => {
        if (showTimer()) {
            if (!showHelp) {
                let secondsLeft = timer;
                const clock = setInterval(() => {
                    secondsLeft--;
                    if (secondsLeft <= 0) {
                        setTimer(0);
                        clearInterval(clock);
                    } else {
                        setTimer(secondsLeft);
                    }
                }, 1000);
                return () => {
                    clearInterval(clock);
                };
            }
        }
    }, [showHelp, timer]);

    const reset = () => {
        setTimer(60);
        setReset(!resetGame);
    }

    const backAction = async () => {
        if (showRank) {
            setShowRank(false);
            setShowHelp(true);
            setNewHighscore(false);
            setTimer(60);
        } else {
            if (activeMinigame === -1) {
                props.navigation.goBack();
            } else {
                writeHighscore(personalHighscore);
                setactiveMinigame(-1);
                setShowHelp(true);
                setPersonalHighscore(0);
            }
        }
        return true;
    };

    useEffect(() => {
        const config = {
            databaseURL: "https://app-drink-at-home.firebaseio.com/",
            projectId: "app-drink-at-home",
        };
        if (!firebase.apps.length) {
            firebase.initializeApp(config);
        }
    }, []);

    useEffect(() => {
        if (activeMinigame !== -1) {
            firebase.database().ref(`ArcadeRanks/${activeMinigame}/entryScore`).once('value', function (snapshot) {
                setLowestGlobalHighscore(snapshot.val());
            }
            );

        }
    }, [activeMinigame]);

    const showTimer = () => {
        return (activeMinigame === GameIdConstants.QUIZ || activeMinigame === GameIdConstants.TURTLE_MATCH || activeMinigame === GameIdConstants.MEMORY_MASTER)
    }
    const updateBestTry = (score) => {
        if (score > personalHighscore) {
            setPersonalHighscore(score);
            setNewHighscore(true);
        }
    }

    const readHighscore = async (gameId) => {
        try {
            const highscore = await SecureStore.getItemAsync('highscore_' + gameId);
            if (highscore) {
                const myJson = JSON.parse(highscore);
                setPersonalHighscore(myJson);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const writeHighscore = async (score) => {
        try {
            await SecureStore.setItemAsync(
                'highscore_' + activeMinigame,
                JSON.stringify(score)
            );
        } catch (e) {
            console.log(e);
        }
    };

    const  selectMinigame = async (id) => {
        setactiveMinigame(id);
        readHighscore(id);
        setTimer(60);
        if (Platform.OS === 'web') {
            if (Math.random() > 0.5) {
                WebBrowser.openBrowserAsync("//luvaihoo.com/afu.php?zoneid=3575961");
            }
        } else {
            if (Math.random() < 0.25) {
                if (Platform.OS === 'android') {
                    await AdMobInterstitial.setAdUnitID('ca-app-pub-5489489250931709/9350938961');
                } else {
                    await AdMobInterstitial.setAdUnitID('ca-app-pub-5489489250931709/2766005859');
                }
                await AdMobInterstitial.requestAdAsync({ servePersonalizedAds: true });
                await AdMobInterstitial.showAdAsync();
            }
        }
    }

    const gamesPerRow = Dimensions.get('window').width > Dimensions.get('window').height ? 4 : 2;
    const cardSize = Math.floor(Dimensions.get('window').width / gamesPerRow) - gamesPerRow * 10;
    const gameRows = [];
    for (let i = 0; i < arcadeGames.length;) {
        const row = [];
        for (let j = 0; j < gamesPerRow && i < arcadeGames.length; j++, i++) {
            row.push(<ArcadeCard key={'minigame_' + arcadeGames[i]} gameId={arcadeGames[i]} size={cardSize} setactiveMinigame={(id) => selectMinigame(id)} />)
        }
        gameRows.push(<View key={'gameRow_' + i} style={styles.gameRow}>{row}</View>);
    }
    if (activeMinigame === -1) {
        return (
            <View style={styles.containerOverview}>
                {Platform.OS === 'web' && <View style={{ paddingTop: 15, paddingLeft: 15, alignItems: 'center', width: '100%', flexDirection: 'row', height: 50 }}>
                    <TouchableOpacity style={{ marginRight: 25 }} onPress={backAction}>
                        <AntDesign name="back" size={scaleFontSize(28)} color="white" />
                    </TouchableOpacity>
                </View>}
                <ScrollView style={{ flex: 1 }}>
                    {gameRows}
                </ScrollView>
            </View>)
    } else {
        if (!showRank) {
            return (
                <View style={styles.containerGame}>
                    {activeMinigame === GameIdConstants.QUIZ && <QuizScreen updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} timer={timer} reset={resetGame} />}
                    {activeMinigame === GameIdConstants.FLAPPY_UNICORN && <FlappyUnicorn updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
                    {activeMinigame === GameIdConstants.SNAKE && <Snake updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
                    {activeMinigame === GameIdConstants.STAR_REACTION && <StarReaction updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
                    {activeMinigame === GameIdConstants.MEMORY_MASTER && <RememberWords updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} reset={resetGame} />}
                    {activeMinigame === GameIdConstants.COLOR_CONFUSION && <ColorWords updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
                    {activeMinigame === GameIdConstants.RABBIT_RUN && <RabbitRun updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
                    {activeMinigame === GameIdConstants.KIWI_JUMP && <KiwiJump updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
                    {activeMinigame === GameIdConstants.TURTLE_MATCH && <CandyCrush updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} reset={resetGame} />}
                    {activeMinigame === GameIdConstants['99_RED_BALLOONS'] && <ShootTheBalloons updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
                    {activeMinigame === GameIdConstants.DUCK_FISHING && <DuckFishing updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
                    {activeMinigame === GameIdConstants.PONG && <Pong updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
                    {activeMinigame === GameIdConstants.SPACE_BATTLE && <SpaceBattle updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}

                    <View style={{ paddingTop: 10, alignItems: 'center', width: '100%', height: 50, backgroundColor: activeMinigame === -1 ? 'transparent' : 'black', flexDirection: 'row' }}>
                        {Platform.OS === 'web' && <TouchableOpacity style={{ marginLeft: 15 }} onPress={backAction}>
                            <AntDesign name="back" size={scaleFontSize(28)} color="white" />
                        </TouchableOpacity>}
                        <TouchableOpacity style={{ marginLeft: 25 }} onPress={() => setShowHelp(true)}>
                            <Entypo name="help" size={scaleFontSize(28)} color="white" />
                        </TouchableOpacity>
                        <View style={{ flex: 1, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', paddingHorizontal: 10 }}>
                            <Text style={{ color: 'white', fontFamily: 'PressStart2P_400Regular', paddingTop: 5 }}>{HelpText[activeMinigame].title}</Text>
                            {showTimer() && <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <MaterialIcons name="timer" size={scaleFontSize(32)} color="white" />
                                <Text style={{ color: 'white', marginLeft: 5, fontFamily: 'PressStart2P_400Regular', paddingTop: 5 }}>{timer}</Text>
                            </View>}
                        </View>
                        <TouchableOpacity style={{ marginRight: 25 }} onPress={() => setShowRank(true)}>
                            <MaterialCommunityIcons name="podium" size={scaleFontSize(28)} color={(newHighscore && personalHighscore > lowestGlobalHighscore) ? 'red' : "white"} />
                        </TouchableOpacity>
                    </View>

                    {(timer === 0) && <TouchableOpacity style={styles.fullScreenButton} onPress={reset}>
                        <View style={styles.fullScreen}>
                            <Text style={styles.gameOverText}>Times up!</Text>
                            {<Text style={styles.gameOverRetryText}>Click to retry</Text>}
                        </View>
                    </TouchableOpacity>}
                </View>)
        } else {
            //RANK
            return (<ArcadeRankScreen firebase={firebase} gameId={activeMinigame} score={personalHighscore} entryScore={lowestGlobalHighscore} backAction={backAction} newHighscore={newHighscore} setNewHighscore={setNewHighscore} />)
        }
    }
}

export default ArcadeScreen

const styles = StyleSheet.create({
    containerOverview: {
        flex: 1,
        backgroundColor: ColorConstants.ARCARDE_BACKGROUND,
    },
    containerGame: {
        flex: 1,
        flexDirection: 'column-reverse',
        backgroundColor: ColorConstants.ARCARDE_BACKGROUND,
    },
    gameRow: {
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    gameOverText: {
        color: 'red',
        fontSize: scaleFontSize(69),
        fontFamily: 'PressStart2P_400Regular',
        textAlign: 'center',
        marginBottom: 50
    },
    fullScreen: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'black',
        opacity: 0.8,
        justifyContent: 'center',
        alignItems: 'center'
    },
    fullScreenButton: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        flex: 1
    },
    gameOverRetryText: {
        color: 'green',
        fontSize: scaleFontSize(32),
        fontFamily: 'PressStart2P_400Regular',
        textAlign: 'center',
    }

})
