import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';

const Answer = props => {

    const width = Dimensions.get('window').width < Dimensions.get('window').height ? (Dimensions.get('window').width * 0.5 - 20) : (Dimensions.get('window').height * 0.4 - 20);
    const height = Dimensions.get('window').width < Dimensions.get('window').height ? (Dimensions.get('window').width * 0.5 - 20) : (Dimensions.get('window').height * 0.3 - 20);

    return (
        <TouchableOpacity
            onPress={props.onAnswer}
            disabled={props.answered}
            style={{ borderColor: props.answered ? props.answer.correct ? 'green' : 'red' : '#2020FF', borderWidth: 1, borderRadius: 25, alignItems: 'center', justifyContent: 'center', width: width, height: height, marginHorizontal: 10 }}>
            <Text style={{ color: 'white', textAlign: 'center' }}>{props.answer.text}</Text>
        </TouchableOpacity>

    );
};

const styles = StyleSheet.create({

});

export default Answer;
