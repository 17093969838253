import React from 'react'
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Image, ScrollView } from 'react-native'

import HelpText from './HelpText';
import ColorConstants from '../../screens/ColorConstants'

const HelpScreen = props => {
    const scaleFontSize = (fontSize) => {
        return Math.ceil((fontSize * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861)));
    }

    const controls = HelpText[props.gameId].control;
    const control = [];

    for (let i = 0; i < controls.length; i++) {
        control.push(
            <View key={i} style={{ marginBottom: 25, flexDirection: 'row' }}>
                <View style={{ alignItems: 'center', width: '50%' }} >
                    <Text style={{ textAlign: 'center', fontWeight: 'bold', fontSize: scaleFontSize(28), marginBottom: 5, marginHorizontal: 5 }}  >{controls[i].event}</Text>
                    {controls[i].specificText && <Text style={{ textAlign: 'center', marginHorizontal: 5, fontSize: scaleFontSize(20) }}>{controls[i].specificText}</Text>}
                    {!controls[i].specificText && <Text style={{ textAlign: 'center', marginHorizontal: 5, fontSize: scaleFontSize(20) }}>{controls[i].instruction.text}</Text>}
                    <View style={{ flex: 1, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                        {controls[i].instruction.img && <Image
                            source={controls[i].instruction.img}
                            fadeDuration={0}
                            resizeMode='contain'
                            style={{
                                height: 100,
                                width: '50%'
                            }} />}
                    </View>
                </View>
                <Image
                    source={controls[i].img}
                    fadeDuration={0}
                    resizeMode='contain'
                    style={{
                        height: 250,
                        width: '50%'
                    }} />
            </View>
        )
    }

    return (
        <View style={styles.fullScreen}>
            <View style={styles.help}>
                <ScrollView>
                    <Text style={{
                        fontSize: scaleFontSize(26), fontFamily: 'PressStart2P_400Regular', textAlign: 'center', marginBottom: 10, marginTop: 10,
                    }}>
                        {HelpText[props.gameId].title}
                    </Text>
                    <Text style={{ paddingHorizontal: 10, textAlign: 'center', fontSize: scaleFontSize(22)}}>  {HelpText[props.gameId].story}</Text>
                    <View style={{ width: '100%', height: 1, marginVertical: 20, backgroundColor: '#A0A0A0A0' }} />
                    {control}
                </ScrollView>
                <TouchableOpacity style={{ width: '100%', alignItems: 'flex-end', paddingRight: 20, marginVertical: 10 }} onPress={props.close}>
                    <Text style={{ color: 'green', fontSize: 22, fontFamily: 'PressStart2P_400Regular' }}>OK</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

export default HelpScreen

const styles = StyleSheet.create({
    fullScreen: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    help: {
        width: '90%',
        height: '90%',
        backgroundColor: ColorConstants.HELP_BACKGROUND,
        borderRadius: 25,
        overflow: 'hidden',
        borderWidth: .5,
        borderColor: '#000000'
    }
})