import React from "react";
import { Image, View, Dimensions, TouchableOpacity } from "react-native";

const Player = props => {
    const iconSize = Dimensions.get('window').width < Dimensions.get('window').height ? Dimensions.get('window').width * 0.15 : (Dimensions.get('window').height - 100) * 0.15;

    const getImage = () => {
        switch (props.data.backgroundColor) {
            case 'red': return require('./assets/turtle_red.png');
            case 'green': return require('./assets/turtle_green.png');
            case 'blue': return require('./assets/turtle_blue.png');
            case 'pink': return require('./assets/turtle_pink.png');
            case 'orange': return require('./assets/turtle_orange.png');
            case 'purple': return require('./assets/turtle_purple.png');
        }
    }

    return (
        <View style={[props.style, { alignItems: 'center', justifyContent: 'center', padding: 2, borderWidth: props.data.selected ? 0.5 : 0, borderColor: 'red' }]}>
            <TouchableOpacity onPress={() => props.onPress(props.id)} style={{ width: '100%', height: '100%' }}>
                <Image
                    source={getImage()}
                    fadeDuration={0}
                    style={{ width: iconSize, height: iconSize }}
                    resizeMode='contain'
                />
            </TouchableOpacity >
        </View>
    );
};

export default Player;

// stroke={props.data.selected ? "purple" : "black"} strokeWidth={props.data.selected ? "20" : "2"}
//                     height={'100%'} fill={props.data.backgroundColor}