import React from "react";
import { Image, View, Dimensions } from "react-native";

const Player = props => {

    const width = props.body.width;
    const height = props.body.height;
    const x = props.body.x;
    const y = props.body.y;

    return (
        <View
            style={{
                position: "absolute",
                left: x,
                top: y,
                width: width,
                height: height,
                alignItems: 'center'
            }} >
                <View
                style={{
                    flex: 1,
                    width: width,
                    borderLeftWidth: 0.5,
                    borderRightWidth: 0.5,
                    borderColor: 'black',
                }} 
                >
                </View>
            <Image
                source={props.imageSource}
                fadeDuration={0}
                resizeMode='stretch'
                style={{
                    width: width,
                    height: width,
                }} />
        </View >
    );
};

export default Player;
