import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Dimensions, TextInput, ScrollView } from 'react-native';
import { Audio } from 'expo-av'

import words from './words';
import HelpScreen from '../../help/HelpScreen';
import GameIdConstants from '../../GameIdConstants';

const scaleFontSize = (fontSize) => {
    return Math.ceil((fontSize * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861)));
}

const RememberWords = props => {
    const [isTest, setIsTest] = useState(false);
    const [wordList, setWordList] = useState([]);
    const [guessedWord, setGuessedWord] = useState([]);
    const [guess, setGuess] = useState('');
    const [leftWords, setLeftWords] = useState([]);
    const [rightWords, setRightWords] = useState([]);
    const [score, setScore] = useState(0);
    const [guessedWrong, setGuessedWrong] = useState(false);
    const soundObject = new Audio.Sound();


    useEffect((() => {
        const loadMusic = async () => {
            try {
                await soundObject.loadAsync(require('../../../assets/music/music_memorymaster.mp3'));
                await soundObject.setIsLoopingAsync(true);
                await soundObject.playAsync();
                // Your sound is playing!
            } catch (error) {
                // An error occurred!
            }
        }; loadMusic();
        setGuessedWrong(false)
        return () => {
            const unloadMusic = async () => {
                await soundObject.unloadAsync();
            }; unloadMusic();
        }
    }), []);


    useEffect(() => {
        setScore(0);
        setIsTest(false);
        const newWordList = [];
        let index = Math.floor(Math.random() * (4993 + 1));
        const step = Math.floor(Math.random() * (50)) + 1;
        for (let i = 0; i < 30; i++) {
            index += step;
            index %= 4993;
            newWordList.push(words[index]);
            guessedWord[i] = false;
        };
        setWordList(newWordList);
    }, [props.reset]);

    useEffect(() => {
        const loadedWordsLeft = [], loadedWordsRight = [];
        for (let i = 0; i < 15; i++) {
            if (guessedWord[i] || !isTest) {
                loadedWordsLeft[i] = <Text key={"l" + i} style={isTest ? styles.wordsTest : styles.words}>{wordList[i]}</Text>;
            } else {
                loadedWordsLeft[i] = <View key={"l" + i} style={styles.wordsUncovered}></View>;
            }

            if (guessedWord[i + 15] || !isTest) {
                loadedWordsRight[i] = <Text key={"r" + i} style={isTest ? styles.wordsTest : styles.words}>{wordList[i + 15]}</Text>;
            } else {
                loadedWordsRight[i] = <View key={"r" + i} style={styles.wordsUncovered}></View>;
            }
        };
        setLeftWords(loadedWordsLeft);
        setRightWords(loadedWordsRight);
    }, [guessedWord, isTest, wordList]);

    const rememberWords = (<View style={{ flex: 1, backgroundColor: 'black' }}>
        <ScrollView style={{ marginVertical: 5 }}>
            <Text style={{ color: '#888888', textAlign: 'center', fontSize: scaleFontSize(28), fontWeight: 'bold', paddingHorizontal: 25 }}>Try to remeber as many words as possible</Text>
            <TouchableOpacity style={{ alignItems: 'center', borderWidth: 0.5, borderColor: 'white', borderRadius: 25, marginHorizontal: 50, marginTop: 25, paddingVertical: 10 }} onPress={() => setIsTest(true)} >
                <Text style={{ color: 'white' }}>Ready</Text>
            </TouchableOpacity>
            <View style={{ flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: 25 }}>
                <View style={styles.wordColumn}>{leftWords}</View>
                <View style={styles.wordColumn}>{rightWords}</View>
            </View>
        </ScrollView>
        {props.showHelp && <HelpScreen close={() => { props.setShowHelp(false); }} gameId={GameIdConstants.MEMORY_MASTER} />}
    </View>);

    const checkGuess = () => {
        const check = wordList.findIndex((word) => word === guess);
        if (check !== -1 && !guessedWord[check]) {
            setScore(score + 1);
            props.updateBestTry(score + 1);
            const table = guessedWord.slice();
            table[check] = true;
            setGuessedWord(table);
        } else {
            setGuessedWrong(true);
        }
        setGuess('');
    }

    const guessWords = (
        <View style={{ flex: 1, backgroundColor: 'black', justifyContent: 'center' }}>
            <View style={{ flexDirection: 'row', paddingHorizontal: 25, width: '100%', paddingVertical: 20, justifyContent: 'space-around' }}>
                <View>
                    <TextInput
                        placeholder='A word you remember?'
                        placeholderTextColor='#BBBBBB'
                        style={{ color: 'white', borderColor: 'grey', borderBottomWidth: 1, }}
                        blurOnSubmit
                        autoCapitalize="none"
                        autoCorrect={false}
                        value={guess}
                        onChangeText={(input) => { setGuessedWrong(false); setGuess(input); }}
                        onSubmitEditing={() => checkGuess()}
                    />
                    {guessedWrong && <Text style={{ color: 'red' }}>Try another word!</Text>}
                </View>
                <TouchableOpacity style={{borderWidth: 0.5, borderColor: 'white', borderRadius: 5, marginLeft: 15, padding: 10 }} onPress={() => checkGuess()} >
                    <Text style={{ color: 'white', paddingTop: 5,         fontFamily: 'PressStart2P_400Regular',
 }}>Check</Text>
                </TouchableOpacity>
            </View>
            <ScrollView style={{ marginTop: 10 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
                    <View style={styles.wordColumn}>{leftWords}</View>
                    <View style={styles.wordColumn}>{rightWords}</View>
                </View>
            </ScrollView>
        </View>);

    return isTest ? guessWords : rememberWords;
};

const styles = StyleSheet.create({
    wordColumn: {
        flex: 1,
        alignItems: 'center',
    },
    words: {
        color: 'white',
        textAlign: 'center',
        fontSize: scaleFontSize(28),
        marginVertical: 2
    },
    wordsTest: {
        color: 'green',
        textAlign: 'center',
        fontSize: scaleFontSize(28),
        marginVertical: 2.5
    },
    wordsUncovered: {
        backgroundColor: '#808080',
        width: 100,
        height: 25,
        borderColor: 'white',
        borderTopWidth: 1,
        marginVertical: 2.5
    }
});

export default RememberWords;
