class Player {
    constructor(name, icon, points, shots) {
      this.name = name;
      this.icon = icon;
      this.shots = shots;
      this.points = points;
    }
  }
  
  export default Player;
  