import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons';

import scaleFontSize from '../helper/scaleFunctions';

const Checkbox = props => {
    return (
        <TouchableOpacity style={props.style} onPress={() => props.onValueChange(!props.value)}>
            {   !props.value && < MaterialCommunityIcons name="checkbox-blank-circle-outline" size={scaleFontSize(28)} color={props.color} />}
            {   props.value && < MaterialCommunityIcons name="checkbox-marked-circle-outline" size={scaleFontSize(28)} color={props.colorChecked} />}

        </TouchableOpacity>
    )
}

export default Checkbox

const styles = StyleSheet.create({})