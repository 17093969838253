import React, { useState, useEffect, useRef } from 'react';
import { View, Text, Button, StyleSheet, FlatList, Dimensions, KeyboardAvoidingView, TouchableOpacity, Switch, TextInput, ScrollView } from 'react-native';
import { FontAwesome5, Entypo } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';

import Room from '../../model/room';
import IconPicker from './IconPicker';
import CheckBox from '../../Components/Checkbox';
import ColorConstants from '../ColorConstants';

import Color from '../../assets/Color'
import { readLocalObject, writeLocalObject } from '../../helper/saveLocal';


const RoomsScreen = props => {
    const [newRoomName, setNewRoomName] = useState('');
    const [username, setUsername] = useState('');
    const [roomPwd, setRoomPwd] = useState('');
    const [rooms, setRooms] = useState([]);
    const [firebase, setFirebase] = useState(require("firebase"));
    const [showModal, setShowModal] = useState(false);
    const [showJoinModal, setShowJoinModal] = useState(false);
    const [room, setRoom] = useState(new Room(-1, '', '', ''));
    const [isPartyMode, setIsPartyMode] = useState(false);
    const [withPassword, setWithPassword] = useState(true);
    const [pwdIsIncorrect, setPwdIsIncorrect] = useState(false);
    const [nameIsUsed, setNameIsUsed] = useState(false);
    const [filter, setFilter] = useState('');
    const [icon, setIcon] = useState(1);
    const [round, setRound] = useState(0);
    const [rejoinRoom, setRejoinRoom] = useState(null);
    const lastRoom = useRef(null);

    useEffect(() => {
        let config = {
            databaseURL: "https://app-drink-at-home.firebaseio.com/",
            projectId: "app-drink-at-home",
        };

        if (!firebase.apps.length) {
            firebase.initializeApp(config);
        }

        (async () => {
            lastRoom.current = await readLocalObject('activeRoom')

            firebase.database().ref('Rooms/').on('value', function (snapshot) {
                let updatedRooms = [];
                snapshot.forEach(function (childSnapshot) {
                    if (childSnapshot.child(`/Players/${childSnapshot.child('hostname').val()}/step`).val() !== "closed") {
                        if (childSnapshot.key && childSnapshot.child('roomName').val() && childSnapshot.child('hostname').val()) {
                            updatedRooms.push(
                                new Room(childSnapshot.key, childSnapshot.child('roomName').val(), childSnapshot.child('roomPwd').val(), childSnapshot.child('hostname').val(), childSnapshot.child('isPartyMode').val(), childSnapshot.child('round').val())
                            )
                        }
                    }
                });
                setRooms(updatedRooms);
                if (lastRoom.current) {
                    const roomLeft = updatedRooms.filter((room) => room.id === lastRoom.current.key);
                    if (roomLeft[0]) {
                        setRejoinRoom(roomLeft[0]);
                    }
                }
            });
        })();

    }, []);

    const createRoom = (roomName, roomPwd, hostname, isPartyMode, icon) => {
        firebase.database().ref('Rooms').push({
            roomName,
            roomPwd,
            hostname,
            isPartyMode,
            round,
            "server_timestamp": {
                ".sv": "timestamp"
            }
        }).then((data) => {
            //success callback
            joinRoom(data.key, hostname, hostname, isPartyMode, round, icon);
        }).catch((error) => {
            //error callback
        })
    };

    const joinRoom = (key, username, hostname, isPartyMode, round, icon) => {
        firebase.database().ref('Rooms/').off();
        firebase.database().ref(`Rooms/${key}/Players/${username}`).set({
            'shots': 0,
            'step': 0,
            'icon': icon,
        }).then((data) => {
            writeLocalObject('activeRoom', { key, username, icon });
            //success callback
            props.navigation.navigate('Game', {
                key: key,
                username: username,
                hostname: hostname,
                isPartyMode: isPartyMode,
                round: round
            });
        }).catch((error) => {
            //error callback
        })
    }

    const reJoinRoom = () => {
        firebase.database().ref('Rooms/').off();
        firebase.database().ref(`Rooms/${rejoinRoom.id}/Players/${lastRoom.current.username}`).update({
            'step': 0,
            'kick': false
        }).then((data) => {
            //success callback
            props.navigation.navigate('Game', {
                key: rejoinRoom.id,
                username: lastRoom.current.username,
                hostname: rejoinRoom.host,
                isPartyMode: rejoinRoom.isPartyMode,
                round: rejoinRoom.round,
            });
        }).catch((error) => {
            //error callback
        })
    }

    if (showModal) {
        return (
            <View
                style={styles.screen}>
                <LinearGradient
                    // Background Linear Gradient
                    colors={['transparent', ColorConstants.ROOMS_GRADIENT]}
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        height: Dimensions.get('window').height,
                    }}
                />
                <View
                    style={{
                        width: '80%',
                        borderColor: 'black',
                        borderWidth: 1,
                        borderRadius: 8,
                        overflow: 'hidden',
                        backgroundColor: ColorConstants.ROOMS_JOIN,
                    }}>
                    <KeyboardAvoidingView
                        style={styles.roomInfoInputContainer}
                        behavior='padding'
                    >
                        <ScrollView>
                            <View style={styles.roomInfoInput}>
                                <IconPicker icon={icon} setIcon={(icon) => setIcon(icon)} />
                                <TextInput
                                    placeholder='Username'
                                    style={styles.input}
                                    blurOnSubmit
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    value={username}
                                    onChangeText={(input) => setUsername(input)}
                                />
                            </View>
                            <View style={styles.roomInfoInput}>
                                <TextInput
                                    placeholder='Roomname'
                                    style={styles.input}
                                    blurOnSubmit
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    value={newRoomName}
                                    onChangeText={(input) => setNewRoomName(input)}
                                />
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                                <TouchableOpacity style={{ marginRight: 10 }} onPress={() => { alert("Gamemodes: \n\nClassic: Compete with your friends in different minigames \n\nParty: After each round, players can request each other to drink a certain number of shots, depending on their ranking in the last minigame.") }} >
                                    <Entypo name="info" size={18} color="white" />
                                </TouchableOpacity>
                                <View style={styles.modeInput}>
                                    <View style={{ flexDirection: 'row', marginRight: 15 }}>
                                        <FontAwesome5 name="dice" size={18} color="white" />
                                        <Text style={{ color: 'white', marginLeft: 5 }}>Classic</Text>
                                    </View>
                                    <Switch
                                        value={isPartyMode}
                                        trackColor={{ false: "#444444", true: "#444444" }}
                                        thumbColor='#6060FF'
                                        onValueChange={(value) => setIsPartyMode(value)}
                                    />
                                    <View style={{ flexDirection: 'row', marginRight: 15 }}>
                                        <Entypo name="drink" size={18} color="white" />
                                        <Text style={{ color: 'white', marginLeft: 5 }}>Party</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <CheckBox
                                    style={{ marginRight: 5 }}
                                    color='white'
                                    colorChecked='white'
                                    value={withPassword}
                                    onValueChange={(value) => {
                                        setWithPassword(value);
                                        if (!value) {
                                            setRoomPwd('');
                                        }
                                    }}
                                />
                                <TextInput
                                    placeholder='Password'
                                    style={[styles.input, { backgroundColor: withPassword ? '#00000000' : '#CCCCCC80', marginLeft: 5 }]}
                                    blurOnSubmit
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    value={roomPwd}
                                    editable={withPassword}
                                    onChangeText={(input) => setRoomPwd(input)}
                                />
                            </View>
                        </ScrollView>
                    </KeyboardAvoidingView>
                    <View style={styles.modalButtons}>
                        <View style={{ width: '50%' }}>
                            <Button
                                title="Start"
                                color='green'
                                onPress={() => {
                                    if (username.length > 0) {
                                        if (newRoomName.length > 0) {
                                            setShowModal(false);
                                            createRoom(newRoomName, roomPwd, username, isPartyMode, icon);
                                            setNewRoomName('');
                                            setRoomPwd('');
                                        } else {
                                            window.alert("Please enter a room name");
                                        }
                                    } else {
                                        window.alert("Please enter a name");
                                    }
                                }}
                            />
                        </View>
                        <View style={{ width: '50%' }}>
                            <Button
                                title="Cancel"
                                color='red'
                                onPress={() => {
                                    setShowModal(false);
                                    setNewRoomName('');
                                    setRoomPwd('');
                                }
                                }
                            />
                        </View>
                    </View>
                </View>
            </View>
        )
    }

    if (showJoinModal) {
        return (
            <View
                style={styles.screen}>
                <View
                    style={{
                        width: '80%',
                        borderColor: 'black',
                        borderWidth: 1,
                        borderRadius: 8,
                        overflow: 'hidden',
                        backgroundColor: ColorConstants.ROOMS_JOIN
                    }}>
                    <KeyboardAvoidingView
                        style={styles.roomInfoInputContainer}
                        behavior='padding'
                    >
                        <ScrollView>
                            <View style={styles.roomInfoInput}>
                                <IconPicker icon={icon} setIcon={(icon) => setIcon(icon)} />
                                <TextInput
                                    placeholder='Username'
                                    style={styles.input}
                                    blurOnSubmit
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    value={username}
                                    onChangeText={(input) => setUsername(input)}
                                />
                            </View>
                            {nameIsUsed && <Text style={{ color: 'red', fontWeight: 'bold' }}>Please choose a different name!</Text>}
                            <View style={styles.roomInfoInput}>
                                {room.pwd !== "" && <TextInput
                                    placeholder='Password'
                                    style={styles.input}
                                    blurOnSubmit
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    value={roomPwd}
                                    onChangeText={(input) => { setRoomPwd(input); setPwdIsIncorrect(false); }}
                                />}
                            </View>
                            {pwdIsIncorrect && <Text style={{ color: 'red', fontWeight: 'bold' }}>Password incorrect!</Text>}
                        </ScrollView>
                    </KeyboardAvoidingView>
                    <View style={styles.modalButtons}>
                        <View style={{ width: '50%' }}>
                            <Button
                                title="Join"
                                color='green'
                                onPress={() => {
                                    if (room.pwd === roomPwd) {
                                        let nameIsValid = true;
                                        firebase.database().ref(`Rooms/${room.id}/Players`).once('value', function (snapshot) {
                                            let allDone = true;
                                            snapshot.forEach(function (childSnapshot) {
                                                if (childSnapshot.key === username) {
                                                    nameIsValid = false;
                                                }
                                            });
                                        }).then(() => {
                                            if (username.length > 0) {
                                                if (nameIsValid) {
                                                    joinRoom(room.id, username, room.host, room.isPartyMode, room.round, icon);
                                                    setShowJoinModal(false);
                                                    setRoomPwd('');
                                                    setPwdIsIncorrect(false);
                                                    setNameIsUsed(false);
                                                } else {
                                                    setNameIsUsed(true);
                                                }
                                            } else {
                                                window.alert("Please enter a name");
                                            }
                                        });
                                    } else {
                                        setPwdIsIncorrect(true);
                                    }
                                }}
                            />
                        </View>
                        <View style={{ width: '50%' }}>
                            <Button
                                title="Cancel"
                                color='red'
                                onPress={() => {
                                    setShowJoinModal(false);
                                    setPwdIsIncorrect(false);
                                    setRoomPwd('');
                                }
                                }
                            />
                        </View>
                    </View>
                </View>
            </View>
        )
    }


    return (
        <View style={styles.screen}>
            <View style={styles.roomList}>
                {rejoinRoom && <View style={{ backgroundColor: '#FF000080', borderWidth: 1, borderColor: 'black', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', marginBottom: 15, padding: 10 }}>
                    <TouchableOpacity
                        style={styles.joinButton}
                        onPress={() => {
                            reJoinRoom();
                        }}
                    >
                        <Text style={{ color: Color.text, fontFamily: 'PressStart2P_400Regular', fontSize: 10, paddingTop: 5, textAlign: 'center' }}>You left "{rejoinRoom.name}" early. Click here to rejoin</Text>
                    </TouchableOpacity>
                </View>}
                <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                    <Text style={{ color: Color.text, fontFamily: 'PressStart2P_400Regular', paddingTop: 5 }}>Open rooms</Text>
                    <TextInput
                        placeholder='Filter by name'
                        placeholderTextColor='#323232'
                        style={{ color: 'black', borderColor: '#888888', borderWidth: 0.5, padding: 5, borderRadius: 5, width: '50%' }}
                        blurOnSubmit
                        autoCapitalize="none"
                        autoCorrect={false}
                        value={filter}
                        onChangeText={(input) => setFilter(input)}
                    />
                </View>
                <FlatList
                    style={{ flex: 1 }}
                    data={rooms.filter((room) => room.name.toUpperCase().includes(filter.toUpperCase()))}
                    keyExtractor={item => item.id}
                    renderItem={itemData => (
                        <View style={styles.room}>
                            <View style={{ flex: 1 }}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Text style={{ color: Color.text, fontSize: 22 }}>{itemData.item.name} </Text>
                                    {itemData.item.isPartyMode && <Entypo name="drink" size={18} color="black" />}
                                    {itemData.item.pwd !== "" && <Entypo name="key" size={18} color="black" />}
                                </View>
                                <Text style={{ color: Color.text, fontSize: 14 }}>  Created by: {itemData.item.host}</Text>
                            </View>
                            <TouchableOpacity
                                style={styles.joinButton}
                                onPress={() => {
                                    setRoom(itemData.item);
                                    setShowJoinModal(true);
                                }}
                            >
                                <Text style={{ color: Color.text, fontFamily: 'PressStart2P_400Regular', paddingTop: 5 }}>JOIN</Text>
                            </TouchableOpacity>
                        </View>
                    )}
                />
            </View>
            <TouchableOpacity
                style={styles.createRoomButton}
                onPress={() => setShowModal(true)} >
                <Text style={{ color: Color.text }}>Create Room</Text>
            </TouchableOpacity>
        </View >
    );
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        backgroundColor: ColorConstants.ROOMS_BACKGROUND,
    },
    roomList: {
        marginVertical: 10,
        paddingHorizontal: 10,
        width: '100%',
        height: '80%'
    },
    roomInfoInputContainer: {
        minHeight: 100,
        padding: 10,
        flex: 1,
    },
    roomInfoInput: {
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 10
    },
    input: {
        borderColor: 'white',
        color: 'white',
        borderWidth: 1,
        width: '75%',
        marginVertical: 5,
        padding: 3
    },
    modalButtons: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
    },
    room: {
        flexDirection: 'row',
        alignItems: "center",
        borderBottomWidth: 1,
        borderColor: Color.borderColor,
        padding: 5,
    },
    joinButton: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    createRoomButton: {
        borderColor: Color.text,
        borderWidth: 1,
        borderRadius: 5,
        padding: 15,
        width: '80%',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: '#FFFFFF',
        backgroundColor: '#FFFFFFAA',
    },
    modeInput: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

export default RoomsScreen;
