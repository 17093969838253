import React, { useRef, useState, useEffect } from "react";
import { View, PanResponder, ImageBackground } from 'react-native';
import { Audio } from 'expo-av'

import Head from './Head';
import Food from "./Food";
import Tail from "./Tail";
import Constants from './Constants';
import MinigameEngine from "../../miniGames/MinigameEngine";
import GameIdConstants from '../../GameIdConstants';

const Snake = props => {
    const [score, setScore] = useState(0);
    const [gameEngine, setGameEngine] = useState(null);
    const [objects, _setObjects] = useState({});
    const objectsRef = useRef(objects);
    const soundObject = new Audio.Sound();

    const setObjects = objects => {
        objectsRef.current = objects
        _setObjects(objects)
    }

    const setupWorld = () => {
        const entities = {
            head: { position: [0, 0], xspeed: 1, yspeed: 0, nextMove: 10, updateFrequency: 10, size: Constants.BLOCK_SIZE, renderer: <Head /> },
            food: { position: [randomBetween(0, Constants.GRID_WIDTH - 1), randomBetween(0, Constants.GRID_HEIGHT - 1)], size: Constants.BLOCK_SIZE, renderer: <Food /> },
            tail: { size: Constants.BLOCK_SIZE, elements: [], renderer: <Tail /> }
        };
        setObjects(entities);
        return entities;
    }

    useEffect((() => {
        const loadMusic = async () => {
            try {
                await soundObject.loadAsync(require('../../../assets/music/music_snake.mp3'));
                await soundObject.setIsLoopingAsync(true);
                await soundObject.playAsync();
                // Your sound is playing!
            } catch (error) {
                // An error occurred!
            }
        }; loadMusic();
        return () => {
            const unloadMusic = async () => {
                await soundObject.unloadAsync();
            }; unloadMusic();
        }
    }), []);

    const randomBetween = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const onEvent = (e) => {
        if (e.type === "score") {
            setScore(score + 1);
            props.updateBestTry(score + 1);
        } else if (e.type === "moveUp") {
            objects.head.yspeed = -1;
            objects.head.xspeed = 0;
        } else if (e.type === "moveDown") {
            objects.head.yspeed = 1;
            objects.head.xspeed = 0;
        } else if (e.type === "moveLeft") {
            objects.head.xspeed = -1;
            objects.head.yspeed = 0;
        } else if (e.type === "moveRight") {
            objects.head.xspeed = 1;
            objects.head.yspeed = 0;
        }
    }

    const panResponder = React.useRef(
        PanResponder.create({
            // Ask to be the responder:
            onStartShouldSetPanResponder: () => true,
            onStartShouldSetPanResponderCapture: (evt, gestureState) =>
                false,
            onMoveShouldSetPanResponder: (evt, gestureState) => false,
            onMoveShouldSetPanResponderCapture: (evt, gestureState) =>
                false,

            onPanResponderGrant: (evt, gestureState) => {
                // The gesture has started. Show visual feedback so the user knows
                // what is happening!
                // gestureState.d{x,y} will be set to zero now
            },
            onPanResponderMove: (evt, gestureState) => {
                // The most recent move distance is gestureState.move{X,Y}
                // The accumulated gesture distance since becoming responder is
                // gestureState.d{x,y}
            },
            onPanResponderTerminationRequest: (evt, gestureState) =>
                true,
            onPanResponderRelease: (evt, gestureState) => {
                // The user has released all touches while this view is the
                // responder. This typically means a gesture has succeeded
                if (!objectsRef.current.head) { return; }
                if (Math.abs(gestureState.dx) > Math.abs(gestureState.dy)) {
                    if (gestureState.dx < 0) {
                        //moveLeft
                        objectsRef.current.head.xspeed = -1;
                        objectsRef.current.head.yspeed = 0;
                    } else {
                        //moveRight
                        objectsRef.current.head.xspeed = 1;
                        objectsRef.current.head.yspeed = 0;
                    }
                } else {
                    if (gestureState.dy < 0) {
                        //moveUp
                        objectsRef.current.head.yspeed = -1;
                        objectsRef.current.head.xspeed = 0;
                    } else {
                        //moveDown
                        objectsRef.current.head.yspeed = 1;
                        objectsRef.current.head.xspeed = 0;
                    }
                }

            },
            onPanResponderTerminate: (evt, gestureState) => {
                // Another component has become the responder, so this gesture
                // should be cancelled
            },
            onShouldBlockNativeResponder: (evt, gestureState) => {
                // Returns whether this component should block native components from becoming the JS
                // responder. Returns true by default. Is currently only supported on android.
                return true;
            }
        })
    ).current;

    const GameLoop = (entities, { touches, dispatch, events }) => {
        let head = entities.head;
        let food = entities.food;
        let tail = entities.tail;

        head.nextMove -= 1;
        if (head.nextMove === 0) {
            head.nextMove = head.updateFrequency;
            if (
                head.position[0] + head.xspeed < 0 ||
                head.position[0] + head.xspeed >= Constants.GRID_WIDTH ||
                head.position[1] + head.yspeed < 0 ||
                head.position[1] + head.yspeed >= Constants.GRID_HEIGHT
            ) {
                // snake hits the wall
                dispatch({ type: "game-over" })
            } else {
                // move the tail
                let newTail = [[head.position[0], head.position[1]]];
                tail.elements = newTail.concat(tail.elements).slice(0, -1);

                // snake moves
                head.position[0] += head.xspeed;
                head.position[1] += head.yspeed;

                // check if it hits the tail
                for (let i = 0; i < tail.elements.length; i++) {
                    if (tail.elements[i][0] === head.position[0] && tail.elements[i][1] === head.position[1]) {
                        dispatch({ type: "game-over" })
                    }
                }

                if (head.position[0] === food.position[0] && head.position[1] === food.position[1]) {
                    // eating Food
                    tail.elements = [[food.position[0], food.position[1]]].concat(tail.elements);

                    dispatch({ type: "score" });
                    food.position[0] = randomBetween(0, Constants.GRID_WIDTH - 1);
                    food.position[1] = randomBetween(0, Constants.GRID_HEIGHT - 1);
                }
            }
        }
        return entities;
    };

    return (
        <View style={{ flex: 1, backgroundColor: 'black' }}>
            <ImageBackground source={require('./assets/back.png')} style={{ width: Constants.GRID_WIDTH * Constants.BLOCK_SIZE, height: Constants.GRID_HEIGHT * Constants.BLOCK_SIZE }}>
                <View  {...panResponder.panHandlers} style={{ width: Constants.GRID_WIDTH * Constants.BLOCK_SIZE, height: Constants.GRID_HEIGHT * Constants.BLOCK_SIZE }}>
                    <MinigameEngine
                        setupWorld={setupWorld}
                        systems={[GameLoop]}
                        onEvent={onEvent}
                        setScore={setScore}
                        score={score}
                        setGameEngine={setGameEngine}
                        showHelp={props.showHelp}
                        setShowHelp={props.setShowHelp}
                        gameId={GameIdConstants.SNAKE}
                    />
                </View>
            </ImageBackground>
        </View>

    );
};


export default Snake;
