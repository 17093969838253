const getIconSource = (iconId) => {
    switch (iconId) {
        case 1: return require('../assets/icons/tree.png');
        case 2: return require('../assets/icons/plant.png');
        case 3: return require('../assets/icons/kaktus.png');
        case 4: return require('../assets/icons/nature.png');
        case 5: return require('../assets/icons/skyscraper.png');
        case 6: return require('../assets/icons/yingyang.png');
        case 7: return require('../assets/icons/banana.png');
        case 8: return require('../assets/icons/cherry.png');
        case 9: return require('../assets/icons/apple.png');
        case 10: return require('../assets/icons/pear.png');
        case 11: return require('../assets/icons/orange.png');
        case 12: return require('../assets/icons/burger.png');
        case 13: return require('../assets/icons/pommes.png');
        case 14: return require('../assets/icons/flower.png');
        default: return require('../assets/icons/_unknown.png');
    }
}

export default getIconSource;