import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Audio } from 'expo-av'

import Bean from './Bean';
import GameIdConstants from '../../GameIdConstants';
import HelpScreen from '../../help/HelpScreen';

const colors = ['red', 'green', 'blue', 'pink', 'orange', 'purple'];

const CandyCrush = props => {
    const [score, setScore] = useState(0);
    const [map, setMap] = useState(undefined);
    const [lastClicked, setLastClicked] = useState(-1);
    const [rebuild, setRebuild] = useState(0);
    const soundObject = new Audio.Sound();

    useEffect(() => {
        setScore(0);
        loadMap();
    }, [props.reset]);

    useEffect(() => {
        async function asyncFunction() {
            if (map && rebuild === -1) {
                checkRow(true);
            } else if (map && rebuild === 0) {
                checkRow(true);
            }
        }
        asyncFunction();
    }, [map, rebuild]);

    useEffect(() => {
        async function asyncFunction() {
            if (rebuild === 1) {
                rebuildMap();
            }
        }
        asyncFunction();
    }, [rebuild]);

    useEffect((() => {
        const loadMusic = async () => {
            try {
                await soundObject.loadAsync(require('../../../assets/music/music_turtlematch.mp3'));
                await soundObject.setIsLoopingAsync(true);
                await soundObject.playAsync();
                // Your sound is playing!
            } catch (error) {
                // An error occurred!
            }
        }; loadMusic();
        return () => {
            const unloadMusic = async () => {
                await soundObject.unloadAsync();
            }; unloadMusic();
        }
    }), []);

    const rebuildMap = async () => {
        let change;

        do {
            change = false;
            for (let i = 5; i > 0; i--) {
                for (let j = 0; j < 6; j++) {
                    if (map[i][j].backgroundColor === 'white') {
                        map[i][j].backgroundColor = map[i - 1][j].backgroundColor;
                        map[i - 1][j].backgroundColor = 'white';
                        change = true;
                    }
                }
            }

            if (map[0][0].backgroundColor === 'white') {
                map[0][0].backgroundColor = colors[Math.floor(Math.random() * 5)];
            }
            for (let j = 1; j < 6; j++) {
                if (map[0][j].backgroundColor === 'white') {
                    let nextColor = Math.floor(Math.random() * 5);
                    if (colors[nextColor] === map[0][j - 1].backgroundColor) {
                        nextColor = (nextColor + 1) % 5;
                    }
                    map[0][j].backgroundColor = colors[nextColor];
                }
            }
            setMap(map);
        } while (change);

        setRebuild(0);
    }

    const onClick = (id) => {
        if (lastClicked === -1) {
            setLastClicked(id);
            const j1 = (id % 6);
            const i1 = (id - j1) / 6;
            map[i1][j1].selected = true;
        } else {
            const j1 = (id % 6);
            const i1 = (id - j1) / 6;
            const j2 = (lastClicked % 6);
            const i2 = (lastClicked - j2) / 6;
            map[i2][j2].selected = false;
            if ((i1 + 1 === i2 || i1 - 1 === i2 || i1 === i2) && (j1 + 1 === j2 || j1 - 1 === j2 || j1 === j2)) {
                const newMap = [];
                newMap.push(...map);
                const tmp = newMap[i1][j1];
                newMap[i1][j1] = newMap[i2][j2];
                newMap[i2][j2] = tmp;
                setMap(newMap);
            }
            setLastClicked(-1);
        }
    }

    let loadMap = () => {
        const newMap = [];
        for (let i = 0; i < 6; i++) {
            const row = [];
            for (let j = 0; j < 6; j++) {
                row.push({ backgroundColor: colors[Math.floor(Math.random() * 5)], selected: false });
            }
            newMap.push(row);
        }

        for (let i = 0; i < 6; i++) {
            const row = [];
            for (let j = 2; j < 6; j++) {
                if (newMap[i][j].backgroundColor === newMap[i][j - 1].backgroundColor && newMap[i][j].backgroundColor === newMap[i][j - 2].backgroundColor) {
                    do {
                        newMap[i][j].backgroundColor = colors[Math.floor(Math.random() * 5)];
                    } while (newMap[i][j].backgroundColor === newMap[i][j - 1].backgroundColor);
                }
            }
        }

        for (let i = 2; i < 6; i++) {
            const row = [];
            for (let j = 0; j < 6; j++) {
                if (newMap[i][j].backgroundColor === newMap[i - 1][j].backgroundColor && newMap[i][j].backgroundColor === newMap[i - 2][j].backgroundColor) {
                    do {
                        newMap[i][j].backgroundColor = colors[Math.floor(Math.random() * 5)];
                    } while (newMap[i][j].backgroundColor === newMap[i - 1][j].backgroundColor);
                }
            }
        }

        setMap(newMap);
    }

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    const checkRow = async (playerMove) => {
        const blockToDelete = [];
        for (let i = 0; i < 6; i++) {
            let lastColor = '';
            let nmbSameInRow = 0;
            for (let j = 0; j < 6; j++) {
                if (map[i][j].backgroundColor === lastColor) {
                    nmbSameInRow++;
                } else {
                    if (nmbSameInRow >= 2) {
                        for (let k = j - 1; k >= j - nmbSameInRow - 1; k--) {
                            blockToDelete.push({ i: i, j: k });
                        }
                    }
                    nmbSameInRow = 0;
                    lastColor = map[i][j].backgroundColor;
                }
            }
            if (nmbSameInRow >= 2) {
                for (let k = 5; k >= 5 - nmbSameInRow; k--) {
                    blockToDelete.push({ i: i, j: k });
                }
            }
        }

        for (let j = 0; j < 6; j++) {
            let lastColor = '';
            let nmbSameInRow = 0;
            for (let i = 0; i < 6; i++) {
                if (map[i][j].backgroundColor === lastColor) {
                    nmbSameInRow++;
                } else {
                    if (nmbSameInRow >= 2) {
                        for (let k = i - 1; k >= i - nmbSameInRow - 1; k--) {
                            blockToDelete.push({ i: k, j: j });
                        }
                    }
                    nmbSameInRow = 0;
                    lastColor = map[i][j].backgroundColor;
                }
            }
            if (nmbSameInRow >= 2) {
                for (let k = 5; k >= 5 - nmbSameInRow; k--) {
                    blockToDelete.push({ i: k, j: j });
                }
            }
        }

        for (let i = 0; i < blockToDelete.length; i++) {
            map[blockToDelete[i].i][blockToDelete[i].j].backgroundColor = 'white';
        }

        if (playerMove) {
            setScore(score + blockToDelete.length);
            props.updateBestTry(score + blockToDelete.length);
        }

        if (blockToDelete.length > 0) {
            await sleep(200);
            setRebuild(1);
            return true;
        }
        setRebuild(-1);
        return false;
    }

    return (
        <View style={{ flex: 1, backgroundColor: '#000000' }}>
            {!props.showHelp && <Text style={styles.score}>{score}</Text>}
            {map && <View style={{ flex: 1, justifyContent: 'center' }}>
                <View style={styles.candyRow}>
                    <Bean id={0} data={map[0][0]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={1} data={map[0][1]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={2} data={map[0][2]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={3} data={map[0][3]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={4} data={map[0][4]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={5} data={map[0][5]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                </View>
                <View style={styles.candyRow}>
                    <Bean id={6} data={map[1][0]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={7} data={map[1][1]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={8} data={map[1][2]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={9} data={map[1][3]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={10} data={map[1][4]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={11} data={map[1][5]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                </View>
                <View style={styles.candyRow}>
                    <Bean id={12} data={map[2][0]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={13} data={map[2][1]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={14} data={map[2][2]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={15} data={map[2][3]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={16} data={map[2][4]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={17} data={map[2][5]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                </View>
                <View style={styles.candyRow}>
                    <Bean id={18} data={map[3][0]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={19} data={map[3][1]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={20} data={map[3][2]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={21} data={map[3][3]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={22} data={map[3][4]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={23} data={map[3][5]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                </View>
                <View style={styles.candyRow}>
                    <Bean id={24} data={map[4][0]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={25} data={map[4][1]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={26} data={map[4][2]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={27} data={map[4][3]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={28} data={map[4][4]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={29} data={map[4][5]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                </View>
                <View style={styles.candyRow}>
                    <Bean id={30} data={map[5][0]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={31} data={map[5][1]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={32} data={map[5][2]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={33} data={map[5][3]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={34} data={map[5][4]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                    <Bean id={35} data={map[5][5]} onPress={(id) => onClick(id)} style={styles.bean} ></Bean>
                </View>
            </View>}
            {props.showHelp && <HelpScreen close={() => { props.setShowHelp(false); }} gameId={GameIdConstants.TURTLE_MATCH} />}
        </View >
    )
}

const styles = StyleSheet.create({
    candyRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 5
    },
    bean: {
        aspectRatio: 1,
    },
    score: {
        position: 'absolute',
        top: '2%',
        width: '100%',
        textAlign: 'center',
        fontSize: 28,
        color: '#AAAAAA',
        fontFamily: 'PressStart2P_400Regular'
    },
});

export default CandyCrush;