class Point {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    diff = (p) => {
        return new Point(this.x - p.x, this.y - p.y);
    }

    static scale = (point, ratio) => {
        return new Point(point.x * ratio, point.y * ratio);
    }

    static scale2d = (point, ratio) => {
        return new Point(point.x * ratio.x, point.y * ratio.y);
    }
}

export default Point;