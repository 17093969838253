import World from './World';
import Point from './Point';
import WorldObject from './WorldObject';

class WorldVectorObject extends WorldObject {
    constructor(x, y, width, height, name, topPoints, rightPoints, bottomPoints, leftPoints, mass, isStatic) {
        super(x, y, width, height, name, mass, isStatic);
        this.topPoints = topPoints;
        this.rightPoints = rightPoints;
        this.bottomPoints = bottomPoints;
        this.leftPoints = leftPoints;
    }

    getTopPoints = () => {
        const points = [];
        for (let i = 0; i < this.topPoints.length; i++) {
            points.push(new Point(this.topPoints[i].x + this.x, this.topPoints[i].y + this.y))
        }
        return points;
    }

    getBottomPoints = () => {
        const points = [];
        for (let i = 0; i < this.bottomPoints.length; i++) {
            points.push(new Point(this.bottomPoints[i].x + this.x, this.bottomPoints[i].y + this.y))
        }
        return points;
    }

    getRightPoints = () => {
        const points = [];
        for (let i = 0; i < this.rightPoints.length; i++) {
            points.push(new Point(this.rightPoints[i].x + this.x, this.rightPoints[i].y + this.y))
        }
        return points;
    }

    getLeftPoints = () => {
        const points = [];
        for (let i = 0; i < this.leftPoints.length; i++) {
            points.push(new Point(this.leftPoints[i].x + this.x, this.leftPoints[i].y + this.y))
        }
        return points;
    }

    getHeight = (x) => {
        const part = (x - this.x) / (this.width);
        if (part < 0.25) {
            return this.bottomPoints[0].y - this.topPoints[0].y;
        }
        if (part > 0.75) {
            return this.bottomPoints[Math.floor((this.bottomPoints.length - 1) / 2)].y - this.topPoints[Math.floor((this.topPoints.length - 1) / 2)].y;
        }
        return this.bottomPoints[this.bottomPoints.length - 1].y - this.topPoints[this.topPoints.length - 1].y;
    }

    getWidth = (y) => {
        const part = (y - this.y) / (this.height);
        if (part < 0.25) {
            return this.rightPoints[0].x - this.leftPoints[0].x;
        }
        if (part > 0.75) {
            return this.rightPoints[Math.floor((this.rightPoints.length - 1) / 2)].x - this.leftPoints[Math.floor((this.leftPoints.length - 1) / 2)].x;
        }
        return this.rightPoints[this.rightPoints.length - 1].x - this.leftPoints[this.leftPoints.length - 1].x;
    }

    getY = (x) => {
        const part = (x - this.x) / (this.width);
        if (part < 0.25) {
            return this.y + this.topPoints[0].y;
        }
        if (part > 0.75) {
            return this.y + this.topPoints[this.topPoints.length - 1].y;
        }
        return this.y + this.topPoints[Math.floor((this.topPoints.length - 1) / 2)].y;
    }

    getX = (y) => {
        const part = (y - this.y) / (this.height);
        if (part < 0.25) {
            return this.x + this.leftPoints[0].x;
        }
        if (part > 0.75) {
            return this.x + this.leftPoints[this.leftPoints.length - 1].x;
        }
        return this.x + this.leftPoints[Math.floor((this.leftPoints.length - 1) / 2)].x;
    }
}

export default WorldVectorObject;