import React from "react";
import { Image, View } from "react-native";

const Player = props => {
    return (
        <View
            style={{
                position: "absolute",
                left: props.body.x,
                top: props.body.y,
                width: props.body.width,
                height: props.body.height,
                opacity: props.body.opacity ? props.body.opacity : 1.0,
            }} >
            <Image
                source={props.body.imageSource ? props.body.imageSource : props.imageSource}
                fadeDuration={0}
                resizeMode='stretch'
                style={{
                    width: props.body.width,
                    height: props.body.height,
                }} />
        </View >
    );
};

export default Player;
