import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Dimensions, Image, Modal } from 'react-native';

import ColorConstants from '../screens/ColorConstants';
import getIconSource from '../helper/getIconSource';

const IconPicker = props => {
    const [showModal, setShowModal] = useState(false);
    const [icon, setIcon] = useState(props.icon ? props.icon : 1);

    const iconSize = Math.floor(Dimensions.get('window').width * 0.3 * 0.8);

    const rows = [];
    const numberOfRows = 5;
    for (let i = 0; i < numberOfRows; i++) {
        const columns = [];
        for (let j = 1; j <= 3; j++) {
            const index = i * 3 + j;
            if (index > 14) { break; };
            columns.push(
                <TouchableOpacity key={'icon_' + index} onPress={() => { setShowModal(false); props.setIcon(index); setIcon(index) }} style={{ width: iconSize, height: iconSize }}>
                    <Image
                        source={getIconSource(index)}
                        fadeDuration={0}
                        style={{ width: iconSize, height: iconSize }}
                        resizeMode='contain'
                    />
                </TouchableOpacity>
            )
        }
        rows.push(<View key={'iconrow_' + i} style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingHorizontal: 10, alignItems: 'center', marginVertical: 5 }}>{columns}</View>);
    }

    return (
        <View style={props.style}>
            <Modal
                animationType='fade'
                transparent={true}
                visible={showModal}
            >
                <View style={{ flex: 1, alignItems: 'center', marginTop: 25 }}>
                    <View style={{ backgroundColor: ColorConstants.ROOMS_ICONPICKER_BACKGROUND, width: '80%', borderRadius: 50, paddingVertical: 20 }}>
                        {rows}
                    </View>
                </View>
            </Modal>
            <TouchableOpacity onPress={() => setShowModal(true)}>
                <Image
                    source={getIconSource(icon)}
                    fadeDuration={0}
                    style={styles.icon}
                    resizeMode='center'
                />
            </TouchableOpacity>
        </View >
    );
};

const styles = StyleSheet.create({
    icon: {
        backgroundColor: 'transparent',
        width: Math.ceil((68 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
        height: Math.ceil((68 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
        marginRight: 5
    }
});

export default IconPicker;
