import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Dimensions, Image, ScrollView } from 'react-native';
import getIconSource from '../../helper/getIconSource';

const IconPicker = props => {
    const [showModal, setShowModal] = useState(false);
    const [icon, setIcon] = useState(props.icon ? props.icon : 1);

    const selectScreen = <View style={{ flex: 1 }}><Text>Icon</Text></View>;

    const iconSize = Math.min(100, Math.floor(Dimensions.get('window').width * 0.2 * 0.6));

    const rows = [];
    const numberOfRows = 3;
    for (let i = 0; i < numberOfRows; i++) {
        const columns = [];
        for (let j = 1; j <= 5; j++) {
            const index = i * 5 + j;
            if (index > 14) { break; };
            columns.push(
                <TouchableOpacity key={'icon_' + index} onPress={() => { setShowModal(false); props.setIcon(index); setIcon(index) }} style={{ width: iconSize, height: iconSize, margin: 5 }}>
                    <Image
                        source={getIconSource(index)}
                        fadeDuration={0}
                        style={{ width: iconSize, height: iconSize }}
                        resizeMode='stretch'
                    />
                </TouchableOpacity>
            )
        }
        rows.push(<View key={'iconrow_' + i} style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', paddingHorizontal: 10, alignItems: 'center' }}>{columns}</View>);
    }


    if (showModal) {
        return (
            <ScrollView>
                <View style={styles.iconPicker}>
                    {rows}
                </View>
            </ScrollView>
        )
    }


    return (
        <View style={props.style}>
            <TouchableOpacity onPress={() => setShowModal(true)}>
                <Image
                    source={getIconSource(icon)}
                    fadeDuration={0}
                    style={styles.icon}
                    resizeMode='stretch'
                />
            </TouchableOpacity>
        </View >
    );
};

const styles = StyleSheet.create({
    icon: {
        backgroundColor: 'transparent',
        width: Math.ceil((68 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
        height: Math.ceil((68 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
        marginRight: 5
    },
    iconPicker: {
        width: '100%',
    }
});

export default IconPicker;
