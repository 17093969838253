import GameIdConstants from '../GameIdConstants';
import Instructions from './Instructions';
import getPlatform from '../../helper/getPlatform'

const platform = getPlatform();

const Constants = {
    [GameIdConstants.QUIZ]: {
        title: "QUIZ",
        story: "It is just a simple quiz. Nothing special. If you answer wrong, your firstborn will die. If you answer each question correctly, you will be burned for sorcery.",
        control: [{ instruction: Instructions.other, event: 'How to play', specificText: 'Click on the answer you think is correct. Click "Next" for a new question.', img: require('./assets/quiz.gif') }]
    },
    [GameIdConstants.FLAPPY_UNICORN]: {
        title: "FLAPPY UNICORN",
        story: "It's finally happening. Today you will graduate from Unicorn University. But like Murphy said, \"Anything that can go wrong will go wrong\". And so it happened. You missed your bus. So, what now? Of course you could fly. But this is something you really suck at. Still, you have no choice. So let's go. Just don't look down. And remember, you're allergic to clouds and will die instantly if you hit one. So please be careful.",
        control: [{ instruction: Instructions.tap, event: 'Go Up', img: require('./assets/unicorn_up.gif') }]
    },
    [GameIdConstants.SNAKE]: {
        title: "SNAKE",
        story: "Ever since you saw this documentary about baby mice growing up without their parents, you decided to go vegan and eat only apples. Thank God there is always exactly one apple. It\'s just that your head has become so sensitive lately. So make sure that you don\'t bump it anywhere.",
        control: [{ instruction: Instructions.swipeToDirection, event: 'Move', img: require('./assets/snake_move.gif') }]
    },
    [GameIdConstants.STAR_REACTION]: {
        title: "STAR REACTION",
        story: "Last night was a little too rough.  You can't remember anything. But somehow you find yourself alone in a spaceship. Another classic Monday. In order not to ruin your day completely you should rather avoid the stars.",
        control: [{ instruction: Instructions.tilt, event: 'Move', img: require('./assets/starreaction.gif') }]
    },
    [GameIdConstants.MEMORY_MASTER]: {
        title: "MEMORY MASTER",
        story: "If only I still knew what I wanted to write here...",
        control: [{ instruction: Instructions.other, event: 'How to play', specificText: 'Try to remember as many words as possible. When you think you are ready, click "Ready". Then type in all the words you can remember. Do not forget the time!', img: require('./assets/rememberwords.gif') }]
    },
    [GameIdConstants.COLOR_CONFUSION]: {
        title: "COLOR CONFUSION",
        story: "Colors are difficult. Words too. And now everything comes together. If you only had paid attention in the preschool",
        control: [{ instruction: Instructions.other, event: 'How to play', specificText: 'If the color name you read on the screen is the name of the color that the last color name had, click the green thumb up, otherwise click the red thumb down.', img: require('./assets/colorwords.gif') }]
    },
    [GameIdConstants.TURTLE_MATCH]: {
        title: "TURTLE MATCH",
        story: "It is turtle mating season. Only turtles of the same color can mate. Unfortunately turtles are color blind, so you have to help them to find each other.",
        control: [{ instruction: Instructions.other, event: 'How to play', specificText: 'Click on a turtle and then click on another turtle next to that one to change their places. If you line up three or more turtles, they will swim away and you will earn points.', img: require('./assets/turtlematch.gif') }]
    },
    [GameIdConstants.RABBIT_RUN]: {
        title: "RABBIT RUN",
        story: "Your wife is coming home from work soon. But unfortunately you forgot to go shopping again and all stores are closed. Therefore you have to go quickly and collect as many carrots as you can. Don't let the snakes bite you, unless you want your children to grow up without a father. The best way is to jump on the head of the snakes to put them out of action.",
        control: [{ instruction: Instructions.tilt, event: 'Move', img: require('./assets/rabbit_run_move.gif') }, { instruction: Instructions.tap, event: 'Jump', img: require('./assets/rabbit_run_jump.gif') }]
    },
    [GameIdConstants.KIWI_JUMP]: {
        title: "KIWI JUMP",
        story: "Some people think they know how to party, but they have never experienced a Kiwi Party. But I did today ... how the f*ck did I get here?",
        control: [{ instruction: Instructions.tilt, event: 'Move', img: require('./assets/kiwi_move.gif') }]
    },
    [GameIdConstants['99_RED_BALLOONS']]: {
        title: "BALLOON PARTY",
        story: "The neighbor child has birthday. You hate kids and want to ruin the party. Therefore you try to destroy all the balloons. But the kids know how to defend themselves and have hidden bombs under the balloons. You better not blow them up.",
        control: [{ instruction: Instructions.touch, event: 'Pop a balloon', img: require('./assets/balloons.gif') }]
    },
    [GameIdConstants.DUCK_FISHING]: {
        title: "DUCK FISHING",
        story: "All your life you have been working as a fisherman. In the course of time you have specialized in ducks. As you know, you are only allowed to catch male ducks, because if you catch female ducks it would have disastrous consequences. First of all, fewer ducklings would be born, which would lead to a decrease in the duck population. As a result, fewer and fewer Asian restaurants can offer duck. Logically this would lead to a bloody war for the last dish with duck. The result of this war would be a last surviving person eating the now cold number 36 from asia palace.",
        control: [{ instruction: Instructions.swipeToDirection, event: 'Move fishing net', img: require('./assets/duck_net_move.gif') }, { instruction: Instructions.other, event: 'Catch a duck', specificText: 'Move the net under a duck and pull it up.', img: require('./assets/duck_catch.gif') }]
    },
    [GameIdConstants.PONG]: {
        title: "PONG",
        story: "It is the year 3000, and after getting up you decide that today you identify yourself as a Pong paddle. You go to your trusted doctor and let your body change. What you didn't know? Pong paddles die when they miss a ball. So do your best!",
        control: platform !== 'web' ? [{ instruction: Instructions.swipeToDirection, event: 'Move', img: require('./assets/pong_move.gif'), specificText: 'Swipe to the left or right. The bar will be there where your finger is.' }]
            : [{ instruction: Instructions.swipeToDirection, event: 'Move with Keyboard', img: require('./assets/pong_move.gif') }, { instruction: Instructions.other, event: 'Move with mouse', specificText: 'Click left mouse button and move cursor', img: require('./assets/pong_move.gif') }]
    },
    [GameIdConstants.SPACE_BATTLE]: {
        title: "SPACE BATTLE",
        story: "An asteroid storm is approaching the earth. You are the last hope of humanity. Can you destroy the asteroids before they destroy us?",
        control: [{ instruction: Instructions.tilt, event: 'Move', img: require('./assets/spacebattle_move.gif') }, { instruction: Instructions.tap, event: 'Shoot', img: require('./assets/spacebattle_shoot.gif') }]
    },
    [GameIdConstants.KNOW_FRIENDS]: {
        title: "Do you know your friends?",
        story: "Good friends know each other. But are you also a good friend? Now it's a matter of finding out by matching the answers to a question to the player who gave them.",
        control: [{ instruction: Instructions.other, event: 'How to play', specificText: 'First answer the question that is displayed and click "Submit" Then, when all players have answered, you must assign an answer to each player that you think is from that player.', img: require('./assets/knowfriends.gif') }]
    },
};

export default Constants;