import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Audio } from 'expo-av'

import QuizContainer from './Components/QuizContainer'
import base64 from 'base-64';
import utf8 from 'utf8';
import HelpScreen from '../../help/HelpScreen';
import GameIdConstants from '../../GameIdConstants';

const QuizScreen = props => {
    const [score, setScore] = useState(0);
    const [quiz, setQuiz] = useState([]);
    const soundObject = new Audio.Sound();

    useEffect((() => {
        const loadMusic = async () => {
            try {
                await soundObject.loadAsync(require('../../../assets/music/music_quiz.mp3'));
                await soundObject.setIsLoopingAsync(true);
                await soundObject.playAsync();
                // Your sound is playing!
            } catch (error) {
                // An error occurred!
            }
        }; loadMusic();
        return () => {
            const unloadMusic = async () => {
                await soundObject.unloadAsync();
            }; unloadMusic();
        }
    }), []);

    useEffect(() => { nextQuestion() }, []);

    useEffect(() => {
        setScore(0);
        nextQuestion();
    }, [props.reset]);

    const onAnswer = (correct) => {
        if (correct) {
            props.updateBestTry(score + 1);
            setScore(score + 1);
        }
    }

    const nextQuestion = () => {
        if (quiz.length <= 5) {
            fetch('https://opentdb.com/api.php?amount=10&type=multiple&encode=base64')
                .then((response) => response.json())
                .then((json) => {
                    const uff = [];
                    for (let i = 0; i < 10; i++) {
                        const newQuiz = {
                            question: utf8.decode(base64.decode(json.results[i].question)),
                            correct: utf8.decode(base64.decode(json.results[i].correct_answer)),
                            false0: utf8.decode(base64.decode(json.results[i].incorrect_answers[0])),
                            false1: utf8.decode(base64.decode(json.results[i].incorrect_answers[1])),
                            false2: utf8.decode(base64.decode(json.results[i].incorrect_answers[2])),
                        }
                        uff.push(newQuiz);
                    }
                    setQuiz(uff);
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            const uff = [];
            uff.push(...quiz.slice(1));
            setQuiz(uff);
        }
    }

    return (
        <View style={{ flex: 1, backgroundColor: 'black' }}>
            {quiz.length > 0 && <QuizContainer quiz={quiz[0]} onAnswer={(correct) => onAnswer(correct)} nextQuestion={nextQuestion} score={score} />}
            {quiz.length <= 0 && <Text style={{ color: 'white' }}>Loading...</Text>}
            {props.showHelp && <HelpScreen close={() => { props.setShowHelp(false); }} gameId={GameIdConstants.QUIZ} />}
        </View>
    );
};

const styles = StyleSheet.create({

});

export default QuizScreen;
