import { Platform } from 'react-native';
import PlatformWeb from 'react-platform-js';

const getPlatform = () => {
    switch (Platform.OS) {
        case 'android':
            return 'android';
        case 'ios':
            return 'ios';
        case 'web':
            if (PlatformWeb.OS.toLowerCase().includes('ios') || PlatformWeb.OS === 'Android') {
                return 'web_mobile';
            } else {
                return 'web';
            }
    }
}

export default getPlatform;