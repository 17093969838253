import React from 'react';
import { Dimensions, Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import Link from '../../Components/Link';
import * as WebBrowser from 'expo-web-browser';
import { AntDesign } from '@expo/vector-icons';

import scaleFontSize from '../../helper/scaleFunctions';

const PlayOnMobileScreen = props => {
    const size = Dimensions.get('window').width < Dimensions.get('window').height ? Dimensions.get('window').width : Dimensions.get('window').height * 0.8;
    return (
        <View style={styles.page}>
            <View style={{ paddingTop: 10, paddingLeft: 15, justifyContent: 'center', width: '100%' }}>
                <TouchableOpacity onPress={() => props.navigation.goBack()}>
                    <AntDesign name="back" size={scaleFontSize(28)} color="white" />
                </TouchableOpacity>
            </View>
            <View>
                <Text style={{ fontFamily: 'PressStart2P_400Regular', fontSize: scaleFontSize(24), textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: 'white', width: '100%' }}>Play on Android</Text>
                <TouchableOpacity onPress={() => WebBrowser.openBrowserAsync('https://play.google.com/store/apps/details?id=com.uNICOrn.drinkAtHome')} >
                    <Image
                        source={require('../../assets/home/getAndroid.png')}
                        fadeDuration={0}
                        style={{ width: size, height: size }}
                        resizeMode='center'
                    />
                </TouchableOpacity>
            </View>
            <View>
                <Text style={{ fontFamily: 'PressStart2P_400Regular', fontSize: scaleFontSize(24), textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: 'white', width: '100%' }}>Play on IOS</Text>
                <Text style={{ color: 'white' }}>Unfortunately Trash Game Night is currently not available in the App Store. But you can play online on any browser on your iPhone!</Text>
            </View>
        </View >
    )
}

export default PlayOnMobileScreen;

const styles = StyleSheet.create({
    page: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: '#202020'
    }
})
