import React, { useState, useEffect } from "react";
import { Dimensions, Platform } from 'react-native';
import { Accelerometer } from 'expo-sensors';
import { Audio } from 'expo-av'

import World from '../physics/World';
import WorldObject from '../physics/WorldObject';
import WorldVectorObject from '../physics/WorldVectorObject';
import MinigameEngine from "../MinigameEngine";
import Player from "../objects/Player";
import Wall from '../objects/Wall';
import Point from "../physics/Point";
import GameIdConstants from '../../GameIdConstants';

const Constants = {
    MAX_WIDTH: Dimensions.get("window").width,
    MAX_HEIGHT: Dimensions.get("window").height - 50,
    KIWI_HEIGHT: Math.ceil((75 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    KIWI_WIDTH: Math.ceil((75 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    FLOOR_HEIGHT: Math.ceil((50 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    PLATFORM_HEIGHT: Math.ceil((15 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    PLATFORM_WIDTH: Dimensions.get("window").width * 0.3,
    REAL_HEIGHT: (Dimensions.get("window").height - 50) - (Math.ceil((50 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861)))),
    PLATFORM_DISTANCE: ((Dimensions.get("window").height - 50) - (Math.ceil((50 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))))) * 0.5,
};


const KiwiJump = props => {
    const [score, setScore] = useState(0);
    const [gameEngine, setGameEngine] = useState(null);
    const [world, setWorld] = useState(new World([]));
    const [backColor, setBackColor] = useState('#FFFFFF');
    const soundObject = new Audio.Sound();

    useEffect((() => {
        const loadMusic = async () => {
            try {
                await soundObject.loadAsync(require('../../../assets/music/music_kiwijump.mp3'));
                await soundObject.setIsLoopingAsync(true);
                await soundObject.playAsync();
                // Your sound is playing!
            } catch (error) {
                // An error occurred!
            }
        }; loadMusic();
        return () => {
            const unloadMusic = async () => {
                await soundObject.unloadAsync();
            }; unloadMusic();
            Accelerometer.removeAllListeners();
        }
    }), []);

    const onEvent = (e) => {
        if (e.type === "game-over") {
            Accelerometer.removeAllListeners();
        } else if (e.type === "score") {
            setScore(score + 1);
            props.updateBestTry(score + 1);
            world.objects.kiwi.score = score + 1;
        } else if (e.type === "moveLeft") {
            world.objects.kiwi.moveLeft(Constants.MAX_WIDTH * 0.1, 15);
        } else if (e.type === "moveRight") {
            world.objects.kiwi.moveRight(Constants.MAX_WIDTH * 0.1, 15);
        }
    }

    const getXPos = () => {
        return Math.floor(Math.random() * ((Constants.MAX_WIDTH - Constants.PLATFORM_WIDTH) - Constants.PLATFORM_WIDTH + 1) + Constants.PLATFORM_WIDTH);
    }

    const getRandomColor = () => {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const setupWorld = () => {
        const objects = [];
        objects['floor'] = new WorldObject(0, Constants.MAX_HEIGHT - Constants.FLOOR_HEIGHT, Constants.MAX_WIDTH, Constants.FLOOR_HEIGHT, 'floor', 0, true);
        objects.floor.color = '#80FF80'
        objects['platform1'] = new WorldObject(Constants.MAX_WIDTH / 2, Constants.REAL_HEIGHT * 0.75, Constants.PLATFORM_WIDTH, Constants.PLATFORM_HEIGHT, 'platform1', 0, false);
        objects['platform1'].color = getRandomColor();
        objects['platform1'].borderWidth = 1;
        objects['platform2'] = new WorldObject(Constants.MAX_WIDTH / 2, Constants.REAL_HEIGHT * 0.75 - (Constants.PLATFORM_HEIGHT + Constants.PLATFORM_DISTANCE), Constants.PLATFORM_WIDTH, Constants.PLATFORM_HEIGHT, 'platform2', 0, false);
        objects['platform2'].color = getRandomColor();
        objects['platform2'].borderWidth = 1;
        objects['platform3'] = new WorldObject(Constants.MAX_WIDTH / 2, Constants.REAL_HEIGHT * 0.75 - 2 * (Constants.PLATFORM_HEIGHT + Constants.PLATFORM_DISTANCE), Constants.PLATFORM_WIDTH, Constants.PLATFORM_HEIGHT, 'platform3', 0, false);
        objects['platform3'].color = getRandomColor();
        objects['platform3'].borderWidth = 1;
        objects['platform4'] = new WorldObject(Constants.MAX_WIDTH / 2, Constants.REAL_HEIGHT * 0.75 - 3 * (Constants.PLATFORM_HEIGHT + Constants.PLATFORM_DISTANCE), Constants.PLATFORM_WIDTH, Constants.PLATFORM_HEIGHT, 'platform4', 0, false);
        objects['platform4'].color = getRandomColor();
        objects['platform4'].borderWidth = 1;
        objects['kiwi'] = new WorldVectorObject(
            Constants.MAX_WIDTH / 2,
            Constants.MAX_HEIGHT - Constants.FLOOR_HEIGHT - 2 * Constants.KIWI_HEIGHT,
            Constants.KIWI_WIDTH,
            Constants.KIWI_HEIGHT,
            'kiwi',
            [new Point(0, 0), new Point(Constants.KIWI_WIDTH, 0)],
            [new Point(Constants.KIWI_WIDTH, 0), new Point(Constants.KIWI_WIDTH, Constants.KIWI_HEIGHT)],
            [new Point(0, Constants.KIWI_HEIGHT), new Point(Constants.KIWI_WIDTH * 0.25, Constants.KIWI_HEIGHT), new Point(Constants.KIWI_WIDTH / 2, Constants.KIWI_HEIGHT), new Point(Constants.KIWI_WIDTH * 0.75, Constants.KIWI_HEIGHT), new Point(Constants.KIWI_WIDTH, Constants.KIWI_HEIGHT)],
            [new Point(0, 0), new Point(0, Constants.KIWI_HEIGHT)],
            1, true);
        objects.kiwi.score = 0;


        const moveDownTime = Math.floor(Constants.PLATFORM_DISTANCE * 0.2);
        const jumpTime = Math.floor((Constants.PLATFORM_DISTANCE) * 0.5 * 0.2);

        if (Platform.OS === 'android') {
            Accelerometer.addListener(motionData => {
                if (motionData.x > 0) {
                    objects.kiwi.moveLeft(motionData.x * 30);
                } else {
                    objects.kiwi.moveRight(Math.abs(motionData.x * 30));
                }
            });
        } else {
            Accelerometer.addListener(motionData => {
                if (motionData.x > 0) {
                    objects.kiwi.moveRight(Math.abs(motionData.x * 30));
                } else {
                    objects.kiwi.moveLeft(motionData.x * 30);
                }
            });
        }
        Accelerometer.setUpdateInterval(1);

        objects['kiwi'].setCollisionHandler(false, (isActive, obj, sP, position) => {

            if (obj.name === "floor") {
                if (objects['kiwi'].score > 0) {
                    gameEngine.dispatch({ type: "game-over" });
                    return 0;
                } else {
                    objects.kiwi.moveUp((Constants.PLATFORM_DISTANCE) * 0.5, jumpTime);
                    return 2;
                }
            }

            if (objects['kiwi'].score === 1) {
                objects.floor.y = Constants.MAX_HEIGHT - 1;
                objects.floor.height = 1;
                objects.floor.color = 'black';
            }

            if (position === 2) {
                let movePlatformDownTime = moveDownTime - objects.kiwi.score;
                setBackColor(obj.color);
                objects.kiwi.moveUp((Constants.PLATFORM_DISTANCE) * 0.5, jumpTime);
                objects.platform1.moveDown(Constants.PLATFORM_DISTANCE, movePlatformDownTime > 1 ? movePlatformDownTime : 1);
                objects.platform2.moveDown(Constants.PLATFORM_DISTANCE, movePlatformDownTime > 1 ? movePlatformDownTime : 1);
                objects.platform3.moveDown(Constants.PLATFORM_DISTANCE, movePlatformDownTime > 1 ? movePlatformDownTime : 1);
                objects.platform4.moveDown(Constants.PLATFORM_DISTANCE, movePlatformDownTime > 1 ? movePlatformDownTime : 1);
                return 2;
            } else {
                return 2;
            }
        });

        const world = new World(objects);
        setWorld(world);

        return {
            physics: { gameEngine: gameEngine },
            world: world,
            kiwi: { body: world.objects.kiwi, imageSource: require('./assets/kiwi.png'), renderer: Player },
            platform1: { body: world.objects.platform1, renderer: Wall },
            platform2: { body: world.objects.platform2, renderer: Wall },
            platform3: { body: world.objects.platform3, renderer: Wall },
            platform4: { body: world.objects.platform4, renderer: Wall },
            floor: { body: world.objects.floor, renderer: Wall },
        }
    };

    const Physics = (entities, { touches, time, dispatch, motionData }) => {

        if (entities.kiwi.body.x + entities.kiwi.body.width / 2 < 0) {
            entities.kiwi.body.setPosition(Constants.MAX_WIDTH - entities.kiwi.body.width / 2, entities.kiwi.body.y);
        }

        if (entities.kiwi.body.x + entities.kiwi.body.width / 2 > Constants.MAX_WIDTH) {
            entities.kiwi.body.setPosition(0 - entities.kiwi.body.width / 2, entities.kiwi.body.y);
        }


        for (let i = 1; i <= 4; i++) {
            if (!entities["platform" + i].score && entities["platform" + i].body.y > entities.kiwi.body.y + Constants.PLATFORM_HEIGHT) {
                entities["platform" + i].score = true;
                dispatch({ type: "score" });
            }
            let nextI = i === 1 ? 4 : i - 1;
            if (entities["platform" + i].body.y >= Constants.MAX_HEIGHT - Constants.FLOOR_HEIGHT) {
                entities["platform" + i].body.setPosition(getXPos(), entities["platform" + nextI].body.getY() - Constants.PLATFORM_DISTANCE);
                if (entities["platform" + i].body.width >= 22) {
                    entities["platform" + i].body.setWidth(entities["platform" + i].body.width - 12);
                }
                entities["platform" + i].score = false;
            }

            if (entities["platform" + i].body.speedHorizontal === 0) {
                if (Math.random() > 0.5) {
                    const moveLeftVal = Math.floor(Math.random() * (entities["platform" + i].body.x - 49)) + 50;
                    const time = Math.floor(15 * Constants.MAX_WIDTH / moveLeftVal);
                    entities["platform" + i].body.moveLeft(moveLeftVal, time);
                } else {
                    const moveRightVal = Math.floor(Math.random() * (Constants.MAX_WIDTH - (entities["platform" + i].body.x + entities["platform" + i].body.width) - 49)) + 50;
                    const time = Math.floor(15 * Constants.MAX_WIDTH / moveRightVal);
                    entities["platform" + i].body.moveRight(moveRightVal, time);
                }
            }
        }

        entities.world.update();
        return entities;
    };

    return (
        <MinigameEngine
            setupWorld={setupWorld}
            systems={[Physics]}
            onEvent={onEvent}
            setScore={setScore}
            score={score}
            setGameEngine={setGameEngine}
            backgroundColor={backColor}
            showHelp={props.showHelp}
            setShowHelp={props.setShowHelp}
            gameId={GameIdConstants.KIWI_JUMP}
        />
    );
};

export default KiwiJump;
