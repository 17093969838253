import React, { useState, useRef, useEffect } from "react";
import { Dimensions, PanResponder, View } from 'react-native';
import { Audio } from 'expo-av'

import World from '../physics/World';
import WorldObject from '../physics/WorldObject';
import WorldVectorObject from '../physics/WorldVectorObject';
import MinigameEngine from "../MinigameEngine";
import Player from "../objects/Player";
import Wall from '../objects/Wall';
import Point from "../physics/Point";
import Rod from './Rod';
import GameIdConstants from '../../GameIdConstants';
import getPlatform from "../../../helper/getPlatform";

const Constants = {
    MAX_WIDTH: Dimensions.get("window").width,
    MAX_HEIGHT: Dimensions.get("window").height - 50,
    ROD_HEIGHT: 2 * (Dimensions.get("window").height - 50),
    ROD_SIZE: Math.ceil((125 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    DUCK_HEIGHT: Math.ceil((75 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    DUCK_WIDTH: Math.ceil((75 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    DUCK_Y: ((Dimensions.get("window").height - 50) * 0.875) - (Math.ceil((75 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861)))) * 0.6,
    WALL_WIDTH: Math.ceil((1 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
};

const DuckFishing = props => {
    const [score, setScore] = useState(0);
    const [gameEngine, setGameEngine] = useState(null);
    const [world, _setWorld] = useState(new World([]));
    const worldRef = useRef(world);
    const showHelpRef = useRef(props.showHelp);
    const platform = getPlatform();
    const moveFactor = platform === 'web' ? 0.02 : platform === 'web_mobile' ? 0.25 : platform === 'android' ? 0.1 : 0.15;
    const soundObject = new Audio.Sound();

    const setWorld = objects => {
        worldRef.current = objects
        _setWorld(objects)
    }

    useEffect(() => {
        showHelpRef.current = props.showHelp
    }, [props.showHelp])

    useEffect((() => {
        const loadMusic = async () => {
            try {
                await soundObject.loadAsync(require('../../../assets/music/music_duckfishing.mp3'));
                await soundObject.setIsLoopingAsync(true);
                await soundObject.playAsync();
                // Your sound is playing!
            } catch (error) {
                // An error occurred!
            }
        }; loadMusic();
        return () => {
            const unloadMusic = async () => {
                await soundObject.unloadAsync();
            }; unloadMusic();
        }
    }), []);

    const onEvent = (e) => {
        if (e.type === "score") {
            setScore(score + 1);
            props.updateBestTry(score + 1);
        } else if (e.type === "moveLeft") {
            world.objects.rod.moveLeft(Constants.MAX_WIDTH * 0.05, 10);
        } else if (e.type === "moveRight") {
            world.objects.rod.moveRight(Constants.MAX_WIDTH * 0.05, 10);
        } else if (e.type === "moveDown") {
            world.objects.rod.moveDown(Constants.MAX_WIDTH * 0.075, 10);
        } else if (e.type === "moveUp") {
            world.objects.rod.moveUp(Constants.MAX_WIDTH * 0.075, 15);
        }
    }

    const getY = () => {
        return Math.floor(Math.random() * ((Constants.MAX_HEIGHT * 0.6) - Constants.DUCK_HEIGHT + 1) + Constants.DUCK_HEIGHT);
    }

    const setupWorld = () => {
        const objects = [];
        const rodRatio = { x: (Constants.ROD_SIZE) / (512), y: Constants.ROD_SIZE / 512 }

        const topPoints = [Point.scale2d(new Point(64, 50), rodRatio), Point.scale2d(new Point(124, 50), rodRatio), Point.scale2d(new Point(255, 50), rodRatio), Point.scale2d(new Point(390, 50), rodRatio), Point.scale2d(new Point(445, 50), rodRatio)];
        topPoints.forEach(point => point.y = point.y + (Constants.ROD_HEIGHT - Constants.ROD_SIZE));

        const bottomPoints = [Point.scale2d(new Point(64, 410), rodRatio), Point.scale2d(new Point(124, 460), rodRatio), Point.scale2d(new Point(255, 477), rodRatio), Point.scale2d(new Point(390, 460), rodRatio), Point.scale2d(new Point(445, 410), rodRatio)];
        bottomPoints.forEach(point => point.y = point.y + (Constants.ROD_HEIGHT - Constants.ROD_SIZE));

        const rightPoints = [Point.scale2d(new Point(475, 65), rodRatio), Point.scale2d(new Point(475, 150), rodRatio), Point.scale2d(new Point(475, 255), rodRatio), Point.scale2d(new Point(445, 410), rodRatio)];
        rightPoints.forEach(point => point.y = point.y + (Constants.ROD_HEIGHT - Constants.ROD_SIZE));

        const leftPoints = [Point.scale2d(new Point(35, 65), rodRatio), Point.scale2d(new Point(35, 150), rodRatio), Point.scale2d(new Point(35, 255), rodRatio), Point.scale2d(new Point(35, 410), rodRatio)];
        leftPoints.forEach(point => point.y = point.y + (Constants.ROD_HEIGHT - Constants.ROD_SIZE));

        objects.rod = new WorldVectorObject(Constants.MAX_WIDTH / 2, Constants.MAX_HEIGHT / 2 - Constants.ROD_HEIGHT + Constants.ROD_SIZE, Constants.ROD_SIZE, Constants.ROD_HEIGHT, 'rod',
            topPoints,
            rightPoints,
            bottomPoints,
            leftPoints,
            0, true);

        objects.duck = new WorldObject(Constants.MAX_WIDTH / 2, Constants.DUCK_Y, Constants.DUCK_WIDTH, Constants.DUCK_HEIGHT, 'duck', 0, true);
        objects.duck.isMother = false;
        objects.duck.imageSource = require('./assets/ente.png')

        objects.flyduck = new WorldObject(Constants.MAX_WIDTH, getY(), Constants.DUCK_WIDTH, Constants.DUCK_HEIGHT, 'duck', 0, true);
        objects.flyduck.isMother = true;
        objects.flyduck.imageSource = require('./assets/flyMotherDuck.png')

        objects.rod.setCollisionHandler(true, (isActive, obj, sP, pos) => {
            switch (obj.name) {
                case 'duck':
                    if (pos === 0) {
                        return 2;
                    } else {
                        if (pos === 1) {
                            obj.moveRight(20, 10);
                        } else if (pos === 2) {
                            obj.moveDown(20, 10);
                        } else if (pos === 3) {
                            obj.moveLeft(20, 10);
                        }
                        return 0;
                    }
                default: return 0;
            }
        });

        objects.leftWall = new WorldObject(0, 0, Constants.WALL_WIDTH, Constants.MAX_HEIGHT, 'wallLeft', 0, false);
        objects.rightWall = new WorldObject(Constants.MAX_WIDTH - Constants.WALL_WIDTH, 0, Constants.WALL_WIDTH, Constants.MAX_HEIGHT, 'wallRight', 0, false);
        objects.topWall = new WorldObject(0, 0, Constants.MAX_WIDTH, Constants.WALL_WIDTH, 'wallTop', 0, false);
        objects.botWall = new WorldObject(0, Constants.MAX_HEIGHT + Constants.ROD_SIZE * 0.5, Constants.MAX_WIDTH, Constants.WALL_WIDTH, 'wallBot', 0, false);

        const world = new World(objects);
        setWorld(world);
        return {
            physics: { gameEngine: gameEngine },
            world: world,
            background1: { body: { x: 0, y: 0, width: Constants.MAX_WIDTH, height: Constants.MAX_HEIGHT }, imageSource: require('./assets/back.png'), renderer: Player },
            rod: { body: world.objects.rod, imageSource: require('./assets/rod.png'), renderer: Rod },
            duck: { body: world.objects.duck, renderer: Player },
            flyduck: { body: world.objects.flyduck, renderer: Player },
            water: { body: { x: 0, y: Constants.MAX_HEIGHT * 0.875, width: Constants.MAX_WIDTH, height: Constants.MAX_HEIGHT * 0.125 }, color: "#0037FFAA", renderer: Wall },
            leftWall: { body: world.objects.leftWall, color: "#000000", renderer: Wall },
            rightWall: { body: world.objects.rightWall, color: "#000000", renderer: Wall },
            topWall: { body: world.objects.topWall, color: "#000000", renderer: Wall },
            botWall: { body: world.objects.botWall, color: "#000000", renderer: Wall },
        }
    };

    const panResponder = React.useRef(
        PanResponder.create({
            // Ask to be the responder:
            onStartShouldSetPanResponder: () => !showHelpRef.current,
            onStartShouldSetPanResponderCapture: (evt, gestureState) =>
                false,
            onMoveShouldSetPanResponder: (evt, gestureState) => false,
            onMoveShouldSetPanResponderCapture: (evt, gestureState) =>
                false,

            onPanResponderGrant: (evt, gestureState) => {
                // The gesture has started. Show visual feedback so the user knows
                // what is happening!
                // gestureState.d{x,y} will be set to zero now
            },
            onPanResponderMove: (evt, gestureState) => {
                // The most recent move distance is gestureState.move{X,Y}
                // The accumulated gesture distance since becoming responder is
                if (!worldRef.current.objects.rod) { return; }
                if (Math.abs(gestureState.dx) > Math.abs(gestureState.dy)) {
                    if (gestureState.dx < 0) {
                        //moveLeft
                        worldRef.current.objects.rod.moveLeft(Constants.MAX_WIDTH * moveFactor, 10);
                    } else {
                        //moveRight
                        worldRef.current.objects.rod.moveRight(Constants.MAX_WIDTH * moveFactor, 10);
                    }
                } else {
                    if (gestureState.dy < 0) {
                        //moveUp
                        worldRef.current.objects.rod.moveUp(Constants.MAX_WIDTH * moveFactor, 5);
                    } else {
                        //moveDown
                        worldRef.current.objects.rod.moveDown(Constants.MAX_WIDTH * moveFactor, 5);
                    }
                }
            },
            onPanResponderTerminationRequest: (evt, gestureState) =>
                true,
            onPanResponderRelease: (evt, gestureState) => {
                // The user has released all touches while this view is the
                // responder. This typically means a gesture has succeeded
            },
            onPanResponderTerminate: (evt, gestureState) => {
                // Another component has become the responder, so this gesture
                // should be cancelled
            },
            onShouldBlockNativeResponder: (evt, gestureState) => {
                // Returns whether this component should block native components from becoming the JS
                // responder. Returns true by default. Is currently only supported on android.
                return true;
            }
        })
    ).current;

    const Physics = (entities, { dispatch }) => {
        if (entities.duck.body.x + Constants.DUCK_WIDTH / 2 > entities.rod.body.x && entities.duck.body.x + Constants.DUCK_WIDTH / 2 < entities.rod.body.x + Constants.ROD_SIZE
            && entities.duck.body.y + Constants.DUCK_HEIGHT / 2 > (entities.rod.body.y + Constants.ROD_HEIGHT - Constants.ROD_SIZE * 0.8) && entities.duck.body.y + Constants.DUCK_HEIGHT / 2 < (entities.rod.body.y + Constants.ROD_HEIGHT)) {
            if (entities.duck.body.isMother) {
                dispatch({ type: "game-over" });
            } else {
                dispatch({ type: "score" });
                if (Math.random() <= 0.25) {
                    entities.duck.body.isMother = true;
                    entities.duck.body.imageSource = require('./assets/motherDuck.png')
                } else {
                    entities.duck.body.isMother = false;
                    entities.duck.body.imageSource = require('./assets/ente.png')
                }
                entities.duck.body.setPosition(- Constants.MAX_WIDTH / 2, Constants.DUCK_Y);
            }
        } else if (entities.duck.body.x > Constants.MAX_WIDTH) {
            if (Math.random() <= 0.25) {
                entities.duck.body.isMother = true;
                entities.duck.body.imageSource = require('./assets/motherDuck.png')
            } else {
                entities.duck.body.isMother = false;
                entities.duck.body.imageSource = require('./assets/ente.png')
            }
            entities.duck.body.setPosition(- Constants.MAX_WIDTH / 2, Constants.DUCK_Y);
        } else {
            entities.duck.body.moveRight(1 + entities.world.speed);
        }

        if (entities.flyduck.body.x + Constants.DUCK_WIDTH / 2 > entities.rod.body.x && entities.flyduck.body.x + Constants.DUCK_WIDTH / 2 < entities.rod.body.x + Constants.ROD_SIZE
            && entities.flyduck.body.y + Constants.DUCK_HEIGHT / 2 > (entities.rod.body.y + Constants.ROD_HEIGHT - Constants.ROD_SIZE * 0.8) && entities.flyduck.body.y + Constants.DUCK_HEIGHT / 2 < (entities.rod.body.y + Constants.ROD_HEIGHT)) {
            if (entities.flyduck.body.isMother) {
                dispatch({ type: "game-over" });
            } else {
                dispatch({ type: "score" });
                if (Math.random() <= 0.25) {
                    entities.flyduck.body.isMother = true;
                    entities.flyduck.body.imageSource = require('./assets/flyMotherDuck.png')
                } else {
                    entities.flyduck.body.isMother = false;
                    entities.flyduck.body.imageSource = require('./assets/flyDuck.png')
                }
                entities.flyduck.body.setPosition(1.5 * Constants.MAX_WIDTH, getY());
            }
        } else if (entities.flyduck.body.x + Constants.DUCK_WIDTH / 2 < 0) {
            if (Math.random() <= 0.25) {
                entities.flyduck.body.isMother = true;
                entities.flyduck.body.imageSource = require('./assets/flyMotherDuck.png')
            } else {
                entities.flyduck.body.isMother = false;
                entities.flyduck.body.imageSource = require('./assets/flyDuck.png')
            }
            entities.flyduck.body.setPosition(1.5 * Constants.MAX_WIDTH, getY());
        } else {
            entities.flyduck.body.moveLeft(1.5 + entities.world.speed);
            entities.flyduck.body.moveUp(1);
        }

        entities.world.speed += 0.001;
        entities.world.update();
        return entities;
    };

    return (
        <View {...panResponder.panHandlers} style={{ flex: 1, overflow: 'hidden' }}>
            <MinigameEngine
                setupWorld={setupWorld}
                systems={[Physics]}
                onEvent={onEvent}
                setScore={setScore}
                score={score}
                setGameEngine={setGameEngine}
                showHelp={props.showHelp}
                setShowHelp={props.setShowHelp}
                gameId={GameIdConstants.DUCK_FISHING}
            />
        </View>
    );
};

export default DuckFishing;