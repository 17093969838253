import World from './World';
import Point from './Point';

class WorldObject {
    constructor(x, y, width, height, name = 'WorldObject', mass = 1, isStatic = false) {
        this.name = name;
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.mass = mass;
        this.isStatic = isStatic;
        this.speedVertical = 0;
        this.veloVertical = 0;
        this.speedHorizontal = 0;
        this.veloHorizontal = 0;
        this.setCollisionHandler();
    }


    defaultCollisionHandler = (isActive, obj, sP, position) => {
        //return 0: false | 1: true | 2: true, dont check further points of obj
        if (obj.callAlwaysOnCollision && isActive) {
            return obj.collissionHandler(false, this, sP, (position + 2) % 4);
        }
        if ((obj.getIsStatic(sP) && this.isStatic)) {
            return 0;
        } else {
            return 1;
        }
    }

    setIsStatic = (isStatic) => {
        this.isStatic = isStatic;
    }

    setMass = (mass) => {
        this.mass = mass;
    }

    setName = (name) => {
        this.name = name;
    }

    setWidth = (width) => {
        this.width = width;
    }

    setCollisionHandler = (callAlways = false, collissionHandler = this.defaultCollisionHandler) => {
        this.callAlwaysOnCollision = callAlways;
        this.collissionHandler = collissionHandler;
    }

    setPosition(x, y) {
        this.x = x;
        this.y = y;
    }

    getTopPoints = () => {
        return [new Point(this.x, this.y), new Point(this.x + this.width / 2, this.y), new Point(this.x + this.width, this.y)];
    }

    getBottomPoints = () => {
        return [new Point(this.x, this.y + this.height), new Point(this.x + this.width / 2, this.y + this.height), new Point(this.x + this.width, this.y + this.height)];
    }

    getLeftPoints = () => {
        return [new Point(this.x, this.y), new Point(this.x, this.y + this.height / 2), new Point(this.x, this.y + this.height)];
    }

    getRightPoints = () => {
        return [new Point(this.x + this.width, this.y), new Point(this.x + this.width, this.y + this.height / 2), new Point(this.x + this.width, this.y + this.height)];
    }

    getIsStatic = (sP) => {
        return this.isStatic;
    }

    moveUp = (y, t = 1) => {
        this.veloVertical = (2 * y / ((t + 1) * t));
        this.speedVertical = -Math.abs(this.veloVertical) * t;
        this.veloVertical -= (World.gravity * this.mass);
    }

    moveDown = (y, t = 1) => {
        this.veloVertical = -(2 * y / ((t + 1) * t));
        this.speedVertical = Math.abs(this.veloVertical) * t;
        this.veloVertical += (World.gravity * this.mass);
    }

    moveLeft = (x, t = 1) => {
        this.veloHorizontal = (2 * x / ((t + 1) * t));
        this.speedHorizontal = -Math.abs(this.veloHorizontal) * t;
    }

    moveRight = (x, t = 1) => {
        this.veloHorizontal = -(2 * x / ((t + 1) * t));
        this.speedHorizontal = Math.abs(this.veloHorizontal) * t;
    }

    getHeight = () => {
        return this.height;
    }

    getWidth = () => {
        return this.width;
    }

    getY = () => {
        return this.y;
    }

    getX = () => {
        return this.x;
    }
}

export default WorldObject;