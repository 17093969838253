import React from 'react';
import { View, StyleSheet, ToucableOpacity, Dimensions, Image } from 'react-native';
import getIconSource from '../helper/getIconSource';

const PlayerItem = props => {

    return (
        <View style={[styles.container, props.style]}>
            <Image
                source={getIconSource(props.icon)}
                fadeDuration={0}
                style={styles.icon}
                resizeMode='stretch'
            />
            {props.children}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        backgroundColor: 'transparent',
        width: Math.ceil((48 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
        height: Math.ceil((48 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
        marginRight: 5
    }
});

export default PlayerItem;
