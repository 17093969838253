import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';
import { Ionicons, AntDesign } from '@expo/vector-icons';
import PlayerItem from './PlayerItem';

const ShareShotsScreen = props => {
    const [shots, setShots] = useState(0);

    const scaleFontSize = (fontSize) => {
        return Math.ceil((fontSize * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861)));
    }

    return (
        <View style={styles.container}>

            <PlayerItem style={{ width: '50%' }} icon={props.icon}>
                <View >
                    <Text numberOfLines={2} style={{ color: 'white', fontSize: scaleFontSize(26) }}>{props.name}</Text>
                    <Text style={{ color: 'grey', fontSize: scaleFontSize(14) }}>Shots total: {props.shotsTotal}</Text>
                </View>
            </PlayerItem>

            <TouchableOpacity
                onPress={() => {
                    setShots(shots - 1);
                    props.updateShotsGiven(props.name, shots - 1, false);
                }}
                disabled={shots === 0}
            >
                <AntDesign name="minuscircleo" size={32} color={shots === 0 ? 'grey' : "white"} />
            </TouchableOpacity>
            <Text style={{ color: 'white', fontSize: scaleFontSize(32), fontWeight: 'bold' }}>{shots}</Text>

            <TouchableOpacity
                onPress={() => {
                    setShots(shots + 1);
                    props.updateShotsGiven(props.name, shots + 1, true);
                }}
                disabled={props.allShotsGiven}
            >
                <AntDesign name="pluscircleo" size={32} color={props.allShotsGiven ? 'grey' : "white"} />
            </TouchableOpacity>


        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginBottom: 10
    }
});

export default ShareShotsScreen;
