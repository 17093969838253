import React, { Component } from "react";
import { Image } from "react-native";

const Food = props => {
    const x = props.position[0];
    const y = props.position[1];

    return (
        <Image
            source={require('./assets/apple.png')}
            fadeDuration={0}
            resizeMode='stretch'
            style={{
                position: "absolute",
                left: x * props.size,
                top: y * props.size,
                width: props.size,
                height: props.size,
            }}
        />
    );

};

export default Food;
