import React, { useState, useEffect } from 'react'
import { StyleSheet, Text, View, FlatList, TouchableOpacity } from 'react-native'
import PlayerItem from '../../../Components/PlayerItem';
import scaleFontSize from '../../../helper/scaleFunctions'

const GuessPlayerAnswer = props => {
    const [selected, setSelected] = useState([]);
    const [showSelect, setShowSelect] = useState(false);
    const [selectForPlayer, setSelectForPlayer] = useState('');

    useEffect(() => {
        const selected = [];
        props.player.forEach(player => {
            selected[player.name] = -1;
            player.selectedAnswer = '';
        });
        setSelected(selected);
    }, [])

    const selectAnswer = (answerIndex) => {
        const playerIndex = props.player.findIndex(player => player.name === selectForPlayer);
        if (selected[selectForPlayer] !== -1) {
            props.answers[selected[selectForPlayer]].selected = false;
        }
        if (answerIndex !== -1) {
            props.answers[answerIndex].selected = true;
            props.player[playerIndex].selectedAnswer = props.answers[answerIndex].value;
        } else {
            props.player[playerIndex].selectedAnswer = '';
        }
        selected[selectForPlayer] = answerIndex;
        setSelected(Object.assign({}, selected));
    }

    return (
        <View style={{ flex: 1, width: '100%' }}>
            <FlatList
                data={props.player}
                extraData={selected}
                keyExtractor={item => item.name}
                renderItem={itemData => (
                    <PlayerItem style={{ flexDirection: 'row', marginHorizontal: 10, marginLeft: 25, justifyContent: 'center' }} icon={itemData.item.icon}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1, alignItems: 'center' }}>
                            <Text numberOfLines={2} style={{ color: 'white', fontSize: scaleFontSize(26) }}>{itemData.item.name}</Text>
                            <View style={{ width: '50%' }}>
                                <TouchableOpacity onPress={() => { setSelectForPlayer(itemData.item.name); setShowSelect(true) }}>
                                    {(props.answers[selected[itemData.item.name]] && selected[itemData.item.name] !== -1) && <Text style={{ color: 'white', width: '100%', borderColor: 'white', borderWidth: 0.5, padding: 2, paddingHorizontal: 10, textAlign: 'center' }}>{props.answers[selected[itemData.item.name]].value}</Text>}
                                    {selected[itemData.item.name] === -1 && <Text style={{ color: 'white', width: '100%', borderColor: 'white', borderWidth: 0.5, padding: 2, paddingHorizontal: 10, textAlign: 'center' }}>- - - - -</Text>}
                                </TouchableOpacity>
                            </View>
                        </View>
                    </PlayerItem>
                )}
            />
            {showSelect && <View style={styles.container}>
                <TouchableOpacity onPress={() => { selectAnswer(-1); setShowSelect(false) }}>
                    <Text style={styles.pickerItemText}>- - - - -</Text>
                </TouchableOpacity>
                <FlatList
                    data={props.answers}
                    keyExtractor={item => item.playerName}
                    renderItem={itemData => (
                        <TouchableOpacity onPress={() => { selectAnswer(itemData.index); setShowSelect(false) }}>
                            {!itemData.item.selected && <Text style={styles.pickerItemText}>{itemData.item.value}</Text>}
                        </TouchableOpacity>
                    )}
                />
                <TouchableOpacity onPress={() => { setShowSelect(false) }}>
                    <Text style={styles.pickerItemCancel}>Cancel</Text>
                </TouchableOpacity>
            </View>}
        </View>
    )
}

export default GuessPlayerAnswer

const styles = StyleSheet.create({
    picker: {
        backgroundColor: 'grey',
        color: 'white',
        width: '50%'
    },
    container: {
        height: '98%',
        width: '90%',
        backgroundColor: '#808080EE',
        position: 'absolute',
        top: '1%',
        left: '5%',
        borderColor: 'grey',
        borderWidth: 2,
        borderRadius: 5
    },
    pickerItemText: {
        color: 'white',
        width: '100%',
        textAlign: 'center',
        fontSize: scaleFontSize(28),
        borderColor: 'black',
        borderBottomWidth: 1,
        paddingVertical: 10,
    },
    pickerItemCancel: {
        color: '#d10e00',
        width: '100%',
        textAlign: 'center',
        fontSize: scaleFontSize(28),
    }
})
