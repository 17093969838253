import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Picker, TouchableOpacity } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';

import scaleFontSize from '../helper/scaleFunctions';
import GameIdConstants from '../games/GameIdConstants';
import FlappyUnicorn from '../games/miniGames/flappybird/FlappyUnicorn';
import QuizScreen from '../games/brainGames/quizgame/QuizScreen';
import Snake from '../games/miniGamesB/snake/Snake';
import StarReaction from '../games/miniGames/starReaction/StarReaction';
import RememberWords from '../games/brainGames/words/RememberWords';
import ColorWords from '../games/brainGames/colorWords/ColorWords';
import RabbitRun from '../games/miniGames/rabbitRun/RabbitRun';
import KiwiJump from '../games/miniGames/kiwiJump/KiwiJump';
import ShootTheBalloons from '../games/miniGames/balloons/ShootTheBalloons';
import DuckFishing from '../games/miniGames/duckFishing/DuckFishing';
import Pong from '../games/miniGames/pong/Pong';
import CandyCrush from '../games/miniGamesB/candycrush/CandyCrush';
import SpaceBattle from '../games/miniGames/spaceBattle/SpaceBattle';
import KnowFriends from '../games/socialGames/knowFriends/KnowFriends';
import { Entypo } from '@expo/vector-icons';

const MiniGameScreen = props => {
    const [bestTry, setBestTry] = useState(0);
    const [timer, setTimer] = useState(60);
    const [miniGameId, setMiniGameId] = useState(props.taskId);
    const [showHelp, setShowHelp] = useState(true);

    const updateBestTry = (score) => {
        if (score > bestTry) {
            setBestTry(score);
        }
    }

    useEffect(() => {
        if (!props.justPlayModus && miniGameId !== GameIdConstants.KNOW_FRIENDS) {
            if (!showHelp) {
                let secondsLeft = timer;
                const clock = setInterval(() => {
                    secondsLeft--;
                    if (secondsLeft <= 0) {
                        onTaskDone();
                        clearInterval(clock);
                    } else {
                        setTimer(secondsLeft);
                    }
                }, 1000);
                return () => {
                    clearInterval(clock);
                };
            }
        }
    }, [showHelp, bestTry]);

    const onTaskDone = () => {
        props.setPoints(bestTry);
        props.onTaskDone();
    }

    return (
        <View style={styles.screen}>
            {miniGameId === GameIdConstants.QUIZ && <QuizScreen updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
            {miniGameId === GameIdConstants.FLAPPY_UNICORN && <FlappyUnicorn updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
            {miniGameId === GameIdConstants.SNAKE && <Snake updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
            {miniGameId === GameIdConstants.STAR_REACTION && <StarReaction updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
            {miniGameId === GameIdConstants.MEMORY_MASTER && <RememberWords updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
            {miniGameId === GameIdConstants.COLOR_CONFUSION && <ColorWords updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
            {miniGameId === GameIdConstants.RABBIT_RUN && <RabbitRun updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
            {miniGameId === GameIdConstants.KIWI_JUMP && <KiwiJump updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
            {miniGameId === GameIdConstants.TURTLE_MATCH && <CandyCrush updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
            {miniGameId === GameIdConstants['99_RED_BALLOONS'] && <ShootTheBalloons updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
            {miniGameId === GameIdConstants.DUCK_FISHING && <DuckFishing updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
            {miniGameId === GameIdConstants.PONG && <Pong updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
            {miniGameId === GameIdConstants.SPACE_BATTLE && <SpaceBattle updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp} />}
            {miniGameId === GameIdConstants.KNOW_FRIENDS && <KnowFriends playerName={props.username} timer={timer} setTimer={setTimer}
                onTaskDone={onTaskDone} updateBestTry={(score) => updateBestTry(score)} showHelp={showHelp} setShowHelp={setShowHelp}
                firebase={props.firebase} username={props.username} roomKey={props.roomKey} />}

            <View style={{ flexDirection: "row", justifyContent: 'space-between', alignItems: 'center', height: 50, backgroundColor: 'black', paddingHorizontal: 10 }}>
                <View style={{ width: '10%' }}>
                    <TouchableOpacity onPress={() => setShowHelp(true)}>
                        <Entypo name="help" size={scaleFontSize(28)} color="white" />
                    </TouchableOpacity>
                </View>
                <Text style={{ color: 'white', width: '20%', textAlign: 'center' }}>Points: {bestTry}</Text>
                {!props.justPlayModus &&
                    <View style={{ width: '40%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                        {/* <TouchableOpacity onPress={() => {
                            props.onTaskDone();
                            props.setPoints(bestTry);
                        }}> */}
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <MaterialIcons name="timer" size={scaleFontSize(32)} color="white" />
                            <Text style={{ color: 'white', fontWeight: 'bold', marginLeft: 5 }}>{timer}</Text>
                        </View>
                        {/* </TouchableOpacity> */}
                    </View>
                }
                {props.justPlayModus &&
                    <Picker
                        style={styles.miniGamePicker}
                        selectedValue={miniGameId}
                        onValueChange={(itemValue) => setMiniGameId(itemValue)}
                    >
                        <Picker.Item label="QUIZ" value={GameIdConstants.QUIZ} />
                        <Picker.Item label="FLAPPY UNICORN" value={GameIdConstants.FLAPPY_UNICORN} />
                        <Picker.Item label="SNAKE" value={GameIdConstants.SNAKE} />
                        <Picker.Item label="STAR REACTION" value={GameIdConstants.STAR_REACTION} />
                        <Picker.Item label="REMEMBER WORDS" value={GameIdConstants.MEMORY_MASTER} />
                        <Picker.Item label="COLORWORDS" value={GameIdConstants.COLOR_CONFUSION} />
                        <Picker.Item label="RABBIT_RUN" value={GameIdConstants.RABBIT_RUN} />
                        <Picker.Item label="KIWI_JUMP" value={GameIdConstants.KIWI_JUMP} />
                        <Picker.Item label="TURTLE MATCH" value={GameIdConstants.TURTLE_MATCH} />
                        <Picker.Item label="99_RED_BALLOONS" value={GameIdConstants['99_RED_BALLOONS']} />
                        <Picker.Item label="DUCK_FISHING" value={GameIdConstants.DUCK_FISHING} />
                        <Picker.Item label="PONG" value={GameIdConstants.PONG} />
                        <Picker.Item label="SPACE BATTLE" value={GameIdConstants.SPACE_BATTLE} />
                        <Picker.Item label="Do you know your friends?" value={GameIdConstants.KNOW_FRIENDS} />
                    </Picker>
                }
                <View style={{ width: '30%', alignItems: 'flex-end' }}>
                    <TouchableOpacity onPress={() => props.leave()}>
                        <Entypo name="log-out" size={scaleFontSize(28)} color="white" />
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        flexDirection: 'column-reverse'
    },
    miniGamePicker: {
        width: '50%',
        height: '50%',
        backgroundColor: 'grey',
    }
});

export default MiniGameScreen;
