const Constants = {
    HELP_BACKGROUND: '#DDDDDD',
    HOME_BACKGROUND: '#6F00DD',
    HOME_GRADIENT: '#000000AA',
    RANK_GRADIENT: '#00000099',
    RANK_BACKGROUND: '#1566A3',
    RANK_USER_COLOR: '#FFB60A',
    ROOMS_BACKGROUND: '#FFD500',
    ROOMS_GRADIENT: '#00000040',
    ROOMS_JOIN: '#202020',
    ROOMS_ICONPICKER_BACKGROUND: '#202020',
    ARCARDE_BACKGROUND: '#000000',
    ARCARDE_RANK_BACKGROUND: '#202020',
    JOIN_TOP_TEN_MODAL: '#553322'
};

export default Constants;