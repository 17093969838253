const Questions = [
    "Who is your hero?",
    "If you could live anywhere, where would it be?",
    "What is your biggest fear?",
    "What would you change about yourself if you could?",
    "What really makes you angry?",
    "What motivates you to work hard?",
    "What is your proudest accomplishment?",
    "What is your favorite book to read?",
    "What makes you laugh the most?",
    "What was the last movie you went to?",
    "What is your favorite movie?",
    "What did you want to be when you were small?",
    "If you could choose to do anything for a day, what would it be?",
    "What would you sing at Karaoke night?",
    "If you could only eat one meal for the rest of your life, what would it be?",
    "Who is your favorite author?",
    "Have you ever had a nickname? What is it?",
    "Who would you want to be stranded with on a deserted island?",
    "If money was no object, what would you do all day?",
    "If you could go back in time, what year would you travel to?",
    "What is the best gift you have been given?",
    "What is the worst gift you have received?",
    "Aside from necessities, what one thing could you not go a day without?",
    "How many pairs of shoes do you own?",
    "If you were a super-hero, what powers would you have?",
    "What would you do if you won the lottery?",
    "What's your favorite animal?",
    "If you could go back in time to change one thing, what would it be?",
    "If you could share a meal with any 4 individuals, living or dead, who would they be?",
    "How often do you buy clothes?",
    "What was the last book you read?",
    "Who would you want to play you in a movie of your life?",
    "What's your favorite family tradition?",
    "What is your favorite childhood memory?",
    "What three items would you take with you on a deserted island?",
    "What's the most unusual thing you've ever eaten?",
    "Do you collect anything?",
    "If you were ruler of your own country what would be the first law you would introduce?",
    "If you had a warning label, what would yours say?",
    "What song would you say best sums you up?",
    "If you had to describe yourself as an animal, which one would it be?",
    "What is one thing you will never do again?",
    "What was the last TV show you binge-watched?",
    "If you could only eat one food for the rest of your life, what would it be?",
    "What song always gets you out on the dance floor?",
    "What's the phone app you use most?",
    "What's your favorite board game?",
    "Is there any product that you couldn't live without?",
    "What’s the best piece of advice a family member has given you?",
    "What's something your family would be surprised to learn about you?",
    "What are you most proud of in the last year?",
    "What makes you feel at peace?",
    "What do you regret not doing in the last year?",
    "If you had unlimited money to start your own business, what would it be?",
    "If you found out today was your last day on Earth, what would you do?",
    "A genie gives you three wishes—what are they?",
    "If you could relive one moment in your life, which would it be?",
    "What would be the title of your memoir?",
    "What’s the most unusual place you’ve fallen asleep?",
    "Which fictional character do you relate to most?",
    "What's the silliest argument you've ever been in?",
    "What was the first thing you wanted to be when you grew up?",
    "What's your least favorite place in the world?",
    "What is the last gift you gave away?"
];

export default Questions;