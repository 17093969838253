import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, BackHandler, Alert, Platform } from 'react-native';

import GameIdConstants from '../../games/GameIdConstants';
import JoinScreen from '../JoinScreen';
import WaitScreen from '../WaitScreen';
import MiniGameScreen from '../MiniGameScreen';
import RankScreen from '../RankScreen';
import DrinkScreen from '../DrinkScreen';
import { removeLocalObject } from '../../helper/saveLocal';

import Questions from '../../games/socialGames/knowFriends/Questions';

const singlePlayerGames = [
    GameIdConstants.QUIZ,
    GameIdConstants.FLAPPY_UNICORN,
    GameIdConstants.SNAKE,
    GameIdConstants.STAR_REACTION,
    GameIdConstants.MEMORY_MASTER,
    GameIdConstants.COLOR_CONFUSION,
    GameIdConstants.RABBIT_RUN,
    GameIdConstants.KIWI_JUMP,
    GameIdConstants.TURTLE_MATCH,
    GameIdConstants['99_RED_BALLOONS'],
    GameIdConstants.DUCK_FISHING,
    GameIdConstants.PONG,
    GameIdConstants.SPACE_BATTLE,
]

const multiplayerGames = [
    GameIdConstants.KNOW_FRIENDS
]

const steps = {
    IGNORED: -1,
    JOIN: 0,
    SYNC_NEXT_TASKID: 1,
    SYNC_BEFORE_TASK: 2,
    TASK: 3,
    SYNC_BEFORE_RAS: 4,
    RANK_AND_SHOTS: 5,
    SYNC_BEFORE_DRINK: 6,
    DRINK: 7
}



const GameScreen = props => {
    const [step, setStep] = useState(props.route.params.step ? props.route.params.step : 0);
    const [taskId, setTaskId] = useState(0);
    const [points, setPoints] = useState([0, 0]);
    const [shots, setShots] = useState(0);
    const [waitPlayerlist, setWaitPlayerlist] = useState([]);
    const round = useRef(props.route.params.round);
    const numberOfPlayer = useRef(0);
    const firebase = useRef(require('firebase'));
    const lastTask = useRef(-1);

    const roomKey = props.route.params.key;
    const username = props.route.params.username;
    const isPartyMode = props.route.params.isPartyMode;

    useEffect(() => {
        let config = {
            databaseURL: "https://app-drink-at-home.firebaseio.com/",
            projectId: "app-drink-at-home",
        };
        if (!firebase.current.apps.length) {
            firebase.current.initializeApp(config);
        }

        return () => {
            firebase.current.database().ref(`Rooms/${props.roomKey}/Players`).off();
        }
    }, []);

    const updatePoints = (newPoints) => {
        firebase.current.database().ref(`Rooms/${roomKey}/Players/${username}`).update({
            'points': [newPoints, points[1]],
        }).then((data) => {
            setPoints([newPoints, points[1]]);
        }).catch((error) => {
            //error callback
        });
    };

    const updateTotalPoints = (newPoints) => {
        firebase.current.database().ref(`Rooms/${roomKey}/Players/${username}`).update({
            'points': [points[0], newPoints],
        }).then((data) => {
            setPoints([points[0], newPoints]);
        }).catch((error) => {
            //error callback
        });
    }

    const updateShots = (newShots) => {
        firebase.current.database().ref(`Rooms/${roomKey}/Players/${username}`).update({
            'shots': (shots + newShots),
        }).then((data) => {
            setShots(shots + newShots);
        }).catch((error) => {
            //error callback
        });
    }

    const ignorePlayer = (name) => {
        firebase.current.database().ref(`Rooms/${roomKey}/Players/${name}`).update({
            'step': steps.IGNORED,
            'kick': true
        });
    }

    const leaveGame = () => {
        removeLocalObject('activeRoom');
        firebase.current.database().ref(`Rooms/${roomKey}/Players/${username}`).set(null).then(() => removeRoom());
        if (props.navigation.canGoBack()) {
            props.navigation.popToTop();
        }
    }

    const kickPlayer = () => {
        firebase.current.database().ref(`Rooms/${roomKey}/Players`).off();
        firebase.current.database().ref(`Rooms/${roomKey}/Players/${username}`).update({
            'kick': false,
            'step': steps.JOIN
        }).then(() => {
            setStep(steps.JOIN);
            if (Platform.OS === 'web') {
                window.alert("Something went wrong. You will rejoin when the next round begins!");
            } else {
                Alert.alert("Something went wrong!", "You will rejoin when the next round begins.");
            }
        });
    }

    const removeRoom = () => {
        firebase.current.database().ref(`Rooms/${roomKey}/Players`).once('value', function (snapshot) {
            let player = 0;
            snapshot.forEach(function (childSnapshot) {
                if (childSnapshot.key && childSnapshot.child('step').val() !== steps.IGNORED) {
                    player++;
                }
            });
            if (player <= 0) {
                firebase.current.database().ref(`Rooms/${roomKey}`).set(null);
            }
        });
    };

    const backAction = () => {
        if (Platform.OS === 'web') {
            window.confirm("Are you sure you want to leave?") && leaveGame()
        } else {
            Alert.alert("Hold on!", "Are you sure you want to leave?", [
                {
                    text: "Cancel",
                    onPress: () => null,
                    style: "cancel"
                },
                {
                    text: "YES", onPress: () => {
                        leaveGame();
                    }
                }
            ]);
        }
        return true;
    };

    useEffect(() => {
        const backHandler = BackHandler.addEventListener(
            "hardwareBackPress",
            backAction
        );

        return () => {
            backHandler.remove();
        };
    }, []);

    const getNextTask = async () => {
        let taskHistory;
        await firebase.current.database().ref(`Rooms/${roomKey}/taskHistory`).once('value', function (snapshot) {
            taskHistory = snapshot.val() || [];
        });
        await firebase.current.database().ref(`Rooms/${roomKey}/round`).once('value', function (snapshot) {
            round.current = snapshot.val();
        });

        if (taskHistory.length > 0 && (taskHistory[taskHistory.length - 1] !== taskId || step <= 0)) {
            return;
        }

        let gameList = singlePlayerGames;
        if (numberOfPlayer.current >= 3) {
            gameList = gameList.concat(multiplayerGames);
        }

        if (taskHistory.length === gameList.length) {
            taskHistory = [];
        }
        let nextTask = Math.round(Math.random() * (gameList.length - 1));
        while (taskHistory.indexOf(gameList[nextTask]) >= 0) {
            nextTask = (nextTask + 1) % (gameList.length);
        }
        taskHistory.push(gameList[nextTask]);

        if (gameList[nextTask] >= 300) {
            processMulitplayerTask(gameList[nextTask]);
        }

        await firebase.current.database().ref(`Rooms/${roomKey}`).update({
            'taskHistory': taskHistory,
            'round': taskHistory.length
        });
    }

    const processMulitplayerTask = () => {
        // case KNOW_FRIENDS
        let questionId = Math.floor(Math.random() * Questions.length);
        firebase.current.database().ref(`Rooms/${roomKey}/KnowFriends`).update({
            'question': questionId,
        });
    }

    const getTask = async () => {
        firebase.current.database().ref(`Rooms/${roomKey}/taskHistory`).on('value', function (snapshot) {
            const taskHistory = snapshot.val();
            const nextTask = taskHistory[taskHistory.length - 1];
            if (nextTask != lastTask.current || taskHistory.length === 1) {
                setTaskId(nextTask);
                round.current = taskHistory.length;
                firebase.current.database().ref(`Rooms/${roomKey}/taskHistory`).off();
            }
        });
    }

    useEffect(() => {
        firebase.current.database().ref(`Rooms/${roomKey}/Players/${username}`).update({ step });

        const sync = () => {
            firebase.current.database().ref(`Rooms/${roomKey}/Players`).on('value', function (snapshot) {
                const waitPlayerList = [];
                let allDone = true;
                numberOfPlayer.current = snapshot.numChildren();
                snapshot.forEach(function (childSnapshot) {
                    const playerStep = childSnapshot.child('step').val();
                    if ((playerStep < step || playerStep > step + 1) && (playerStep > 0 || (playerStep >= 0 && step === (steps.JOIN + 1)))) {
                        allDone = false;
                        waitPlayerList.push({ name: childSnapshot.key, icon: childSnapshot.val().icon });
                    }
                    if (playerStep <= 0) {
                        numberOfPlayer.current--;
                    }
                    if (childSnapshot.key === username && childSnapshot.child('kick').val()) {
                        kickPlayer();
                        return;
                    }
                });
                if (allDone) {
                    firebase.current.database().ref(`Rooms/${roomKey}/Players`).off();
                    nextStep();
                }
                setWaitPlayerlist(waitPlayerList);
            });
        }
        (async () => {
            switch (step) {
                case steps.SYNC_NEXT_TASKID:
                    getNextTask().then(() => sync());
                    break;
                case steps.SYNC_BEFORE_TASK:
                    await getTask();
                    sync();
                    break;
                case steps.SYNC_BEFORE_RAS: sync();
                    break;
                case steps.SYNC_BEFORE_DRINK: sync();
                    break;
            }
        })();

    }, [step]);

    const nextStep = async () => {
        setStep((step) => {
            let nextStep = step + 1;
            if (!isPartyMode && nextStep === steps.SYNC_BEFORE_DRINK || nextStep > steps.DRINK) {
                nextStep = 1;
            }
            return nextStep;
        });
    }

    switch (step) {
        case steps.JOIN:
            return (
                <View style={{ flex: 1 }}>
                    <JoinScreen
                        round={round.current}
                        leave={backAction}
                        firebase={firebase.current}
                        roomKey={roomKey}
                        join={nextStep}
                        canStart={round.current === 0}
                    />
                </View>);
        case steps.SYNC_NEXT_TASKID:
            return (<View style={{ flex: 1 }}><WaitScreen leave={backAction} ignorePlayer={(name) => ignorePlayer(name)} playerlist={waitPlayerlist} msg={"Wait for other players. Next round will start soon!"} /></View>)
        case steps.TASK:
            if (Platform.OS === 'web' && (document.getElementById('adsWebDesktop') || document.getElementById('adsWebMobile'))) {
                document.getElementById('adsWebDesktop').style.display = "none";
                document.getElementById('adsWebMobile').style.display = "none";
            }
            return (<View style={{ flex: 1 }}>
                <MiniGameScreen leave={backAction} taskId={taskId} setPoints={(points) => updatePoints(points)} onTaskDone={() => nextStep()} firebase={firebase.current} username={username} roomKey={roomKey} />
            </View>)
        case steps.SYNC_BEFORE_RAS:
            return (<View style={{ flex: 1 }}><WaitScreen leave={backAction} ignorePlayer={(name) => ignorePlayer(name)} playerlist={waitPlayerlist} msg={"Wait for your opponents to finish the game"} /></View>)
        case steps.RANK_AND_SHOTS:
            if (Platform.OS === 'web' && (document.getElementById('adsWebDesktop') || document.getElementById('adsWebMobile'))) {
                document.getElementById('adsWebDesktop').style.display = "none";
                document.getElementById('adsWebMobile').style.display = "none";
            }
            return (<View style={{ flex: 1 }}>
                <RankScreen
                    round={round.current}
                    firebase={firebase.current}
                    username={username}
                    roomKey={roomKey}
                    points={points}
                    isPartyMode={props.route.params.isPartyMode}
                    onTaskDone={() => nextStep()}
                    updateTotalPoints={(newPoints) => updateTotalPoints(newPoints)}
                    leave={backAction}
                />
            </View>)
        case steps.SYNC_BEFORE_DRINK:
            return (<View style={{ flex: 1 }}><WaitScreen leave={backAction} ignorePlayer={(name) => ignorePlayer(name)} playerlist={waitPlayerlist} msg={"Wait for your opponents to share their shots"} /></View>)
        case steps.DRINK:
            if (Platform.OS === 'web' && (document.getElementById('adsWebDesktop') || document.getElementById('adsWebMobile'))) {
                document.getElementById('adsWebDesktop').style.display = "none";
                document.getElementById('adsWebMobile').style.display = "none";
            }
            return (<View style={{ flex: 1 }}><DrinkScreen firebase={firebase.current} username={username} roomKey={roomKey} onTaskDone={(shots) => { updateShots(shots); nextStep(); }} /></View>)
        default: return <View style={{ flex: 1, backgroundColor: 'black' }}></View>;
    }
}

export default GameScreen

const styles = StyleSheet.create({})