import React, { useState, useRef, useEffect } from 'react';
import { View, PanResponder } from 'react-native';
import { Accelerometer } from 'expo-sensors';
import { Platform } from "react-native";
import { Audio } from 'expo-av'

import Wall from '../objects/Wall';
import World from '../physics/World';
import WorldObject from '../physics/WorldObject';
import WorldVectorObject from '../physics/WorldVectorObject';
import Point from "../physics/Point";
import Player from "../objects/Player";
import MinigameEngine from "../MinigameEngine";
import GameIdConstants from '../../GameIdConstants';
import Constants from './Constants';

const RabbitRun = props => {
    const [score, setScore] = useState(0);
    const [gameEngine, setGameEngine] = useState(null);
    const [world, _setWorld] = useState(new World([]));
    const worldRef = useRef(world);
    const showHelpRef = useRef(props.showHelp);
    const soundObject = new Audio.Sound();

    const setWorld = objects => {
        worldRef.current = objects
        _setWorld(objects)
    }

    useEffect(() => {
        showHelpRef.current = props.showHelp
    }, [props.showHelp])

    useEffect((() => {
        const loadMusic = async () => {
            try {
                await soundObject.loadAsync(require('../../../assets/music/music_rabbitrun.mp3'));
                await soundObject.setIsLoopingAsync(true);
                await soundObject.playAsync();
                // Your sound is playing!
            } catch (error) {
                // An error occurred!
            }
        }; loadMusic();
        return () => {
            const unloadMusic = async () => {
                await soundObject.unloadAsync();
            }; unloadMusic();
            Accelerometer.removeAllListeners();
        }
    }), []);

    const onEvent = (e) => {
        if (e.type === "game-over") {
            Accelerometer.removeAllListeners();
        } else if (e.type === "score") {
            setScore(score + 1);
            props.updateBestTry(score + 1);
        } else if (e.type === "scoreSnake") {
            setScore(score + 3);
            props.updateBestTry(score + 3);
        } else if (e.type === "moveLeft") {
            world.objects.rabbit.moveLeft(Constants.MAX_WIDTH * 0.05, 15);
        } else if (e.type === "moveRight") {
            world.objects.rabbit.moveRight(Constants.MAX_WIDTH * 0.05, 15);
        } else if (e.type === "jump") {
            if (world.objects.rabbit.jumps > 0) {
                world.objects.rabbit.jumps--;
                world.objects.rabbit.moveUp((Constants.MAX_HEIGHT - Constants.FLOOR_HEIGHT) * 0.25, 12);
            }
        }
    }

    const getYPos = () => {
        return Math.floor(Math.random() * ((Constants.MAX_HEIGHT - Constants.CARROT_SIZE * 2 - Constants.FLOOR_HEIGHT) + 1));
    }

    const setupWorld = () => {
        const objects = [];

        const rabbitRatio = Constants.RABBIT_HEIGHT / 512;
        const snakeRatio = Constants.SNAKE_HEIGHT / 512;

        objects['rabbit'] = new WorldVectorObject(
            Constants.MAX_WIDTH / 4,
            Constants.MAX_HEIGHT / 2 - Constants.FLOOR_HEIGHT - Constants.RABBIT_HEIGHT,
            Constants.RABBIT_WIDTH,
            Constants.RABBIT_HEIGHT,
            'rabbit',
            [Point.scale(new Point(0, 275), rabbitRatio), Point.scale(new Point(157, 196), rabbitRatio), Point.scale(new Point(348, 0), rabbitRatio), Point.scale(new Point(512, 196), rabbitRatio)],
            [Point.scale(new Point(437, 37), rabbitRatio), Point.scale(new Point(512, 196), rabbitRatio), Point.scale(new Point(512, 355), rabbitRatio), Point.scale(new Point(438, 512), rabbitRatio)],
            [Point.scale(new Point(0, 512), rabbitRatio), Point.scale(new Point(438, 512), rabbitRatio), Point.scale(new Point(256, 480), rabbitRatio), Point.scale(new Point(512, 350), rabbitRatio)],
            [Point.scale(new Point(0, 512), rabbitRatio), Point.scale(new Point(0, 275), rabbitRatio), Point.scale(new Point(146, 236), rabbitRatio), Point.scale(new Point(255, 39), rabbitRatio)],
            1,
            true);
        objects['rabbit'].jumps = 4;
        objects['floor'] = new WorldObject(0, Constants.MAX_HEIGHT - Constants.FLOOR_HEIGHT, Constants.MAX_WIDTH * 3, Constants.FLOOR_HEIGHT, 'floor', 0, true);
        objects['carrot'] = new WorldObject(Constants.MAX_WIDTH, getYPos(), Constants.CARROT_SIZE, Constants.CARROT_SIZE * 2, 'carrot', 0);
        objects['snake'] = new WorldVectorObject(
            Constants.MAX_WIDTH * 0.8,
            Constants.MAX_HEIGHT * 0.5,
            Constants.SNAKE_WIDTH,
            Constants.SNAKE_HEIGHT,
            'snake',
            [Point.scale(new Point(96, 36), snakeRatio), Point.scale(new Point(350, 36), snakeRatio)],
            [Point.scale(new Point(350, 36), snakeRatio), Point.scale(new Point(479, 345), snakeRatio), Point.scale(new Point(412, 480), snakeRatio)],
            [Point.scale(new Point(0, 512), snakeRatio), Point.scale(new Point(512, 512), snakeRatio)],
            [Point.scale(new Point(96, 36), snakeRatio), Point.scale(new Point(65, 270), snakeRatio), Point.scale(new Point(0, 440), snakeRatio)],
            1,
            true
        );

        if (Platform.OS === "android") {
            Accelerometer.addListener(motionData => {
                if (motionData.x > 0) {
                    world.objects.rabbit.moveLeft(motionData.x * 30);
                } else {
                    world.objects.rabbit.moveRight(Math.abs(motionData.x * 30));
                }
            });
        } else {
            Accelerometer.addListener(motionData => {
                if (motionData.x > 0) {
                    world.objects.rabbit.moveRight(Math.abs(motionData.x * 30));
                } else {
                    world.objects.rabbit.moveLeft(motionData.x * 30);
                }
            });
        }
        Accelerometer.setUpdateInterval(1);


        objects['rabbit'].setCollisionHandler(true, (isActive, obj, sP, position) => {
            switch (obj.name) {
                case 'snake':
                    if ((objects['rabbit'].getY(sP.x) + (objects['rabbit'].getHeight(sP.x))) < objects['snake'].getY(sP.x)) {
                        objects['snake'].setPosition(Constants.MAX_WIDTH * 1.2, objects['snake'].y);
                        gameEngine.dispatch({ type: "scoreSnake" });
                    } else {
                        gameEngine.dispatch({ type: "game-over" });
                    }
                    return 2;
                case 'carrot':
                    gameEngine.dispatch({ type: "score" });
                    objects['carrot'].setPosition(Constants.MAX_WIDTH * 1.1, getYPos());
                    return 2;
                case 'floor':
                    objects['rabbit'].jumps = 4;
                    return 0;
                default: return 1;
            }
        });
        const world = new World(objects);
        setWorld(world);


        return {
            physics: { gameEngine: gameEngine },
            world: world,
            background1: { body: { x: 0, y: 0, width: Constants.MAX_WIDTH, height: Constants.MAX_HEIGHT }, imageSource: require('./assets/grass1.png'), renderer: Player },
            background2: { body: { x: Constants.MAX_WIDTH - 3, y: 0, width: Constants.MAX_WIDTH, height: Constants.MAX_HEIGHT }, imageSource: require('./assets/grass2.png'), renderer: Player },
            rabbit: { body: world.objects.rabbit, imageSource: require('./assets/rabbit.png'), renderer: Player },
            carrot: { body: world.objects.carrot, imageSource: require('./assets/carrot.png'), renderer: Player },
            snake: { body: world.objects.snake, imageSource: require('./assets/snake.png'), renderer: Player },
            floor: { body: world.objects.floor, color: "#80FF8000", renderer: Wall },
        }
    };

    const panResponder = React.useRef(
        PanResponder.create({
            onStartShouldSetPanResponder: () => !showHelpRef.current,
            onStartShouldSetPanResponderCapture: (evt, gestureState) =>
                false,
            onMoveShouldSetPanResponder: (evt, gestureState) => false,
            onMoveShouldSetPanResponderCapture: (evt, gestureState) =>
                false,
            onPanResponderGrant: (evt, gestureState) => {
                if (worldRef.current.objects.rabbit.jumps > 0) {
                    worldRef.current.objects.rabbit.jumps--;
                    worldRef.current.objects.rabbit.moveUp((Constants.MAX_HEIGHT - Constants.FLOOR_HEIGHT) * 0.25, 12);
                }
            },
            onPanResponderMove: (evt, gestureState) => {
            },
            onPanResponderTerminationRequest: (evt, gestureState) =>
                true,
            onPanResponderRelease: (evt, gestureState) => {
            },
            onPanResponderTerminate: (evt, gestureState) => {
            },
            onShouldBlockNativeResponder: (evt, gestureState) => {
                return true;
            }
        })
    ).current;

    const Physics = (entities) => {
        let rabbit = entities.rabbit.body;
        let carrot = entities.carrot.body;
        let snake = entities.snake.body;

        const getYPos = () => {
            return Math.floor(Math.random() * ((Constants.MAX_HEIGHT - Constants.CARROT_SIZE * 2 - Constants.FLOOR_HEIGHT) + 1));
        }

        if (rabbit.x + rabbit.width / 2 < 0) {
            rabbit.setPosition(Constants.MAX_WIDTH - rabbit.width / 2, rabbit.y);
        }

        if (rabbit.x + rabbit.width / 2 > Constants.MAX_WIDTH) {
            rabbit.setPosition(0 - rabbit.width / 2, rabbit.y);
        }

        if (carrot.x <= -Constants.CARROT_SIZE) {
            carrot.setPosition(Constants.MAX_WIDTH * 1.5, getYPos());
        } else {
            carrot.moveLeft(0.5 + entities.world.speed);
        }

        if (snake.x <= -Constants.SNAKE_WIDTH) {
            snake.setPosition(Constants.MAX_WIDTH * 1.1, snake.y);
        } else {
            if (snake.speedVertical >= 0 && snake.y > Constants.MAX_HEIGHT * 0.66 && Math.random() <= 0.05) {
                snake.moveUp((Constants.MAX_HEIGHT - Constants.FLOOR_HEIGHT) * 0.2, 12);
            }
            snake.moveLeft(0.25 + entities.world.speed);
        }

        if (entities.background1.body.x < -Constants.MAX_WIDTH) {
            entities.background1.body.x = entities.background2.body.x + Constants.MAX_WIDTH - 3;
        } else {
            entities.background1.body.x -= Constants.MAX_WIDTH * 0.005;
        }

        if (entities.background2.body.x < -Constants.MAX_WIDTH) {
            entities.background2.body.x = entities.background1.body.x + Constants.MAX_WIDTH - 3;
        } else {
            entities.background2.body.x -= Constants.MAX_WIDTH * 0.005;
        }

        entities.world.update();
        entities.world.speed += 0.002;
        return entities;
    };

    return (
        <View {...panResponder.panHandlers} style={{ flex: 1, overflow: 'hidden' }}>
            <MinigameEngine
                setupWorld={setupWorld}
                systems={[Physics]}
                onEvent={onEvent}
                setScore={setScore}
                score={score}
                setGameEngine={setGameEngine}
                showHelp={props.showHelp}
                setShowHelp={props.setShowHelp}
                gameId={GameIdConstants.RABBIT_RUN}
            />
        </View>
    );
};

export default RabbitRun;
