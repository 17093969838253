import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, FlatList, TouchableOpacity, Dimensions } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

import GivePlayerShotsItem from './GivePlayerShotsItem';

const ShareShotsScreen = props => {
    const [shotsGiven, setShotsGiven] = useState({});
    const [shots, setShots] = useState(0);

    const updateShotsGiven = (name, givenShots, increase) => {
        if (givenShots > 0) {
            shotsGiven[name] = givenShots;
        } else {
            shotsGiven[name] = null;
        }
        increase ? setShots(shots - 1) : setShots(shots + 1);
    };

    const scaleFontSize = (fontSize) => {
        return Math.ceil((fontSize * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861)));
    }

    const calculateShots = (rank, shots) => {
        let shotsToGive = Math.ceil(shots / 2);
        if (rank > 0 && shots > 0) {
            return calculateShots(rank - 1, shots - shotsToGive);
        } else {
            if (shots <= 0) {
                shots = 0;
                props.continue();
            }
            setShots(shotsToGive);
        }
    }

    const shareShots = () => {
        for (var prop in shotsGiven) {
            props.firebase.database().ref(`Rooms/${props.roomKey}/Players/${prop}/shotsToDrink`).update({
                [props.username]: shotsGiven[prop],
            }).then((data) => {
                //success callback
                nextStep();
            }).catch((error) => {
                //error callback
            });
        }
    };

    useEffect(() => {
        if (props.playerlist.length === 2 && props.playerlist.findIndex((player) => player.name === props.username) === 1) {
            props.continue();
        } else {
            calculateShots(props.playerlist.findIndex((player) => player.name === props.username), props.playerlist.length);
        }
    }, [])

    return (
        <View style={styles.screen}>

            <View style={{ width: '100%', alignItems: 'center', justifyContent: 'space-around', marginTop: 10, marginBottom: 25, flexDirection: 'row' }}>
                <Ionicons name="md-beer" size={scaleFontSize(72)} color="white" />
                <Ionicons name="md-share-alt" size={scaleFontSize(72)} color="white" />
                <Ionicons name="md-people" size={scaleFontSize(72)} color="white" />
            </View>


            <View style={styles.playerlist}>
                <FlatList
                    data={props.playerlist.filter((player) => (props.playerlist.length <= 1 || player.name != props.username)).sort((a, b) => a.shots < b.shots ? 1 : a.shots === b.shots ? a.name < b.name ? 1 : -1 : -1)}
                    keyExtractor={item => item.name}
                    renderItem={itemData => (
                        <GivePlayerShotsItem name={itemData.item.name} icon={itemData.item.icon} shotsTotal={itemData.item.shots} updateShotsGiven={(name, shots, increase) => updateShotsGiven(name, shots, increase)} allShotsGiven={shots === 0} />
                    )}
                />
            </View>

            <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                <Text style={{ color: 'white', marginBottom: 10, fontSize: scaleFontSize(22) }}>Shots left to give: {shots}</Text>

                <TouchableOpacity
                    disabled={shots > 0}
                    onPress={() => {
                        if (shots === 0) {
                            shareShots();
                            props.continue();
                        }
                    }}>
                    <View style={{ alignItems: 'center' }}>
                        <Ionicons name="ios-arrow-round-forward" size={32} color={shots > 0 ? 'grey' : "white"} />
                        <Text style={{ color: shots > 0 ? 'grey' : "white", fontWeight: 'bold' }}>CONTINUE</Text>
                    </View>
                </TouchableOpacity>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    playerlist: {
        margin: 15,
        flex: 1,
        width: '100%',
    }
});

export default ShareShotsScreen;
