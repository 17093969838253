import { Dimensions } from 'react-native';

const Constants = {
    MAX_WIDTH: Dimensions.get("window").width,
    MAX_HEIGHT: Dimensions.get("window").height - 50,
    RABBIT_HEIGHT: Math.ceil((75 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    RABBIT_WIDTH: Math.ceil((75 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    FLOOR_HEIGHT: (Dimensions.get("window").height - 50) * 0.1,
    CARROT_SIZE: Math.ceil((30 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    SNAKE_HEIGHT: Math.ceil((75 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    SNAKE_WIDTH: Math.ceil((75 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
};

export default Constants;