import React, { useState, useEffect } from "react";
import { Dimensions, View } from 'react-native';
import { Audio } from 'expo-av'

import Balloon from './Balloon';
import Bomb from './Bomb';
import World from '../physics/World';
import WorldObject from '../physics/WorldObject';
import MinigameEngine from "../MinigameEngine";
import GameIdConstants from '../../GameIdConstants';
import Player from '../objects/Player';

const Constants = {
    MAX_WIDTH: Dimensions.get("window").width,
    MAX_HEIGHT: Dimensions.get("window").height - 50,
    BALLOON_HEIGHT: Math.ceil((100 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    BALLOON_WIDTH: Math.ceil((48 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    BOMB_HEIGHT: Math.ceil((75 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
    BOMB_WIDTH: Math.ceil((63 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861))),
};

const colors = [["#C62828", "#F44336", "#E57373"], ["#009900", "#00e600", "#33ff33"], ["#b3b300", "#ffff00", "#ffff80"], ["#8f246b", "#cc3399", "#db70b8"]];

const ShootTheBalloons = props => {
    const [score, setScore] = useState(0);
    const [gameEngine, setGameEngine] = useState(null);
    const soundObject = new Audio.Sound();

    useEffect((() => {
        const loadMusic = async () => {
            try {
                await soundObject.loadAsync(require('../../../assets/music/music_balloons.mp3'));
                await soundObject.setIsLoopingAsync(true);
                await soundObject.playAsync();
                // Your sound is playing!
            } catch (error) {
                // An error occurred!
            }
        }; loadMusic();
        return () => {
            const unloadMusic = async () => {
                await soundObject.unloadAsync();
            }; unloadMusic();
        }
    }), []);

    const onEvent = (e) => {
        if (e.type === "score") {
            setScore(score + 1);
            props.updateBestTry(score + 1);
        }
    }

    const getYPos = () => {
        const min = 0.5 * Constants.MAX_HEIGHT;
        return Math.floor(Math.random() * ((Constants.MAX_HEIGHT - Constants.BALLOON_HEIGHT) - min + 1) + min);
    }

    const clickBalloon = () => {
        gameEngine.dispatch({ type: "score" });
    }

    const clickBomb = () => {
        gameEngine.dispatch({ type: "game-over" });
    }

    const getRandomColor = () => {
        return Math.floor(Math.random() * 4);
    }

    const setupWorld = () => {
        const objects = [];

        objects['balloon1'] = new WorldObject(Constants.MAX_WIDTH / 2, Constants.MAX_HEIGHT * 0.5, Constants.BALLOON_WIDTH, Constants.BALLOON_HEIGHT, 'balloon1', 0, false);
        objects['balloon1'].color = getRandomColor();
        objects['balloon1'].status = 0;
        objects['balloon1'].speed = 0.05;

        objects['balloon2'] = new WorldObject(0 / 2, Constants.MAX_HEIGHT * 0.5, Constants.BALLOON_WIDTH, Constants.BALLOON_HEIGHT, 'balloon2', 0, false);
        objects['balloon2'].color = getRandomColor();
        objects['balloon2'].status = 0;
        objects['balloon2'].speed = 0.06;

        objects['balloon3'] = new WorldObject(0, Constants.MAX_HEIGHT * 0.5, Constants.BALLOON_WIDTH, Constants.BALLOON_HEIGHT, 'balloon3', 0, false);
        objects['balloon3'].color = getRandomColor();
        objects['balloon3'].status = 0;
        objects['balloon3'].speed = 0.08;

        objects['balloon4'] = new WorldObject(0, Constants.MAX_HEIGHT * 0.5, Constants.BOMB_WIDTH, Constants.BOMB_HEIGHT, 'bomb', 0, false);
        objects['balloon4'].color = getRandomColor();
        objects['balloon4'].status = 0;
        objects['balloon4'].speed = 0.1;

        objects['balloon5'] = new WorldObject(Constants.MAX_WIDTH, Constants.MAX_HEIGHT * 0.5, Constants.BALLOON_WIDTH, Constants.BALLOON_HEIGHT, 'balloon4', 0, false);
        objects['balloon5'].color = getRandomColor();
        objects['balloon5'].status = 0;
        objects['balloon5'].speed = 0.04;

        objects['balloon6'] = new WorldObject(Constants.MAX_WIDTH, Constants.MAX_HEIGHT * 0.5, Constants.BALLOON_WIDTH, Constants.BALLOON_HEIGHT, 'balloon5', 0, false);
        objects['balloon6'].color = getRandomColor();
        objects['balloon6'].status = 0;
        objects['balloon6'].speed = 0.06;

        objects['balloon7'] = new WorldObject(Constants.MAX_WIDTH, Constants.MAX_HEIGHT * 0.5, Constants.BALLOON_WIDTH, Constants.BALLOON_HEIGHT, 'balloon6', 0, false);
        objects['balloon7'].color = getRandomColor();
        objects['balloon7'].status = 0;
        objects['balloon7'].speed = 0.09;

        objects['balloon8'] = new WorldObject(Constants.MAX_WIDTH, Constants.MAX_HEIGHT * 0.5, Constants.BOMB_WIDTH, Constants.BOMB_HEIGHT, 'bomb', 0, false);
        objects['balloon8'].color = getRandomColor();
        objects['balloon8'].status = 0;
        objects['balloon8'].speed = 0.05;

        objects.background1 = new WorldObject(0, 0, Constants.MAX_WIDTH, Constants.MAX_HEIGHT, 'background', 0, false);
        objects.background2 = new WorldObject(0, Constants.MAX_HEIGHT * 0.6, Constants.MAX_WIDTH, Constants.MAX_HEIGHT * 0.4, 'background', 0, false);


        const world = new World(objects);
        world.deltaTotal = 0;
        return {
            physics: { gameEngine: gameEngine },
            world: world,
            background1: { body: world.objects.background1, imageSource: require('./assets/background.png'), renderer: Player },
            balloon1: { body: world.objects.balloon1, id: 1, onClick: clickBalloon, renderer: Balloon },
            balloon2: { body: world.objects.balloon2, id: 2, onClick: clickBalloon, renderer: Balloon },
            balloon3: { body: world.objects.balloon3, id: 3, onClick: clickBalloon, renderer: Balloon },
            balloon4: { body: world.objects.balloon4, id: 4, onClick: clickBomb, renderer: Bomb },
            balloon5: { body: world.objects.balloon5, id: 5, onClick: clickBalloon, renderer: Balloon },
            balloon6: { body: world.objects.balloon6, id: 6, onClick: clickBalloon, renderer: Balloon },
            balloon7: { body: world.objects.balloon7, id: 7, onClick: clickBalloon, renderer: Balloon },
            balloon8: { body: world.objects.balloon8, id: 8, onClick: clickBomb, renderer: Bomb },
            background2: { body: world.objects.background2, imageSource: require('./assets/fence.png'), renderer: Player },
        }
    };

    const Physics = (entities, { time }) => {
        entities.world.deltaTotal += time.delta;
        if (entities.world.deltaTotal >= 75) {
            entities.world.deltaTotal = 0;
            for (let i = 1; i <= 4; i++) {
                if (entities["balloon" + i].body.y + Constants.BALLOON_HEIGHT < 0 || entities["balloon" + i].body.status === 1) {
                    entities["balloon" + i].body.setPosition(-Constants.BALLOON_WIDTH, Constants.MAX_HEIGHT);
                    entities["balloon" + i].body.color = getRandomColor();
                    entities["balloon" + i].score = false;
                    entities["balloon" + i].body.status = 0;
                    entities["balloon" + i].body.speed += 0.002;
                } else {
                    entities["balloon" + i].body.moveRight(Constants.MAX_WIDTH * entities["balloon" + i].body.speed, 10); //.x += entities["balloon" + i].body.xSpeed + entities.world.speed;
                    entities["balloon" + i].body.moveUp(Constants.MAX_HEIGHT * entities["balloon" + i].body.speed, 10);    //.y -= entities["balloon" + i].body.ySpeed + entities.world.speed;
                }
            }
            for (let i = 5; i <= 8; i++) {
                if (entities["balloon" + i].body.y + Constants.BALLOON_HEIGHT < 0 || entities["balloon" + i].body.status === 1) {
                    entities["balloon" + i].body.setPosition(Constants.MAX_WIDTH, Constants.MAX_HEIGHT);
                    entities["balloon" + i].body.color = getRandomColor();
                    entities["balloon" + i].score = false;
                    entities["balloon" + i].body.status = 0;
                    entities["balloon" + i].body.speed += 0.002;
                } else {
                    entities["balloon" + i].body.moveLeft(Constants.MAX_WIDTH * entities["balloon" + i].body.speed, 10); //.x += entities["balloon" + i].body.xSpeed + entities.world.speed;
                    entities["balloon" + i].body.moveUp(Constants.MAX_HEIGHT * entities["balloon" + i].body.speed, 10);    //.y -= entities["balloon" + i].body.ySpeed + entities.world.speed;
                }
            }
            entities.world.update();
        }
        return entities;
    };

    return (
        <View style={{ flex: 1, overflow: 'hidden' }}>
            <MinigameEngine
                setupWorld={setupWorld}
                systems={[Physics]}
                onEvent={onEvent}
                setScore={setScore}
                score={score}
                setGameEngine={setGameEngine}
                backgroundColor={'#2040F0'}
                showHelp={props.showHelp}
                setShowHelp={props.setShowHelp}
                gameId={GameIdConstants['99_RED_BALLOONS']}
            />
        </View>);
};

export default ShootTheBalloons;
