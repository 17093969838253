import React from 'react'
import { StyleSheet, Text, View, FlatList, Dimensions } from 'react-native';
import Link from './Link';

const FlatIconCredits = props => {
    return (
        <Text style={styles.credits} >
            Icons made by <Link link={props.authorLink} title={props.author} /> from <Link link='https://www.flaticon.com/de/' title="www.flaticon.com" />
        </Text>
    )
}

export default FlatIconCredits

const styles = StyleSheet.create({
    credits: {
        width: '100%',
        textAlign: 'center',
        color: '#CDDCCD',
        fontSize: Math.ceil((32 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861)))
    }
})
