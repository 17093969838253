import { Platform } from "react-native";
import * as SecureStore from "expo-secure-store";

export const writeSecureStore = async (key, value) => {
    await SecureStore.setItemAsync(key, value);
};

export const readSecureStore = async (key) => {
    const value = await SecureStore.getItemAsync(key);
    return value;
};

export const removeSecureStoreItem = async (key) => {
    await SecureStore.deleteItemAsync(key);
}

export const writeLocalStorage = async (key, value) => {
    localStorage.setItem(key, value);
};

export const readLocalStorage = async (key) => {
    return localStorage.getItem(key);
};

export const removeLocalStorageItem = async (key) => {
    localStorage.removeItem(key);
}

export const writeLocalObject = async (key, obj) => {
    if (Platform.OS === "web") {
        writeLocalStorage(key, JSON.stringify(obj));
    } else {
        writeSecureStore(key, JSON.stringify(obj));
    }
};

export const readLocalObject = async (key) => {
    let value;
    if (Platform.OS === "web") {
        value = await readLocalStorage(key);
    } else {
        value = await readSecureStore(key);
    }
    return JSON.parse(value);
};

export const removeLocalObject = async (key) => {
    if (Platform.OS === "web") {
        removeLocalStorageItem(key);
    } else {
        removeSecureStoreItem(key);
    }
}

