import React from 'react';
import { StyleSheet, Text, View, FlatList, Dimensions, TouchableOpacity, Platform } from 'react-native';
import { AntDesign, Entypo } from '@expo/vector-icons';

import FlatIconCredits from '../Components/FlatIconCredits';
import Link from '../Components/Link';
import scaleFontSize from '../helper/scaleFunctions';


const CreditsScreen = props => {
    const credits = [
        <View style={styles.creditsEntry}>
            <Text style={styles.credits}>Developed by Nico Neubig</Text>
            <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'center', marginVertical: 5 }}>
                <AntDesign name="mail" style={{marginRight: 10}} size={scaleFontSize(42)} color="#CDDCCD" />
                <Link style={[styles.credits, {fontSize: scaleFontSize(22)}]} link="mailto:unicornapplications@gmail.com" title='unicornapplications@gmail.com' />
            </View>
            <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'center', marginVertical: 5 }}>
                <AntDesign name="instagram" style={{marginRight: 10}} size={scaleFontSize(42)} color="#CDDCCD" />
                <Link style={styles.credits} link="https://www.instagram.com/unicornsapplications" title='@unicornsapplications' />
            </View>
            <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'center', marginVertical: 5 }}>
                <Entypo name="bug" size={scaleFontSize(48)} color="#88FF66" style={{marginRight: 10}}/>
                <Link style={styles.credits} link="https://unicornsapplications.atlassian.net/servicedesk/customer/portal/2/group/2/create/10020" title='Report a feature' />
            </View>
        </View>,
        <View style={styles.creditsEntry}>
            <Text style={styles.credits}>Design consultant {'\n'} & Graphic designer {'\n'} Samuel "Designer" Seelig</Text>
            <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'center', marginVertical: 5 }}>
                <AntDesign name="instagram" style={{marginRight: 10}} size={scaleFontSize(42)} color="#CDDCCD" />
                <Link style={styles.credits} link="https://www.instagram.com/samuel.seelig" title='@samuel.seelig' />
            </View>
        </View>,
        <FlatIconCredits authorLink='https://www.flaticon.com/de/autoren/freepik' author='Freepik' />,
        <Text style={{
            width: '100%',
            textAlign: 'center',
            color: '#CDDCCD',
            fontSize: Math.ceil((32 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861)))
        }}>This game uses questions sourced from <Link link='https://opentdb.com/' title='OpenTriviaDB' /> by <Link link="https://www.pixeltailgames.com/" title='PIXELTAIL GAMES LLC' /></Text>,
        <Text style={{
            width: '100%',
            textAlign: 'center',
            color: '#CDDCCD',
            fontSize: Math.ceil((32 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861)))
        }}>Music from <Link link='https://www.musicfox.com/info/kostenlose-gemafreie-musik.php' title='musicfox' /></Text>
    ];

    return (
        <View style={styles.container}>
            {Platform.OS === 'web' && <View style={{ paddingTop: 10, paddingLeft: 15, justifyContent: 'center', width: '100%' }}>
                <TouchableOpacity onPress={() => props.navigation.goBack()}>
                    <AntDesign name="back" size={scaleFontSize(28)} color="white" />
                </TouchableOpacity>
            </View>}
            <Text>CREDITS</Text>
            <FlatList
                style={{ width: '100%' }}
                data={credits}
                keyExtractor={item => item.index}
                renderItem={itemData => (
                    <View key={itemData.key}
                        style={{
                            flexDirection: 'row', alignItems: 'center', borderColor: '#404040',
                            borderBottomWidth: 0.5, marginBottom: 5, padding: 5
                        }}>
                        {itemData.item}
                    </View>
                )}
            />
        </View>
    )
}

export default CreditsScreen

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'black',
        flex: 1,
        alignItems: 'center',
    },
    credits: {
        textAlign: 'center',
        color: '#CDDCCD',
        fontSize: Math.ceil((32 * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861)))
    },
    creditsEntry: {
        width: '100%'
    }
})

