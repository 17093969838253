import React from 'react'
import { StyleSheet, Text } from 'react-native'
import * as WebBrowser from 'expo-web-browser';

const Link = props => {

  const openLink = () => {
    WebBrowser.openBrowserAsync(props.link);
  };

  return (
    <Text onPress={openLink} style={[props.style, { color: '#17b4e8' }]}>{props.title}</Text>
  )
}

export default Link

const styles = StyleSheet.create({})
