import React, { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';

import ShareShots from '../Components/ShareShots';
import Player from '../model/player';
import Rank from '../Components/Rank';
import scaleFontSize from '../helper/scaleFunctions';
import ColorConstants from './ColorConstants';


const RankScreen = props => {
    const [playerlist, setPlayerlist] = useState([]);
    const [isRank, setIsRank] = useState(true);

    useEffect(() => {
        let updatedPlayerlist = [];
        props.firebase.database().ref(`Rooms/${props.roomKey}/Players`).once('value', function (snapshot) {
            snapshot.forEach(function (childSnapshot) {
                if (childSnapshot.child('step').val() > 0) {
                    updatedPlayerlist.push(new Player(childSnapshot.key, childSnapshot.child('icon').val(), childSnapshot.child('points').val(), childSnapshot.child('shots').val()));
                }
            });
        }).then(() => {
            updatedPlayerlist = updatedPlayerlist.filter((player) => player.points)
            updatedPlayerlist.sort((a, b) => a.points[0] < b.points[0] ? 1 : a.points[0] > b.points[0] ? -1 : a.points[1] < b.points[1] ? 1 : a.name < b.name ? 1 : -1);
            for (let i = 0; i < updatedPlayerlist.length; i++) {
                let earnedPoints = 0;
                for (let j = i + 1; j < updatedPlayerlist.length; j++) {
                    if (updatedPlayerlist[i].points[0] > updatedPlayerlist[j].points[0]) {
                        earnedPoints++;
                    }
                }
                updatedPlayerlist[i].earnedPoints = earnedPoints;
                updatedPlayerlist[i].points[1] += earnedPoints;
            }
            setPlayerlist(updatedPlayerlist);
        });
    }, []);

    return (
        <View style={styles.container}>
            <LinearGradient
                // Background Linear Gradient
                colors={['transparent', ColorConstants.RANK_GRADIENT]}
                style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    height: Dimensions.get('window').height,
                }}
            />
            <View style={{ width: '100%', alignItems: 'flex-end', justifyContent: 'center' }}>
                <TouchableOpacity onPress={() => props.leave()}>
                    <Entypo name="log-out" size={scaleFontSize(28)} color="white" />
                </TouchableOpacity>
            </View>
            <View style={{ flex: 1 }}>
                {isRank &&
                    <Rank
                        isPartyMode={props.isPartyMode}
                        playerlist={playerlist}
                        username={props.username}
                        round={props.round}
                        continue={() => {
                            props.updateTotalPoints(playerlist.find((player) => player.name === props.username).points[1]);
                            if (props.isPartyMode) {
                                setIsRank(false);
                            } else {
                                props.onTaskDone();
                            }
                        }}
                    />
                }
                {!isRank &&
                    <ShareShots
                        firebase={props.firebase}
                        username={props.username}
                        roomKey={props.roomKey}
                        continue={() => props.onTaskDone()}
                        playerlist={playerlist.sort((a, b) => a.points[0] < b.points[0] ? 1 : a.points[0] > b.points[0] ? -1 : a.points[1] < b.points[1] ? 1 : a.name < b.name ? 1 : -1)}
                    />
                }
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: ColorConstants.RANK_BACKGROUND,
        padding: 15
    },
    header: {
        width: '100%',
        flexDirection: 'row',
    },
    tab: {
        width: '50%',
        justifyContent: 'center',
        backgroundColor: 'black'
    }
});

export default RankScreen;