import React, { useRef, useState, useEffect } from 'react';
import { View, Text, StyleSheet, FlatList, Image, Dimensions, TouchableOpacity } from 'react-native';
import { AdMobBanner } from 'expo-ads-admob';
import PlayerItem from '../Components/PlayerItem'
import { Platform } from 'react-native';
import { Entypo } from '@expo/vector-icons';

import getPlatform from '../helper/getPlatform';


const scaleFontSize = (fontSize) => {
    return Math.ceil((fontSize * Math.min(Dimensions.get('window').width / 411, Dimensions.get('window').height / 861)));
}

const getWaitImage = () => {
    let index = Math.floor(Math.random() * 6);
    switch (index) {
        case 0:
            return require('../assets/waitpics/wait_1.png');
        case 1:
            return require('../assets/waitpics/wait_2.png');
        case 2:
            return require('../assets/waitpics/wait_3.png');
        case 3:
            return require('../assets/waitpics/wait_4.png');
        case 4:
            return require('../assets/waitpics/wait_5.png');
        case 5:
            return require('../assets/waitpics/wait_6.png');
        default:
            return require('../assets/waitpics/wait_1.png');
    }
}

const imageSize = Dimensions.get('window').width < Dimensions.get('window').height ? Dimensions.get('window').width : Dimensions.get('window').height




const WaitScreen = props => {
    const waitImageSource = useRef(getWaitImage());
    const [canKick, setCanKick] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setCanKick(true);
        }, 30000);
    }, [])

    if (getPlatform() === 'web' && document.getElementById('adsWebDesktop')) {
        document.getElementById('adsWebDesktop').src = "https://uprimp.com/bnr_xload.php?section=General&pub=755569&format=160x600&ga=g&bg=2&xt=160056043235494&xtt=" + Math.round(Math.random() * 10000000);
        document.getElementById('adsWebDesktop').style.display = "inline";
    }
    if (getPlatform() === 'web_mobile' && document.getElementById('adsWebMobile')) {
        document.getElementById('adsWebMobile').src = "https://uprimp.com/bnr_xload.php?section=General&pub=755569&format=300x50&ga=g&bg=2&xt=160056043235494&xtt=" + Math.round(Math.random() * 10000000);
        document.getElementById('adsWebMobile').style.display = "inline";
    }

    return (
        <View style={styles.container}>
            <View style={{ width: '100%', alignItems: 'flex-end', justifyContent: 'center' }}>
                <TouchableOpacity onPress={() => props.leave()}>
                    <Entypo name="log-out" size={scaleFontSize(28)} color="white" />
                </TouchableOpacity>
            </View>
            <Text style={{ color: 'white', fontSize: scaleFontSize(18), fontWeight: 'bold', textAlign: 'center', marginTop: 25 }}>{props.msg}</Text>
            <View style={{ marginTop: 25, marginLeft: 25 }}>
                <FlatList
                    data={props.playerlist}
                    keyExtractor={item => item.name}
                    renderItem={itemData => (
                        <PlayerItem style={{ flexDirection: 'row', alignItems: 'center', marginHorizontal: 10, marginBottom: 10 }} icon={itemData.item.icon}>
                            <View >
                                <Text numberOfLines={2} style={{ color: 'white', fontSize: scaleFontSize(26) }}>{itemData.item.name}</Text>
                                {canKick && <TouchableOpacity
                                    onPress={(name) => props.ignorePlayer(itemData.item.name)}
                                >
                                    <Text style={{ color: 'red', fontSize: 12 }}>Click here to continue without {itemData.item.name}</Text>
                                </TouchableOpacity>}
                            </View>
                        </PlayerItem>
                    )}
                />
            </View>
            <View style={styles.icon}>
                <Image
                    source={waitImageSource.current}
                    fadeDuration={0}
                    resizeMode='contain'
                    style={{ width: imageSize, height: imageSize }}
                />
            </View>
            <View>
                {(Platform.OS === 'android' || Platform.OS === 'ios') && <AdMobBanner
                    bannerSize="fullBanner"
                    adUnitID={Platform.OS === 'android' ? "ca-app-pub-5489489250931709/3942699612" : "ca-app-pub-5489489250931709/2897017731"}
                    //adUnitID="ca-app-pub-3940256099942544/6300978111" //test ad
                    servePersonalizedAds // true or false
                    onDidFailToReceiveAdWithError={(error) => console.log('AdMob Error: ' + error)} />}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'black',
        flex: 1,
    },
    icon: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default WaitScreen;