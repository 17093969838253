const Constants = {
    QUIZ: 0,
    FLAPPY_UNICORN: 1,
    SNAKE: 2,
    STAR_REACTION: 3,
    MEMORY_MASTER: 4,
    COLOR_CONFUSION: 5,
    RABBIT_RUN: 6,
    KIWI_JUMP: 7,
    TURTLE_MATCH: 8,
    '99_RED_BALLOONS': 9,
    DUCK_FISHING: 10,
    PONG: 11,
    SPACE_BATTLE: 12,
    KNOW_FRIENDS: 301,
};

export default Constants;