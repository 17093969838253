import React, { useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Dimensions, Platform, Image } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Entypo } from '@expo/vector-icons';

import ColorConstants from './ColorConstants';
import Link from '../Components/Link';
import scaleFontSize from '../helper/scaleFunctions';

const HomeScreen = props => {

    const moonSize = Dimensions.get('window').width < Dimensions.get('window').height ? Dimensions.get('window').width * 0.25 : Dimensions.get('window').height * 0.25;
    const trashSize = Dimensions.get('window').height * 0.5;
    const isLandscape = Dimensions.get('window').width > Dimensions.get('window').height;

    useEffect(() => {
        if (Platform.OS === 'web' && document.getElementById('adsWebDesktop')) {
            document.getElementById('adsWebDesktop').style.display = "none";
            document.getElementById('adsWebMobile').style.display = "none";
        }
    }, [])

    return (
        <View style={styles.container}>
            <LinearGradient
                // Background Linear Gradient
                colors={['transparent', ColorConstants.HOME_GRADIENT]}
                style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    height: Dimensions.get('window').height,
                }}
            />
            <Image
                source={require('../assets/home/moon.png')}
                fadeDuration={0}
                resizeMode='stretch'
                style={{
                    width: moonSize,
                    height: moonSize,
                    position: 'absolute',
                    top: 0,
                    right: 0,
                }} />

            <View style={{ height: '20%', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <View style={styles.headline}>
                    {Platform.OS !== 'web' && <Text numberOfLines={1} adjustsFontSizeToFit style={{ color: '#C3F4F1', fontSize: 56, textAlign: 'center', textAlignVertical: 'center', fontFamily: 'PressStart2P_400Regular', paddingTop: 10, }}>TRASH GAME NIGHT</Text>}
                    {(Platform.OS === 'web' && !isLandscape) && <Text numberOfLines={1} adjustsFontSizeToFit style={{ color: '#C3F4F1', fontSize: scaleFontSize(20), textAlign: 'center', textAlignVertical: 'center', fontFamily: 'PressStart2P_400Regular', paddingTop: 10, }}>TRASH GAME NIGHT</Text>}
                    {(Platform.OS === 'web' && isLandscape) && <Text numberOfLines={1} adjustsFontSizeToFit style={{ color: '#C3F4F1', fontSize: scaleFontSize(56), textAlign: 'center', textAlignVertical: 'center', fontFamily: 'PressStart2P_400Regular', paddingTop: 10, }}>TRASH GAME NIGHT</Text>}
                </View>
                {Platform.OS === 'web' && <View >
                    <TouchableOpacity onPress={() => props.navigation.navigate('Mobile')} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10, backgroundColor: '#80808040' }}>
                        <Entypo name="mobile" size={48} color="black" />
                        <Text style={{ fontFamily: 'PressStart2P_400Regular', fontSize: scaleFontSize(24), textAlign: 'center', paddingTop: 10, color: '#FF8000' }}>Click here to get the app for the best experience!</Text>
                        <Entypo name="mobile" size={48} color="black" />
                    </TouchableOpacity>
                </View>}
            </View>
            <View style={styles.buttons}>
                <TouchableOpacity
                    style={styles.btn_room}
                    onPress={() => {
                        if (Platform.OS === 'web') {
                            // feature detect
                            if (typeof DeviceMotionEvent.requestPermission === 'function') {
                                DeviceMotionEvent.requestPermission().then(() => {
                                    props.navigation.navigate('Arcade');
                                })
                                    .catch(console.error);
                            } else {
                                // handle regular non iOS 13+ devices
                                props.navigation.navigate('Arcade');
                            }
                        } else {
                            props.navigation.navigate('Arcade');
                        }
                    }}
                >
                    <Image
                        source={require('../assets/home/button.png')}
                        fadeDuration={0}
                        resizeMode='stretch'
                        style={{
                            width: Dimensions.get('window').width * 0.75,
                            height: Dimensions.get('window').height * 0.075,
                            maxWidth: Dimensions.get('window').height * 0.05 * 12,
                        }} />
                    <Text numberOfLines={2} adjustsFontSizeToFit style={{ fontFamily: 'PressStart2P_400Regular', position: 'absolute', fontSize: scaleFontSize(36), width: Dimensions.get('window').width * 0.6, textAlign: 'center', paddingTop: 10 }}>ARCADE</Text>
                </TouchableOpacity>

                <TouchableOpacity
                    style={styles.btn_room}
                    onPress={() => {
                        if (Platform.OS === "web") {
                            if (typeof DeviceMotionEvent.requestPermission === 'function') {
                                DeviceMotionEvent.requestPermission().then(() => {
                                    props.navigation.navigate('RoomsWeb');
                                })
                                    .catch(console.error);
                            } else {
                                // handle regular non iOS 13+ devices
                                props.navigation.navigate('RoomsWeb');
                            }
                        } else {
                            props.navigation.navigate('Rooms');
                        }
                    }}
                >
                    <Image
                        source={require('../assets/home/button.png')}
                        fadeDuration={0}
                        resizeMode='stretch'
                        style={{
                            width: Dimensions.get('window').width * 0.75,
                            height: Dimensions.get('window').height * 0.075,
                            maxWidth: Dimensions.get('window').height * 0.05 * 12,
                        }} />
                    <Text numberOfLines={1} adjustsFontSizeToFit style={{ fontFamily: 'PressStart2P_400Regular', position: 'absolute', fontSize: scaleFontSize(22), width: Dimensions.get('window').width * 0.55, textAlign: 'center', paddingTop: 10, }}>MULTIPLAYER</Text>
                </TouchableOpacity>

                <TouchableOpacity
                    style={styles.btn_room}
                    onPress={() => {
                        props.navigation.navigate('Credits');
                    }}
                >
                    <Image
                        source={require('../assets/home/button.png')}
                        fadeDuration={0}
                        resizeMode='stretch'
                        style={{
                            width: Dimensions.get('window').width * 0.65,
                            height: Dimensions.get('window').height * 0.075,
                            maxWidth: Dimensions.get('window').height * 0.05 * 11,

                        }} />
                    <Text numberOfLines={1} adjustsFontSizeToFit style={{ fontFamily: 'PressStart2P_400Regular', position: 'absolute', fontSize: scaleFontSize(28), width: Dimensions.get('window').width * 0.4, textAlign: 'center', paddingTop: 10 }}>Credits</Text>
                </TouchableOpacity>

            </View>
            <Image
                source={require('../assets/home/trashgamenight.png')}
                fadeDuration={1000}
                resizeMode='stretch'
                style={{
                    width: trashSize,
                    height: trashSize,
                }} />
            {Platform.OS === 'web' && <Link style={styles.policy} link="https://sites.google.com/view/trashgamenightcom/startseite" title="Privacy Policy and Terms of Service" />}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: ColorConstants.HOME_BACKGROUND,
        alignItems: 'center',
        justifyContent: 'center'
    },
    btn_room: {
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 10
    },
    headline: {
        backgroundColor: '#000000D0',
        width: '90%',
        justifyContent: 'center',
        paddingVertical: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 7,
        },
        shadowOpacity: 0.43,
        shadowRadius: 9.51,
        elevation: 15,
        borderColor: 'black',
        borderWidth: 1
    },
    buttons: {
        height: '30%'
    },
    policy: {
        position: 'absolute',
        bottom: 5,
        left: 5,
        fontSize: scaleFontSize(18),
    }
});

export default HomeScreen;